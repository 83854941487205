<nav class="navbar navbar-expand-lg navbar-dark bg-dark">
  <a class="navbar-brand" routerLink="/worktime/create">Worktime Manager</a>
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div class="collapse navbar-collapse" id="navbarSupportedContent">

    <div *ngIf="authService.loggedIn()" class="dropdown menu-item" dropdown>
      <a class="dropdown-toggle text-light" dropdownToggle>
        Worktime management
      </a>

      <div class="dropdown-menu mt-3" *dropdownMenu>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/worktime/create" routerLinkActive="active">Add worktime</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/worktime/edit" routerLinkActive="active">Edit worktimes</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/worktime/reporting" routerLinkActive="active">Worktime reporting</a>
      </div>
    </div>

    <div *ngIf="authService.loggedIn()" class="dropdown menu-item" dropdown>
      <a class="dropdown-toggle text-light" dropdownToggle>
        Project management
      </a>
    
      <div class="dropdown-menu mt-3" *dropdownMenu>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/organization/organizations" routerLinkActive="active">Organizations</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/roles/roles" routerLinkActive="active">Roles</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/teams/teams" routerLinkActive="active">Teams</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/projects/projects" routerLinkActive="active">Projects</a>
        <div class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/task/tasks" routerLinkActive="active">Tasks</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/task/types" routerLinkActive="active">Task types</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/task/states" routerLinkActive="active">Task states</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/priority/priorities" routerLinkActive="active">Priorities</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/project/types" routerLinkActive="active">Project types</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/project/versions" routerLinkActive="active">Project version</a>
        <div  class="dropdown-divider"></div>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/board" routerLinkActive="active">Board</a>
      </div>
    </div>

    <div *ngIf="authService.loggedIn()" class="dropdown menu-item" dropdown>
      <a class="dropdown-toggle text-light" dropdownToggle>
        Productivity
      </a>
    
      <div class="dropdown-menu mt-3" *dropdownMenu>
        <a class="nav-link" *ngIf="authService.loggedIn()" routerLink="/productivity/todo-lists" routerLinkActive="active">Todo</a>
      </div>
    </div>

    <ul class="navbar-nav user-controls">
      <a class="nav-link" *ngIf="!authService.loggedIn()" routerLink="/login" routerLinkActive="active">Login</a>
      <a class="nav-link" *ngIf="!authService.loggedIn()" routerLink="/register" routerLinkActive="active">Register</a>
    </ul>

    <div class="menu-group">
      <div *ngIf="authService.loggedIn()" class="dropdown menu-item" style="float: left;" dropdown>

      
        <app-organization-selection></app-organization-selection>
      </div>

      <div *ngIf="authService.loggedIn()" class="dropdown menu-item user-controls" style="float: right;" dropdown>
        <a class="dropdown-toggle text-light" dropdownToggle>
          Welcome {{authService.decodedToken?.user | titlecase}}
        </a>
      
        <div class="dropdown-menu mt-3" *dropdownMenu>
          <a class="dropdown-item" routerLink="/users/edit">
            <i class="fa fa-user"></i>Edit Profile</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="authService.logoutUser()"><i class="fa fa-sign-out"></i> Logout</a>
        </div>
      </div>
    </div>
  </div>
</nav>    
<div class="container">
  <router-outlet></router-outlet>            
</div>