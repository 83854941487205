import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { ProjectService } from '../../_services/project.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectType } from '../../_models/projectType';
import { TaskTypes } from '../../_models/taskTypes';
import { TaskState } from '../../_models/taskState';
import { TaskService } from '../../_services/task.service';
import { ProjectVersion } from '../../_models/projectVersion';
import { ProjectVersionsComponent } from '../../ProjectManagement/project-versions/project-versions.component';
import { Priority } from 'src/app/_models/priority';
import { PriorityService } from 'src/app/_services/priority.service';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-task-create',
  templateUrl: './task-create.component.html',
  styleUrls: ['./task-create.component.css']
})
export class TaskCreateComponent implements OnInit {
  @Input() activeOrganizationId: number = null;
  @Output() taskCreated: EventEmitter<any> = new EventEmitter();

  localFormat = 'YYYY-MM-DD HH:mm:ss';
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  taskCreateForm: UntypedFormGroup;
  projects = [];
  taskTypes: TaskTypes[];
  taskStates: TaskState[];
  activeTaskType;
  activeTaskState;
  activeProject;
  projectId: number;
  projectVersions: ProjectVersion[];
  activeVersion: ProjectVersion;
  priorities: Priority[];
  activePriority: Priority;

  constructor(private projectService: ProjectService, private router: Router, private fb: UntypedFormBuilder,
              private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService,
              private priorityService: PriorityService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.searchProjects();
    this.createTaskForm();
  }

  createTaskForm() {
    this.taskCreateForm = this.fb.group({
      taskname: ['', Validators.required],
      taskproject: [{value: '', disabled: true}, Validators.required],
      taskprojectid: ['', Validators.required],
      tasktypeid: ['', Validators.required],
      taskstateid: ['', Validators.required],
      taskversionid: ['', ''],
      taskpriorityid: ['', ''],
      taskdeadline: ['', ''],
      description: ['', ''],
      estimatedDays: ['', ''],
      estimatedHours: ['', ''],
      estimatedMinutes: ['', '']
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        this.searchProjects();
      }
    }
  }

  async searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
            this.projects = res.results;
            this.projectId = Number(localStorage.getItem('currentProject'));

            if (!this.projects.find(p => p.projectId === this.projectId)){
              this.projectId = this.projects[0].projectId;
              localStorage.setItem('currentProject', this.projectId.toString());
            }

            if (this.projectId) {
              this.setTaskProject(this.projectId);
              this.getTaskTypes();
              this.getTaskStates();
              this.getProjectVersions();
              this.getPriorities();
            }
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'))
      }
    ));
  }

  async getTaskTypes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.taskService.getTaskTypes(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskTypes = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'))
      }
    ));
  }

  async getTaskStates() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.taskService.getTaskStates(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskStates = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'));
      }
    ));
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.activeProject.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectVersions = res;
          }
          else {
            this.projectVersions = [];
          }
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'));
      }
    ));
  }

  async getPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.priorities = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to load priorities');
          reject(new Error('Failed'));
      }
    ));
  }

  async setTaskProject(projectId) {
    if (this.projects.find(p => p.projectId === projectId)){
      localStorage.setItem('currentProject', this.projectId.toString());
      this.taskCreateForm.patchValue({taskprojectid: projectId});
      this.activeProject = this.projects.find(p => p.projectId === projectId);
      this.projectId = projectId;
      await this.getTaskStates();
      await this.getTaskTypes();
    }
  }

  setTaskType(taskTypeId) {
    this.taskCreateForm.patchValue({tasktypeid: taskTypeId});
    this.activeTaskType = this.taskTypes.find(tt => tt.taskTypeId === taskTypeId);
  }

  setTaskState(stateId) {
    this.taskCreateForm.patchValue({taskstateid: stateId});
    this.activeTaskState = this.taskStates.find(ts => ts.stateId === stateId);
  }

  setVersion(versionId) {
    this.taskCreateForm.patchValue({taskversionid: versionId});
    this.activeVersion = this.projectVersions.find(v => v.versionId === versionId);
  }

  setPriority(priorityId) {
    this.taskCreateForm.patchValue({taskversionid: priorityId});
    this.activePriority = this.priorities.find(p => p.priorityId === priorityId);
  }

  async saveTask() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let deadline = this.taskCreateForm.value['projectdeadline'];
    if (!deadline) {
      deadline = null;
    }
    else {
      moment(deadline).format(this.localFormat);
    }

    const taskData = {
      organizationId: this.activeOrganizationId,
      taskName: this.taskCreateForm.value['taskname'],
      projectId: this.taskCreateForm.value['taskprojectid'],
      taskType: this.taskCreateForm.value['tasktypeid'],
      taskState: this.taskCreateForm.value['taskstateid'],
      versionId: this.activeVersion && this.activeVersion.versionId != 0 ? this.activeVersion.versionId : null,
      priorityId: this.activePriority && this.activePriority.priorityId != 0 ? this.activePriority.priorityId : null,
      taskOwner: this.authService.decodedToken.id,
      assigneeId: null,
      deadline,
      description: this.taskCreateForm.value['description'],
      estimatedDays: this.taskCreateForm.value['estimatedDays'],
      estimatedHours: this.taskCreateForm.value['estimatedHours'],
      estimatedMinutes: this.taskCreateForm.value['estimatedMinutes'],
    };

    if (deadline != null && !moment(taskData.deadline).isValid()) {
      this.alertify.error('Invalid deadline');
      return;
    }

    await new Promise((resolve, reject) => this.taskService.saveTask(taskData)
      .subscribe(
        res => {
          this.taskCreated.emit();
          this.taskCreateForm.reset();
          this.activeTaskType = null;
          this.activePriority = null;
          this.activeProject = null;
          this.activeTaskState = null;
          this.activeVersion = null;
          this.alertify.success('Saved task');
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save task');
          reject(new Error('Failed'));
      }
    ));
  }
}
