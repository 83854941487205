import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagePermission } from 'src/app/_models/pagePermission';
import { TodoListItem } from 'src/app/_models/todoListItem';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TodoService } from 'src/app/_services/todo.service';

@Component({
  selector: 'app-todoitem',
  templateUrl: './todoitem.component.html',
  styleUrls: ['./todoitem.component.css']
})
export class TodoitemComponent implements OnInit {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  @Input() todoId: number;

  pagePermissions: Array<PagePermission> = new Array<PagePermission>();
  todoListItems: Array<TodoListItem> = new Array<TodoListItem>();
  todoItemForm: UntypedFormGroup;

  constructor(private router: Router, private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService,
    private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService, private todoService: TodoService, 
    private fb: UntypedFormBuilder) { }

  async ngOnInit() {
    this.generatePagePermissions();
    await this.getTodoListItems();
    this.createTodoItemForm();
  }

  createTodoItemForm() {
    this.todoItemForm = this.fb.group({
      message: ['', Validators.required],
      isChecked: ['', '']
    });
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'todo.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'todo.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'todo.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  async getTodoListItems() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    const oldItems = JSON.parse(JSON.stringify(this.todoListItems));
    this.todoListItems = new Array<TodoListItem>();
    await new Promise((resolve, reject) => this.todoService.getTodoListItems(this.todoId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.todoListItems = res.results;
          this.setExistingTodoItems(oldItems, this.todoListItems);
          resolve(true);
        }, err => {
          console.log('err: ', err);
          this.alertify.error('Failed to load projects');
          reject(new Error('failed'));
      }
    ));
  }

  setExistingTodoItems(oldTodoItems: Array<TodoListItem>, newTodoItems: Array<TodoListItem>) {
    for (const item of oldTodoItems) {
      let itemToUpdate: TodoListItem = newTodoItems.find(oldItem => oldItem.id === item.id && oldItem.todoId === item.todoId);
      if (!itemToUpdate) {
        continue;
      }
      itemToUpdate.isChecked = item.isChecked;
      itemToUpdate.message = item.message;
    }
  }

  async addItem() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    // this.todoListItems = new Array<TodoListItem>();
    let todoListItemData = { todoId: this.todoId, message: 'todo', isChecked: 0 };
    await new Promise((resolve, reject) => this.todoService.saveTodoListItem(todoListItemData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.todoListItems.push(res);
            this.getTodoListItems();
            resolve(true);
        }, err => {
          console.log('err: ', err);
          this.alertify.error('Failed to load projects');
          reject(new Error('failed'));
      }
    ));
  }

  deleteItem(id: number, todoId: number) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.alertify.confirm('Are you sure you want to delete this todo item?', () => {
      this.todoService.deleteTodoListItem(id, todoId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted todo item');
          this.getTodoListItems();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete todo item');
            }
          }
        }
      );
    });
  }

  saveItems() {
    if (!this.activeOrganizationId) {
      this.alertify.error('Failed to save project changes');
      return;
    }

    let editedTodoListItemData = [];

    for (let i = 0; i < this.todoListItems.length; i++) {
      const todoListItemsData = {
        id: this.todoListItems[i].id,
        todoId: this.todoListItems[i].todoId,
        message: this.todoListItems[i].message,
        isChecked: this.todoListItems[i].isChecked ? 1 : 0
      };

      editedTodoListItemData.push(todoListItemsData);
    }

    const todoListItemData = {
      todoListItemData: editedTodoListItemData
    };

    this.todoService.editTodoListItems(todoListItemData, this.projectId, this.activeOrganizationId, this.todoId)
      .subscribe(
        res => {
          this.alertify.success('Saved project changes');
      }, err => {
        this.alertify.error('Failed to save project changes');
      }
    );
  }
}