<div class="row pt-5">
  <div class="col-md-6 mx-auto">
    <div class="card rounded-0">
      <div class="card-header">
        <h3 class="mb-0">Create new task state</h3>
      </div>

      <div class="card-body">
        <form [formGroup]="taskStateForm" class="form">
          <table style="width: 100%;">
              <tr>
                <td colspan="2">
                  <label for="">*Task state name</label>
                  <input 
                    [ngClass]="{'is-invalid': taskStateForm.get('taskstatename').errors && taskStateForm.get('taskstatename').touched}"
                    formControlName="taskstatename"
                    name="taskstatename"
                    type="text"
                    class="form-control">
                  <div class="invalid-feedback worktime-date-field">Task state is required</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div>
                    <label for="">Billable</label>
                    <input 
                      [ngClass]="{'is-invalid': taskStateForm.get('billable').errors && taskStateForm.get('billable').touched}"
                      formControlName="billable"
                      name="billable"
                      type="checkbox"
                      class="form-control state-check-box">
                    <div class="invalid-feedback worktime-date-field">Billable is required</div>
                  </div>
                </td>
                <td>
                  <div>
                    <label for="">Closed</label>
                    <input 
                      [ngClass]="{'is-invalid': taskStateForm.get('closed').errors && taskStateForm.get('closed').touched}"
                      formControlName="closed"
                      name="closed"
                      type="checkbox"
                      class="form-control state-check-box">
                    <div class="invalid-feedback worktime-date-field">Closed is required</div>
                  </div>
                </td>
              </tr>
            <tr class="bottom-row">
              <td>
                <button [disabled]="!taskStateForm.valid" name="saveTaskState" (click)="saveState()" type="button" class="btn btn-success float-left">Save</button>
              </td>
            </tr>
          </table>
        </form>

      </div>
    </div>
  </div>
</div>

