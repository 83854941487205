<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit projects</h3>
          <div class="worktime-search-limit">
            <div class="worktime-search-field">
                <label for="">From:</label>
                <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <div class="worktime-search-field">
              <label for="">To:</label>
              <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <button class="worktime-search-field" (click)="searchProjects()" [disabled]="false" type="button" class="btn btn-info mt-4 mb-0">Search</button>
          </div>
        </div>

        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="userProjects && userProjects.length > 0" (click)="saveProjects()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
            </div>
              <table class="table-full-width" *ngIf="userProjects && projectTypes">
                  <span *ngFor="let row of expandedStates">
                    <tr>
                      <td style="width: 25%;">
                        <button *ngIf="isOwner(row.project.projectId)" name="deleteProjectButton_{{row.project.projectName}}" (click)="deleteProject(row.project.projectId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                        <button name="expandButton_{{row.project.projectName}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                      </td>
                    </tr>
                    <span *ngIf="!row.isActive">
                      <tr>
                        <td style="width: 25%;">
                          <label for="">*Project name</label>
                          <p>
                            {{row.project.projectName}}
                          </p>
                        </td>
                      </tr>
                    </span>
                  <span *ngIf="row.isActive">
                    <tr>
                      <td style="width: 25%;">
                        <label for="">*Project name</label>
                        <input [(ngModel)]="row.project.projectName"
                        name="projectname{{row.project.projectId}}"
                        type="text"
                        class="form-control">
                        <div class="invalid-feedback worktime-date-field">Project name is required</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="">Description</label>
                        <textarea 
                        [(ngModel)]="row.project.description"
                          name="projectname{{row.project.description}}"
                          type="text"
                          class="form-control">
                        </textarea>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <label for="">*Project type</label>
                        <div *ngIf="projectTypes.length > 0" class="btn-group" style="width: 100%;" dropdown>
                          <button id="task-button{{row.project.projectId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Project type <span class="caret"></span>
                        </button>
                        <ul id="task-dropdown{{row.project.projectId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let projectType of projectTypes" role="menuitem" (click)="setProject(row.project.projectId, projectType.projectTypeId)">{{projectType.projectTypeName}}</li>
                          </ul>
                        </div>
                        <p *ngIf="row.project" type="text" class="form-control">
                          {{getProjectTypeFromTypeId(row.project.projectType)}}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <label for="">Team</label>
                        <div *ngIf="organizationTeams.length > 0" class="btn-group" style="width: 100%;" dropdown>
                          <button id="team-button{{row.project.projectId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Team <span class="caret"></span>
                        </button>
                        <ul id="team-dropdown{{row.project.projectId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" (click)="setTeam(row.project.projectId, null)">No team</li>
                          <li *ngFor="let team of organizationTeams" role="menuitem" (click)="setTeam(row.project.projectId, team.teamId)">{{team.name}}</li>
                          </ul>
                        </div>
                        <p *ngIf="row.project" type="text" class="form-control">
                          {{getTeamFromTeamId(row.project.teamId)}}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <label for="">Project deadline</label>
                        <input [(ngModel)]="row.project.projectDeadline" name="deadline{{row.project.projectId}}" class="form-control" placeholder="Project deadline" type="text" bsDatepicker [bsConfig]="bsConfig">
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-comment entityId={{row.project.projectId}} [activeOrganizationId]="activeOrganizationId" [relationTable]="relationTable" projectId={{row.project.projectId}}></app-comment>
                      </td>
                    </tr>
                    <tr *ngIf="isOwner(row.project.projectId)">
                      <td>
                        <app-entity-users 
                          [entityUsers]="getProjectUser(row.project.projectId)"
                          [organizationId]="activeOrganizationId"
                          [entityId]="row.project.projectId"
                          [lockedUsers]="getLockedUsersForProject(row.project.projectId)"
                          (addUserEvent)="saveUser($event)"
                          (deleteUserEvent)="deleteUser($event)"
                        >
                        </app-entity-users>
                      </td>
                    </tr>
                    <div class="bottom-row">
                    </div>
                  </span>
                </span>
              </table>
              <div class="horizontal-line mb-5">
                <button *ngIf="userProjects && userProjects.length > 0" (click)="saveProjects()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              </div>
          </form>
          <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>
        <div>
          <h2 *ngIf="!userProjects || userProjects.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
