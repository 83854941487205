import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { ProjectType } from '../_models/projectType';
import { TaskTypes } from '../_models/taskTypes';
import { TaskState } from '../_models/taskState';
import { Task } from '../_models/task';
import { Comment } from '../_models/comment';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getOrganizations( perPage?: number, pageNumber?: number) {
    const ownerId = this.authService.decodedToken.id;
    if (!ownerId) {
      return;
    }
    return this.http.get<any>(this.baseUrl + 'organizations/get/' + ownerId + '/' + (perPage ? perPage : null) + '/' + (pageNumber ? pageNumber : null));
  }

  getOrganizationsWithUsers(perPage?: number, pageNumber?: number) {
    const ownerId = this.authService.decodedToken.id;
    if (!ownerId) {
      return;
    }
    return this.http.get<any>(this.baseUrl + 'organizationsWithUsers/get/' + ownerId + '/' + (perPage ? perPage : 0) + '/' + (pageNumber ? pageNumber : 0));
  }

  saveOrganization(organizationData) {
    return this.http.post<any>(this.baseUrl + 'organization/create', organizationData);
  }

  editOrganizations(organizationsData) {
    const body = {
      ownerId: this.authService.decodedToken.id,
      organizationsData
    }
    return this.http.post<any>(this.baseUrl + 'organizations/edit', body);
  }

  deleteOrganization(organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.decodedToken.id
      },
    };
    return this.http.delete<any>(this.baseUrl + 'organization/delete/' + organizationId, options);
  }

  getOrganizationUsers(organizationId: number) {
    const body = {
      userId: this.authService.decodedToken.id,
      organizationId
    };
    return this.http.post<any>(this.baseUrl + 'organizationUsers/get', body);
  }

  saveOrganizationUser(organizationUserData) {
    organizationUserData.ownerId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'organizationUser/save', organizationUserData);
  }

  deleteOrganizationUser(organizationId: number, userId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ownerId: this.authService.currentUser.id,
        userId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'organizationUser/delete/' + organizationId, options);
  }

  getActiveOrganizationId() {
    const activeOrganization = localStorage.getItem('activeOrganizationId');
    return activeOrganization ?? '';
  }

  getActiveOrganizationName() {
    const activeOrganization = localStorage.getItem('activeOrganizationName');
    return activeOrganization ?? 'None';
  }
}
