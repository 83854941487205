import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { User } from '../_models/user';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUserToEdit(organizationId: number) {
    const id = this.authService.decodedToken.id;
    return this.http.get<any>(this.baseUrl + 'users/edit/' + organizationId + '/' + id);
  }

  saveUserToEdit(userData): Observable<UserToEdit> {
    const userId = this.authService.decodedToken.id;
    return this.http.post<any>(this.baseUrl + 'users/edit/' + userId, userData);
  }

  /**
  * @deprecated since 22.7.2021 to be removed or refactored in future updates.
  * This function is currently very dangerous, and should not be used if possible!
  */
  getUserByUsername(username: string) {
    console.log('Warning! Deprecated function. Will be removed in future updates');
    const body = {
      userId: this.authService.decodedToken.id,
      username: username
    }
    return this.http.post<any>(this.baseUrl + 'user/getByUsername', body);
  }
}
