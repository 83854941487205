<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit priorities</h3>
        </div>

        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="projectPriorities && projectPriorities.length > 0" (click)="savePriorities()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>
              <table class="table-full-width" *ngIf="projectPriorities">
                  <span *ngFor="let priority of projectPriorities">
                    <tr>
                      <td style="width: 25%;">
                        <button (click)="deletePriority(priority.priorityId)" name="deletePriority_{{priority.priorityName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                        <label for="">*Priority name</label>
                        <input [(ngModel)]="priority.priorityName"
                        name="projectname{{priority.priorityId}}"
                        id="priorityname_{{priority.priorityName}}"
                        type="text"
                        class="form-control">
                        <div class="invalid-feedback worktime-date-field">Priority name is required</div>
                      </td>
                    </tr>
                  </span>
              </table>
              <div class="horizontal-line mb-5">
                <button *ngIf="projectPriorities && projectPriorities.length > 0" (click)="savePriorities()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              </div>
          </form>

        <div>
          <h2 *ngIf="projectPriorities && projectPriorities.length == 0">No priorities for selected project</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
