<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new task type</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="taskTypeForm" class="form">
            <table style="width: 100%;">
                <tr>
                    <td colspan="2">
                        <label for="">*Tasktype name</label>
                        <input 
                          [ngClass]="{'is-invalid': taskTypeForm.get('tasktypename').errors && taskTypeForm.get('tasktypename').touched}"
                          formControlName="tasktypename"
                          name="tasktypename"
                          type="text"
                          class="form-control">
                        <div class="invalid-feedback worktime-date-field">Tasktype name is required</div>
                    </td>
                </tr>
                <tr>
                    <td>
                      <label for="">Billable</label>
                      <input 
                      [ngClass]="{'is-invalid': taskTypeForm.get('billable').errors && taskTypeForm.get('billable').touched}"
                      formControlName="billable"
                      name="billable"
                      type="checkbox"
                      class="form-control">
                    <div class="invalid-feedback worktime-date-field">Billable is required</div>
                    </td>
                </tr>
              <tr class="bottom-row">
                <td>
                  <button [disabled]="!taskTypeForm.valid" name="saveTaskType" (click)="saveTaskType()" type="button" class="btn btn-success float-left">Save</button>
                </td>
              </tr>
            </table>
          </form>

        </div>
      </div>
    </div>
  </div>

