import { Component, OnInit, ViewChild } from '@angular/core';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { OrganizationEditComponent } from '../organization-edit/organization-edit.component';

@Component({
  selector: 'app-organizations',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.css']
})
export class OrganizationsComponent implements OnInit {
  activeOrganizationId: number = null;

  @ViewChild(OrganizationEditComponent) child: OrganizationEditComponent;

  constructor(private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
    }
  }

  refreshOrganizations(events: any) {
    this.child.refresh();
  }

}
