import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { map } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { User } from '../_models/user';
import { AlertifyService } from './alertify.service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  baseUrl = environment.apiUrl;
  jwtHelper = new JwtHelperService();
  decodedToken: any;
  currentUser: User;

  constructor(private http: HttpClient, private router: Router, private alertify: AlertifyService) { }

  // registerUser(user) {
  //   return this.http.post<any>(this.baseUrl + 'auth/register', user)
  //     .pipe(
  //       map((response: any) => {
  //         const user = response;
  //         localStorage.setItem('token', user.token);
  //         this.decodedToken = this.jwtHelper.decodeToken(user.token);
  //         localStorage.setItem('user', JSON.stringify(this.decodedToken));
  //         this.currentUser = this.decodedToken;
  //       })
  //     );
  // }

  loginUser(user) {
    return this.http.post<any>(this.baseUrl + 'auth/login', user)
      .pipe(
          map((response: any) => {
            const user = response;
            localStorage.setItem('token', user.token);
            this.decodedToken = this.jwtHelper.decodeToken(user.token);
            localStorage.setItem('user', JSON.stringify(this.decodedToken));
            this.currentUser = this.decodedToken;
          })
      );
  }

  logoutUser() {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    localStorage.removeItem('activeOrganizationId');
    localStorage.removeItem('activeOrganizationName');
    localStorage.removeItem('currentProject');
    this.router.navigate(['/login']);
    this.decodedToken = null;
    this.alertify.success('Logged out');
  }

  loggedIn() {
    return !!localStorage.getItem('token');
  }

  getToken() {
    return localStorage.getItem('token');
  }
}
