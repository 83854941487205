import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { element } from 'protractor';
import { Project } from 'src/app/_models/project';
import { Task } from 'src/app/_models/task';
import { Worktime } from 'src/app/_models/worktime';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ProjectService } from 'src/app/_services/project.service';
import { WorktimeService } from 'src/app/_services/worktime.service';

@Component({
  selector: 'app-cut-worktimes',
  templateUrl: './cut-worktimes.component.html',
  styleUrls: ['./cut-worktimes.component.css']
})
export class CutWorktimesComponent implements OnInit {
  @Input() worktime: Worktime = null;
  @Input() projects: Array<Project> = Array<Project>();
  @Input() tasks: Array<Task> = Array<Task>();
  @Input() activeOrganizationId: Number = 0;

  @Output() closeCutModal: EventEmitter<any> = new EventEmitter();
  localFormat = 'YYYY-MM-DD HH:mm:ss';
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  cutWorktimeForm: UntypedFormGroup;
  originalActiveProject: Project;
  originalActiveTask: Task;
  editedActiveProject: Project;
  editedActiveTask: Task;
  projectTasks: Array<Task> = new Array<Task>();

  constructor(private work: WorktimeService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private projectsService: ProjectService) { }

  ngOnInit(): void {
    this.createWorktimeForm();
    this.setWorktimesToCut();
  }

  diff_hours(dt2, dt1) {
    let diff =(dt2.getTime() - dt1.getTime()) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }

  createWorktimeForm() {
    this.cutWorktimeForm = this.fb.group({
      originalProject: ['', Validators.required],
      originalTask: ['', ''],
      originalStartdate: ['', Validators.required],
      originalStarttime: ['', Validators.required],
      originalEnddate: ['', Validators.required],
      originalEndtime: ['', Validators.required],
      originalComment: ['', ''],

      editedProject: ['', Validators.required],
      editedTask: ['', ''],
      editedStartdate: ['', Validators.required],
      editedStarttime: ['', Validators.required],
      editedEnddate: ['', Validators.required],
      editedEndtime: ['', Validators.required],
      editedComment: ['', '']
    });
  }

  setWorktimesToCut() {
    let diff = this.diff_hours(this.worktime.endTime, this.worktime.startTime);
    const middleDate = new Date(moment(this.worktime.endTime).subtract(diff / 2, 'hours').format('YYYY/MM/DD HH:mm'));

    this.cutWorktimeForm.patchValue({
      originalProject: this.worktime.projectId,
      originalTask: this.worktime.taskId,
      originalStartdate: this.worktime.startTime,
      originalStarttime: this.worktime.startTime,
      originalEnddate: middleDate,
      originalEndtime: middleDate,
      originalComment: this.worktime.comment,

      editedProject: this.worktime.projectId,
      editedTask: this.worktime.taskId,
      editedStartdate: middleDate,
      editedStarttime: middleDate,
      editedEnddate: this.worktime.endTime,
      editedEndtime: this.worktime.endTime,
      editedComment: null
    });
    this.setProject(this.worktime.projectId, 'originalProject');
    this.setProject(this.worktime.projectId, 'editedProject');
  }

  setProject(projectId: number, controllerName: string) {
    let activeProject = null;
    if (controllerName === 'originalProject') {
      activeProject = this.originalActiveProject = this.projects.find(p => p.projectId === projectId);
    }
    else if (controllerName === 'editedProject') {
      activeProject = this.editedActiveProject = this.projects.find(p => p.projectId === projectId);
    }
    this.cutWorktimeForm.controls[controllerName].setValue(activeProject.projectName);

    this.getProjectTaskList(this.worktime.projectId);

    const taskControllerName = controllerName === 'originalProject' ? 'originalTask' : 'editedTask';
    this.setTask(this.worktime.taskId, taskControllerName);
  }

  setTask(taskId: number, controllerName: string) {
    let activeTask = null;
    if (taskId === 0 || taskId === null || !this.projectTasks || this.projectTasks.length === 0) {
      if (controllerName === 'originalTask') {
        this.originalActiveTask = null;
      }
      if (controllerName === 'editedTask') {
        this.editedActiveTask = null;
      }
    }
    else {
      if (controllerName === 'originalTask') {
        activeTask = this.originalActiveTask = this.projectTasks.find(t => t.taskId === taskId);
        
      }
      if (controllerName === 'editedTask') {
        activeTask = this.editedActiveTask = this.projectTasks.find(t => t.taskId === taskId);
      }
      if (!activeTask) {
        return;
      }
    }
    this.cutWorktimeForm.controls[controllerName].setValue(activeTask?.taskName);
  }

  getProjectTaskList(projectId: number) {
    let taskList = [];
    this.tasks.forEach(projectTaskList => {
      if (projectTaskList[0] && projectTaskList[0].projectId === projectId) {
        taskList.push(projectTaskList);
      }
    });
    this.projectTasks = taskList[0];
  }

  save() {
    const formatedOriginalStartDate = moment(this.cutWorktimeForm.controls['originalStartdate'].value).format('YYYY-MM-DD');
    const formattedOriginalStartTime = moment(this.cutWorktimeForm.controls['originalStarttime'].value).format('HH:mm');
    const formatedOriginalEndtDate = moment(this.cutWorktimeForm.controls['originalEnddate'].value).format('YYYY-MM-DD');
    const formattedOriginalEndTime = moment(this.cutWorktimeForm.controls['originalEndtime'].value).format('HH:mm');

    const formatedEditedStartDate = moment(this.cutWorktimeForm.controls['editedStartdate'].value).format('YYYY-MM-DD');
    const formattedEditedStartTime = moment(this.cutWorktimeForm.controls['editedStarttime'].value).format('HH:mm');
    const formatedEditedEndtDate = moment(this.cutWorktimeForm.controls['editedEnddate'].value).format('YYYY-MM-DD');
    const formattedEditedEndTime = moment(this.cutWorktimeForm.controls['editedEndtime'].value).format('HH:mm');

    const originalWorktime: Worktime = {
      id: this.worktime.id,
      userId: this.authService.decodedToken.id,
      projectId: this.originalActiveProject.projectId,
      taskId: this.originalActiveTask ? this.originalActiveTask.taskId : 0,
      startTime: new Date(moment(formatedOriginalStartDate + ' ' + formattedOriginalStartTime).format(this.localFormat)),
      endTime: new Date(moment(formatedOriginalEndtDate + ' ' + formattedOriginalEndTime).format(this.localFormat)),
      comment: this.cutWorktimeForm.controls['originalComment'].value
    };

    const editedWorktime: Worktime = {
      id: null,
      userId: this.authService.decodedToken.id,
      projectId: this.editedActiveProject.projectId,
      taskId: this.editedActiveTask ? this.editedActiveTask.taskId : 0,
      startTime: new Date(moment(formatedEditedStartDate + ' ' + formattedEditedStartTime).format(this.localFormat)),
      endTime: new Date(moment(formatedEditedEndtDate + ' ' + formattedEditedEndTime).format(this.localFormat)),
      comment: this.cutWorktimeForm.controls['editedComment'].value
    };

    if (!moment(originalWorktime.startTime).isValid()) {
      this.alertify.error('Invalid start time');
      return;
    }
    if (!moment(originalWorktime.endTime).isValid()) {
      this.alertify.error('Invalid end time');
      return;
    }
    if (originalWorktime.startTime >= originalWorktime.endTime) {
      this.alertify.error('End time has to be greater than start time 1');
      return;
    }

    if (!moment(editedWorktime.startTime).isValid()) {
      this.alertify.error('Invalid start time');
      return;
    }
    if (!moment(editedWorktime.endTime).isValid()) {
      this.alertify.error('Invalid end time');
      return;
    }
    if (editedWorktime.startTime >= editedWorktime.endTime) {
      this.alertify.error('End time has to be greater than start time 2');
      return;
    }

    if (!this.originalActiveProject || !this.editedActiveProject) {
      this.alertify.error('No project selected');
      return;
    }

    this.work.cutWorktime(originalWorktime, editedWorktime, this.activeOrganizationId)
    .subscribe(
      res => {
        this.alertify.success('Successfully cut worktime');
        this.createWorktimeForm();
        this.originalActiveProject = null;
        this.originalActiveTask = null;
        this.editedActiveProject = null;
        this.editedActiveTask = null
        this.closeCutModal.emit();
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to cut worktime');
            this.router.navigate(['/login']);
          }
        }
      }
    );
  }

  isValidDate(d) {
    const formattedDate = new Date(moment(d).format('YYYY-MM-DD HH:mm'));
    return formattedDate instanceof Date && !isNaN(Number(formattedDate));
  }
}
