import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { User } from '../_models/user';
import { UserToShow } from '../_models/userToShow';
import { ActiveOrganizationService } from '../_services/active-organization.service';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { ProjectService } from '../_services/project.service';
import { TaskService } from '../_services/task.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-assignee',
  templateUrl: './assignee.component.html',
  styleUrls: ['./assignee.component.css']
})
export class AssigneeComponent implements OnInit {
  @Input() projectId: number;
  @Input() taskId: number;
  @Input() activeOrganizationId: number;
  @Output() assigneeSaved: EventEmitter<any> = new EventEmitter();
  projectUsers: Array<UserToShow>;
  assignee;

  constructor(private projectService: ProjectService, private router: Router, private fb: UntypedFormBuilder, private authService: AuthService, 
              private alertify: AlertifyService, private userService: UserService, private taskService: TaskService,
              private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.getProjectUsers();
  }

  async getProjectUsers() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectUsers(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectUsers = res[0];
          this.getAssignee();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get users');
          reject(new Error('Failed'));
        }
      ));
  }

  setAssignee(assigneeId: number = null) {
    if (!assigneeId) {
      assigneeId = this.authService.currentUser.id;
    }
    if (assigneeId === -1) {
      assigneeId = null;
    }

    this.taskService.setTaskAssignee(this.taskId, assigneeId, this.projectId)
      .subscribe(
        res => {
          this.taskService.getTaskAssignee(this.taskId, this.projectId, this.activeOrganizationId)
            .subscribe(
              res => {
                this.assignee = res[0];
                  this.assigneeSaved.emit(this.assignee);
                  this.alertify.success('Set assignee');
              }, err => {
                this.alertify.error('Failed to set assignee');
              }
            );
        }, err => {
          this.alertify.error('Failed to set assignee');
        }
      );
  }

  getAssignee() {
    if (!this.taskId || !this.projectId || !this.activeOrganizationId) {
      return;
    }
    this.taskService.getTaskAssignee(this.taskId, this.projectId, this.activeOrganizationId)
    .subscribe(
      res => {
        this.assignee = res[0];
      }, err => {
        this.alertify.error('Failed to set assignee');
      }
    );
  }
}
