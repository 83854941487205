import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { LoggedWorktime } from '../_models/loggedWorktime';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { WorktimeService } from '../_services/worktime.service';

@Component({
  selector: 'app-worktime-logging',
  templateUrl: './worktime-logging.component.html',
  styleUrls: ['./worktime-logging.component.css']
})
export class WorktimeLoggingComponent implements OnInit {
  @Input() taskId: number;
  @Input() projectId: number;
  @Input() activeOrganizationId: number;

  loggedWorktime: LoggedWorktime

  constructor(private router: Router, private fb: UntypedFormBuilder, private worktimeService: WorktimeService,
    private authService: AuthService, private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.getLoggedWorktime();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'taskId') {
        if (changes && changes.items && changes.projectTags.currentValue !== changes.projectTags.previousValue) {
        }
      }
    }
  }

  hasLoggedWorktimes() {
    return this.loggedWorktime && (this.loggedWorktime.minutes || this.loggedWorktime.hours || this.loggedWorktime.days);
  }

  getLoggedWorktime() {
    if (!this.taskId || !this.projectId || !this.activeOrganizationId) {
      return;
    }

    this.worktimeService.getTaskLoggedWorktime(this.projectId, this.taskId, this.activeOrganizationId)
    .subscribe(
        res => {
          if (res && (res.days || res.hours || res.minutes)) {
            this.loggedWorktime = {
              days: res.days ? res.days : 0,
              hours: res.hours ? res.hours : 0,
              minutes: res.minutes ? res.minutes : 0
            };
          }
      }, err => {
        this.alertify.error('Failed to get logged worktimes');
      }
    );
  }

}
