<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <app-search *ngIf="(checkPagePermissions('worktimes.own.view') && checkPagePermissions('worktimes.own.edit') 
        || checkPagePermissions('worktimes.others.view') && checkPagePermissions('worktimes.others.edit')) 
        && checkPagePermissions('project.view')"
        pageHeader="Edit worktimes" [hasDates]=true [hasOnlyProject]=true [hasSelectAllProjects]=true [hasSelectAll]=false (searchParametersChanged)="search($event)"></app-search>
        
        <div class="card-body" *ngIf="(checkPagePermissions('worktimes.own.view') && checkPagePermissions('worktimes.own.edit') 
        || checkPagePermissions('worktimes.others.view') && checkPagePermissions('worktimes.others.edit')) 
        && checkPagePermissions('project.view')">
          <form class="form">
            <ng-template #template>
              <div class="modal-header">
                <h4 class="modal-title pull-left">Cut worktimes</h4>
                <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <app-cut-worktimes [worktime]="worktimeToCut" [projects]="userProjects" [tasks]="projectTasks" [activeOrganizationId]="activeOrganizationId" (closeCutModal)="closeCutModal()"></app-cut-worktimes>
              </div>
            </ng-template>
            <div class="horizontal-line mb-5">
              <button *ngIf="expandedStates.length > 0" (click)="saveWorkTimes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <button type="button" class="btn btn-info mb-3 ml-2" (click)="toggleMode()">12H / 24H</button>
              <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
            </div>
            <table class="table-full-width">
              <span *ngFor="let row of expandedStates">
                <tr>
                  <td style="width: 25%;">
                    <button (click)="deleteWorkTime(row.worktime.id, row.worktime.projectId)" name="deleteWorktime_{{row.worktime.id}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                    <button name="expandButton_{{row.worktime.id}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                  </td>
                </tr>
                <span *ngIf="!row.isActive">
                  <tr>
                    <td class="minified-column">
                      <label for="">*Start Date</label>
                      <p>{{getValidTime(row.worktime.startTime)}}</p>
                    </td>
                    <td class="minified-column endtime-column">
                      <label for="">End Date</label>
                      <p>{{getValidTime(row.worktime.endTime)}}</p>
                    </td>
                    <td>
                      <label for="">*Project</label>
                      <p>{{row.worktime.projectName}}</p>
                    </td>
                  </tr>
                </span>

                <span *ngIf="row.isActive">
                  <tr>
                    <td class="half-row">
                      <label for="">*Start Date</label>
                      <input [(ngModel)]="row.worktime.startDate" name="minDate{{row.worktime.id}}" class="form-control date-input" placeholder="{{row.worktime.startDate}}" type="text" bsDatepicker [bsConfig]="bsConfig">
                    </td>
                    <td class="half-row">
                      <label for="">*Start Time</label>
                      <timepicker [(ngModel)]="row.worktime.startTime" name="starttime{{row.worktime.id}}" [showMeridian]="ismeridian"></timepicker>
                    </td>
                  </tr>
                  <tr>
                    <td class="half-row">
                      <label for="">End Date</label>
                      <input [(ngModel)]="row.worktime.endDate" name="enddate{{row.worktime.id}}" class="form-control date-input" placeholder="{{row.worktime.endDate}}" type="text" bsDatepicker [bsConfig]="bsConfig">
                    </td>
                    <td class="half-row">
                      <label for="">End Time</label>
                      <timepicker [(ngModel)]="row.worktime.endTime" name="endtime{{row.worktime.id}}" [showMeridian]="ismeridian"></timepicker>
                    </td>
                  </tr>

                  <tr>
                    <td>
                      <label for="">*Project</label>
                      <div *ngIf="userProjects.length > 0" class="btn-group" style="width: 100%;" dropdown>
                        <button id="project-button{{row.worktime.id}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Projects <span class="caret"></span>
                      </button>
                      <ul id="project-dropdown{{row.worktime.id}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li *ngFor="let project of userProjects" role="menuitem" (click)="setProject(row.worktime.id, project.projectId)">{{project.projectName}}</li>
                        </ul>
                      </div>
                      <p *ngIf="row.worktime.projectId" type="text" class="form-control">
                        {{row.worktime.projectName}}
                      </p>
    
                      <div class="alert" *ngIf="userProjects.length == 0">Create projects in project management before adding worktime entries!</div>
                    </td>
                    <td>
                      <label for="">Task</label>
                      <div *ngIf="getProjectTaskList(row.worktime.projectId).length > 0" class="btn-group" style="width: 100%;" dropdown>
                        <button id="task-button{{row.worktime.id}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Tasks <span class="caret"></span>
                      </button>
                      <ul id="task-dropdown{{row.worktime.id}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" (click)="setTask(row.worktime.id, 0)">No task</li>
                        <li *ngFor="let task of getProjectTaskList(row.worktime.projectId)" role="menuitem" (click)="setTask(row.worktime.id, task.taskId)">{{task.taskName}}</li>
                      </ul>
                      </div>
                        <p *ngIf="row.worktime.taskId && row.worktime.taskId != 0" type="text" class="form-control">
                          {{row.worktime.taskName}}
                        </p>
                      <div class="alert" *ngIf="getProjectTaskList(row.worktime.projectId).length == 0  && row.worktime.taskId == 0">This project has no tasks.</div>
                    </td>
                  </tr>

                  <tr>
                    <td colspan="2">
                      <label for="">Comment</label>
                      <input [(ngModel)]="row.worktime.comment" name="comment{{row.worktime.id}}" type="text" class="form-control" required>
                    </td>
                  </tr>
                  <tr>
                    <td class="bottom-row" colspan="2">
                      <button *ngIf="canCutWorktime(row.worktime)" type="button" class="btn btn-info mb-3 ml-2" (click)="cutWorktime(template, row.worktime)">Cut</button>
                    </td>
                  </tr>
                </span>
              </span>
            </table>
            <div class="mt-5">
              <button *ngIf="expandedStates.length > 0" (click)="saveWorkTimes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <button type="button" class="btn btn-info mb-3 ml-2" (click)="toggleMode()">12H / 24H</button>
            </div>
          </form>
          <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>

        <div>
          <h2 *ngIf="!expandedStates || expandedStates.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
