import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges } from '@angular/core';
import { PriorityService } from 'src/app/_services/priority.service';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { Priority } from 'src/app/_models/priority';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-priority-create',
  templateUrl: './priority-create.component.html',
  styleUrls: ['./priority-create.component.css']
})
export class PriorityCreateComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number = null;
  @Output() priorityCreated: EventEmitter<any> = new EventEmitter();

  priorityCreateForm: UntypedFormGroup;
  priorities: Priority[];

  constructor(private priorityService: PriorityService, private router: Router, private fb: UntypedFormBuilder,
              private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createPriorityForm();
    if (!this.projectId) {
      return;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
      }
    }
  }

  async searchPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.priorities = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get priorities');
          reject(new Error('Failed'));
      }
    ));
  }

  createPriorityForm() {
    this.priorityCreateForm = this.fb.group({
      priorityName: ['', Validators.required]
    });
  }

  async savePriority() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('No selected project');
      return;
    }

    const priorityData = {
      priorityName: this.priorityCreateForm.value['priorityName'],
      projectId: this.projectId,
      organizationId: this.activeOrganizationId
    };

    await new Promise((resolve, reject) => this.priorityService.savePriority(priorityData)
      .subscribe(
        res => {
          this.priorityCreated.emit();
          this.priorityCreateForm.reset();
          this.alertify.success('Saved priority');
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save priority');
          reject(new Error('Failed'));
      }
    ));
  }
}
