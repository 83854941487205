import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { uptime } from 'process';
import { Organization } from 'src/app/_models/organization';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';

@Component({
  selector: 'app-organization-selection',
  templateUrl: './organization-selection.component.html',
  styleUrls: ['./organization-selection.component.css']
})
export class OrganizationSelectionComponent implements OnInit {

  userOrganizations = Array<Organization>();
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  activeOrganization: string;

  constructor(private authService: AuthService, private alertify: AlertifyService,
    public organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService) { }

  ngOnInit(): void {
    this.searchOrganizations();
  }

  searchOrganizations() {
    this.organizationService.getOrganizations(this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.userOrganizations = res.results;
          this.resultCount = res.count[0]['totalCount'];

          const activeOrganizationId = localStorage.getItem('activeOrganizationId');
          if (!this.userOrganizations) {
            return;
          }
          let userOrganization = this.userOrganizations.find(uo => uo.organizationId.toString() === activeOrganizationId)
          this.setActiveOrganization(userOrganization ?? this.userOrganizations[0] ?? null);


          this.spinner.hide();
        }, err => {
          this.alertify.error('Failed to load organizations');
      }
    );
  }

  setActiveOrganization(organization: Organization) {
    if (!this.userOrganizations || !organization) {
      localStorage.removeItem('activeOrganizationId');
      localStorage.setItem('activeOrganizationName', 'None');
      return;
    }

    localStorage.setItem('activeOrganizationId', organization.organizationId.toString());
    localStorage.setItem('activeOrganizationName', organization.organizationName);
  }
}
