import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class RoleService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  saveRole(rolesData: any) {
    let body = {
      organizationId: rolesData.organizationId,
      userId: this.authService.currentUser.id,
      name: rolesData.name,
      description: rolesData.description
    };
    return this.http.post<any>(this.baseUrl + 'role/create', body);
  }

  getRoles(organizationId: number, perPage: number, pageNumber: number) {
    return this.http.get<any>(this.baseUrl + 'roles/get/' + organizationId + '/' + this.authService.currentUser.id + '/' + perPage + '/' + pageNumber);
  }

  editRoles(rolesData: any, organizationId: number) {
    rolesData.userId = this.authService.decodedToken.id;
    rolesData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'roles/edit', rolesData);
  }

  deleteRole(roleId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.decodedToken.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'role/delete/' + roleId, options);
  }

  updateUserRole(organizationId: number, roleId: number, userId: number, relationTable: string) {
    const body = {
      ownerId: this.authService.decodedToken.id,
      organizationId,
      roleId,
      userId,
      relationTable
    };
    return this.http.post<any>(this.baseUrl + 'userRole/update', body);
  }
}