import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { resourceUsage } from 'process';

@Injectable({
  providedIn: 'root'
})
export class PermissionService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUserPermissions(organizationId: number) {
    const userId = this.authService.decodedToken.id;
    if (!userId || !organizationId) {
      return;
    }

    return this.http.get<any>(this.baseUrl + 'userPermissions/get/' + userId + '/' + organizationId);
  }
}
