import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Tag } from '../_models/tag';

@Injectable({
  providedIn: 'root'
})
export class TagService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getTags(entityId: number, relationTable: string, projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      projectId,
      organizationId
    }
    return this.http.post<any>(this.baseUrl + 'tags/get/' + relationTable + '/' + entityId, body);
  }

  getProjectTags(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      projectId,
      organizationId
    }
    return this.http.post<any>(this.baseUrl + 'projectTags/get', body);
  }

  saveTag(tagData, projectId: number) {
    tagData.userId = this.authService.currentUser.id;
    tagData.projectId = projectId;
    return this.http.post<any>(this.baseUrl + 'tag/save', tagData);
  }

  editTag(data, projectId: number) {
    const commentData = {
      tagData: data,
      userId: this.authService.currentUser.id,
      projectId
    };
    return this.http.post<any>(this.baseUrl + 'tag/edit', commentData);
  }

  deleteTag(tagId: number, entityId: number, relationTable: string, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        entityId,
        relationTable,
        userId: this.authService.currentUser.id,
        projectId,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'tag/delete/' + tagId, options);
  }
}
