import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Organization } from 'src/app/_models/organization';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TeamService } from 'src/app/_services/team.service';

@Component({
  selector: 'app-team-create',
  templateUrl: './team-create.component.html',
  styleUrls: ['./team-create.component.css']
})
export class TeamCreateComponent implements OnInit {
  @Input() organizations: Array<Organization> = new Array<Organization>();
  @Input() activeOrganizationId: number = null;

  @Output() teamCreated: EventEmitter<any> = new EventEmitter();

  localFormat = 'YYYY-MM-DD HH:mm:ss';
  teamCreateForm: UntypedFormGroup;
  teams = [];
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;

  constructor(private teamService: TeamService, private organizationService: OrganizationService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createTeamForm();
  }

  createTeamForm() {
    this.teamCreateForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', '']
    });
  }

  saveTeam()
  {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const teamData = {
      ownerId: this.authService.decodedToken.id,
      name: this.teamCreateForm.value['name'],
      description: this.teamCreateForm.value['description'],
      organizationId: this.activeOrganizationId,
    };

    this.teamService.saveTeam(teamData)
      .subscribe(
        res => {
          this.teamCreated.emit();
          this.teamCreateForm.reset();
          this.alertify.success('Saved team');
        }, err => {
          this.alertify.error('Failed to save team');
      }
    );
  }

}
