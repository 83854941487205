import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { TaskTypes } from '../../_models/taskTypes';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ProjectService } from '../../_services/project.service';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-project-versions-create',
  templateUrl: './project-versions-create.component.html',
  styleUrls: ['./project-versions-create.component.css']
})
export class ProjectVersionsCreateComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  @Output() projectVersionCreated: EventEmitter<any> = new EventEmitter();
  projectVersionCreateData = {versionId: '', projectId: '', versionName: ''};
  projectVersionForm: UntypedFormGroup;
  taskTypes: TaskTypes[];

  constructor(private router: Router, private fb: UntypedFormBuilder, private authService: AuthService, private alertify: AlertifyService, 
              private projectService: ProjectService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createTaskForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
      }
    }
  }

  createTaskForm() {
    this.projectVersionForm = this.fb.group({
      versionname: ['', Validators.required]
    });
  }

  async saveProjectVersion() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load project versions');
      return;
    }

    const versionData = {
      projectId: this.projectId,
      versionName: this.projectVersionForm.value['versionname'],
      organizationId: this.activeOrganizationId
    };

    await new Promise((resolve, reject) => this.projectService.saveProjectVersion(versionData)
      .subscribe(
        res => {
          this.projectVersionCreated.emit();
          this.alertify.success('Saved version');
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save version');
          reject(new Error('failed'));
      }
    ));
  }

}
