<div class="card rounded-0">
  <div class="card-header">
    <h3 class="mb-0">Create new todo list</h3>
  </div>

  <div class="card-body">
    <form [formGroup]="todoForm" class="form">
      <table style="width: 100%;">
          <tr>
            <td colspan="2">
              <label for="">*Todo list name</label>
              <input 
                [ngClass]="{'is-invalid': todoForm.get('name').errors && todoForm.get('name').touched}"
                formControlName="name"
                name="name"
                type="text"
                class="form-control">
              <div class="invalid-feedback worktime-date-field">Todo list name is required</div>
            </td>
          </tr>
          <tr>
            <td>
              <div>
                <label for="">Deadline</label>
                <input 
                  [ngClass]="{'is-invalid': todoForm.get('deadline').errors && todoForm.get('deadline').touched}"
                  formControlName="deadline"
                  class="form-control"
                  placeholder="Deadline"
                  type="text"
                  bsDatepicker
                  [bsConfig]="bsConfig">
              </div>
            </td>
          </tr>
        <tr class="bottom-row">
          <td>
            <button [disabled]="!todoForm.valid" name="saveTodoList" (click)="saveTodoList()" type="button" class="btn btn-success float-left">Save</button>
          </td>
        </tr>
      </table>
    </form>
  </div>
</div>
  
  