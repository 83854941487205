<div class="col-md-6 mx-auto card-header">
    <h3 class="mb-0">Priorities</h3>
    <div class="board-search-field">
        <label for="">Project</label>
        <div *ngIf="projects && projects.length > 0" class="btn-group" style="width: 100%;" dropdown>
            <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-basic">
            Projects <span class="caret"></span>
            </button>
            <ul id="project-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li *ngFor="let project of projects" role="menuitem" (click)="setProject(project.projectId)">{{project.projectName}}</li>
            </ul>
            <p>{{getProjectName()}}</p>
        </div>
    </div>
</div>

<ng-container *ngIf="checkPagePermissions('priorities.view')">
    <app-priority-create *ngIf="checkPagePermissions('priorities.create')" [projectId]="projectId" [activeOrganizationId]="activeOrganizationId" (priorityCreated)="updatePriorities($event)"></app-priority-create>
    <app-priority-edit *ngIf="checkPagePermissions('priorities.edit')" [projectId]="projectId" [activeOrganizationId]="activeOrganizationId"></app-priority-edit>
</ng-container>
