import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ProjectService } from '../../_services/project.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectType } from '../../_models/projectType';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableProjectResult } from 'src/app/_models/expandableProjectResult';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { UserToShow } from 'src/app/_models/userToShow';
import { Team } from 'src/app/_models/team';
import { TeamService } from 'src/app/_services/team.service';

@Component({
  selector: 'app-project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.css']
})
export class ProjectEditComponent implements OnInit {
  @Input() activeOrganizationId: number = null;

  userProjects = [];
  minDate;
  maxDate;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  projectTypes: ProjectType[];
  relationTable: string = 'Projects';
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedStates: Array<ExpandableProjectResult> = new Array<ExpandableProjectResult>();
  isAllExpanded: boolean = false;
  isInitialized: boolean = false;
  projectUsers = new Map<number, Array<UserToShow>>();

  activeTeam;
  organizationTeams: Array<Team> = new Array<Team>();
  teamUsers = new Map<number, Array<UserToShow>>();

  lockedUsers = new Map<number, Array<UserToShow>>();

  constructor(private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService,
              private projectService: ProjectService, private router: Router, private spinner: NgxSpinnerService,
              private teamService: TeamService) { }

  ngOnInit(): void {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');
  }

  async changePage(pagination: PaginParameters) {
    this.spinner.show();
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    await this.getProjectTypes();
    await this.searchProjects();
    await this.findProjectUsers();
    this.spinner.hide();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId' && this.activeOrganizationId && !this.isInitialized) {
        this.spinner.show();
        await this.getProjectTypes();
        await this.searchProjects();
        await this.findProjectUsers();
        await this.searchTeams();
        this.isInitialized = true;
        this.spinner.hide();
      }
    }
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async searchProjects(activeOrganizationId: number = null) {
    if (activeOrganizationId) {
      this.activeOrganizationId = activeOrganizationId;
    }

    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getPaginatedUsersProjects(this.activeOrganizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.userProjects = [];
          this.expandedStates = new Array<ExpandableProjectResult>();
          this.userProjects = res.results;
          this.userProjects.forEach(project => {
            this.expandedStates.push(
              {
                project,
                isActive: this.isAllExpanded
              }
            );
          });
          this.resultCount = res.count[0]['totalCount'];

          this.userProjects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss');
            }
          });
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load projects');
          reject(new Error('failed'));
          this.spinner.hide();
      }
    ));
  }

  getProjectTypeFromTypeId(typeId: number) {
    const type = this.projectTypes.find(pt => pt.projectTypeId === typeId);
    if (type) {
      return type.projectTypeName;
    }
    else {
      return null;
    }
  }

  getTeamFromTeamId(teamId: number) {
    const team = this.organizationTeams.find(ot => ot.teamId === teamId);
    if (team) {
      return team.name;
    }
    else {
      return null;
    }
  }

  setProject(projectId: number, projectType: number) {
    this.userProjects.find(p => p.projectId === projectId).projectType = projectType;
  }

  setTeam(projectId: number, teamId: number) {
    this.userProjects.find(p => p.projectId === projectId).teamId = teamId;
  }

  async getProjectTypes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    await new Promise((resolve, reject) => this.projectService.getProjectTypes(this.activeOrganizationId)
      .subscribe(
        res => {
            this.projectTypes = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('failed'));
      }
    ));
  }

  async searchTeams() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.teamService.getTeams(this.activeOrganizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.organizationTeams = res.results;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load teams');
          reject(new Error('failed'));
      }
    ));
  }

  isOwner(projectId: number): boolean {
    const project = this.userProjects.find(up => up.projectId === projectId);
    if (!project) {
      return false;
    }

    return project.ownerId === this.authService.currentUser.id;
  }

  saveProjects() {
    if (!this.activeOrganizationId) {
      this.alertify.error('Failed to save project changes');
      return;
    }

    let editedProjectData = [];

    for (let i = 0; i < this.userProjects.length; i++) {
      const time = '00:00';
      let formatedDeadline;
      let deadline = this.userProjects[i].projectDeadline;
      if (!deadline) {
        formatedDeadline = null;
      }
      else {
        formatedDeadline = moment(deadline + time).format('YYYY-MM-DD HH:DD');
      }

      const projectsFormatedData = {
        projectId: this.userProjects[i].projectId,
        projectName: this.userProjects[i].projectName,
        projectType: this.userProjects[i].projectType,
        ownerId: this.userProjects[i].ownerId,
        projectDeadline: formatedDeadline,
        description: this.userProjects[i].description,
        assigneeId: null,
        teamId: this.userProjects[i].teamId
      };

      if (formatedDeadline != null && !moment(projectsFormatedData.projectDeadline).isValid()) {
        this.alertify.error('Invalid deadline');
        return;
      }

      editedProjectData.push(projectsFormatedData);
    }

    const projectData = {
      projectData: editedProjectData
    };

    this.projectService.editProjects(projectData, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved project changes');
      }, err => {
        this.alertify.error('Failed to save project changes');
      }
    );
  }

  deleteProject(projectId: number) {
    if (!this.activeOrganizationId) {
      this.alertify.error('Failed to delete project');
      return;
    }

    this.alertify.confirm('Are you sure you want to delete this project', () => {
      this.projectService.deleteProject(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted project');
          this.searchProjects();
        },
        err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete project');
            }
          }
        }
      );
    });
  }

  async saveUser(event: any) {
    if (!event || !event.entityId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }
    const projectUserData = {
      projectId: event.entityId,
      username: event.username,
      organizationId: this.activeOrganizationId
    };
    await new Promise((resolve, reject) => this.projectService.saveProjectUser(projectUserData)
    .subscribe(
      res => {
        const newUser: UserToShow = {
          userId: res.userId,
          username: event.username,
          firstname: res.firstname,
          lastname: res.lastname
        }
        let users = this.projectUsers.get(event.entityId);
        users.push(newUser);
        this.projectUsers.set(event.entityId, users);
        this.alertify.success('Added user to project');
        resolve(true);
      }, err => {
        this.alertify.error('Failed to save changes');
        reject(new Error('Failed'));
      }
    ));
  }

  async deleteUser(event: any) {
    if (!event || !event.entityId || !event.userId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to remove this user from the project?', () => {
      this.projectService.deleteProjectUser(event.entityId, event.userId, this.activeOrganizationId)
      .subscribe(
        res => {
          let users = this.projectUsers.get(event.entityId);
          let userToRemove = users.find(u => u.userId === event.userId);
          let index = users.indexOf(userToRemove);
          users.splice(index, 1);
          this.projectUsers.set(event.entityId, users);
          this.alertify.success('Succesfully removed user from the project');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to remove user from project');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }

  async findProjectUsers() {
    this.projectUsers = new Map<number, Array<UserToShow>>();
    for (let project of this.userProjects) {
      await this.getProjectUsers(project.projectId);
      if (project.teamId) {
        await this.getTeamUsers(project.teamId, project.projectId);
      }
   }
  }

  async getProjectUsers(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectUsers(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectUsers.set(projectId, res);
          this.getLockedUsers(null, projectId);
          resolve(true);
        }, err => {
          reject(new Error('failed'));
        }
      )
    );
  }

  getProjectUser(projectId: number) {
    return this.projectUsers.get(projectId);
  }

  async getTeamUsers(teamId: number, projectId: number) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }
    this.teamUsers = new Map<number, Array<UserToShow>>();
    await new Promise((resolve, reject) => this.teamService.getTeamUsers(teamId, this.activeOrganizationId)
      .subscribe(
        res => {
          let users = this.projectUsers.get(projectId);
          for (let user of res) {
            if (!users.find(u => u.userId === user.userId)) {
              users.push(user);
            }
          }
          this.projectUsers.set(projectId, users);
          this.teamUsers.set(teamId, res);

          this.getLockedUsers(teamId, projectId);
          resolve(true);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to get users');
            }
          }
          reject(new Error('failed'));
        }
      )
    );
  }

  getLockedUsers(teamId: number, projectId: number) {
    let projectUsersToLock: UserToShow[] = [];
    if (teamId) {
      projectUsersToLock = this.teamUsers.get(teamId);
    }
    const ownerId = this.userProjects.find(up => up.projectId === projectId).ownerId;
    if (ownerId) {
      const projectUsers: UserToShow[] = this.projectUsers.get(projectId);
      const projectOwner: UserToShow = projectUsers.find(pu => pu.userId === ownerId);
      projectUsersToLock.push(projectOwner);
    }
    this.lockedUsers.set(projectId, projectUsersToLock);
  }

  getLockedUsersForProject(projectId: number) {
    return this.lockedUsers.get(projectId);
  }

  getTeamUser(teamId: number) {
    return this.teamUsers.get(teamId);
  }

  expandRow(row: ExpandableProjectResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }
}