import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { UserEntityOptions } from '../_enums/user-entity-options';
import { Role } from '../_models/role';
import { UserToShow } from '../_models/userToShow';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { ProjectService } from '../_services/project.service';
import { UserService } from '../_services/user.service';

@Component({
  selector: 'app-entity-users',
  templateUrl: './entity-users.component.html',
  styleUrls: ['./entity-users.component.css']
})
export class EntityUsersComponent implements OnInit {
  @Input() entityId: number;
  @Input() organizationId: number;
  @Input() entityUsers: Array<UserToShow> = new Array<UserToShow>();
  @Input() userRoles: Array<UserToShow> = new Array<UserToShow>();
  @Input() canHighlight: boolean = false;
  @Input() lockedUsers: Array<UserToShow> = new Array<UserToShow>();
  @Input() relationTable: string;
  @Output() addUserEvent: EventEmitter<any> = new EventEmitter();
  @Output() deleteUserEvent: EventEmitter<any> = new EventEmitter();
  @Output() highlightUserEvent: EventEmitter<any> = new EventEmitter();
  @Output() updateUserRoleEvent: EventEmitter<any> = new EventEmitter();
  entityUsersForm: UntypedFormGroup;

  constructor(private projectService: ProjectService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private userService: UserService) { }

  ngOnInit(): void {
    this.createEntityUsersForm();
  }

  createEntityUsersForm() {
    this.entityUsersForm = this.fb.group({
      username: ['', Validators.required]
    });
  }

  saveUser() {
    const user = {
      entityId: this.entityId, 
      username: this.entityUsersForm.get('username').value
    }
    this.addUserEvent.emit(user);
  }

  deleteUser(userId: number) {
    const user = {
      userId: userId,
      entityId: this.entityId
    }
    this.deleteUserEvent.emit(user);
  }

  highlightUser(userId: number) {
    const user = {
      userId: userId,
      entityId: this.entityId
    }
    this.highlightUserEvent.emit(user);
  }

  canDeleteUser(userId: number) {
    return (!this.lockedUsers || !this.lockedUsers.find(le => le.userId === userId)) ? true : false;
  }

  setRole(role: Role, userId: number) {
    const event = {
      organizationId: this.organizationId ?? null,
      roleId: role.id ?? null,
      userId: userId ?? null
    };

    this.updateUserRoleEvent.emit(event);
    let user = this.entityUsers.find(eu => eu.userId === userId);
    user.roleId = role.id;
    user.rolename = role.roleName;
  }
}
