import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Comment } from '../_models/comment';
import { StateMapping } from '../_models/stateMapping';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { CommentService } from '../_services/comment.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';
import { ActiveOrganizationService } from '../_services/active-organization.service';

@Component({
  selector: 'app-comment',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.css']
})
export class CommentComponent implements OnInit {
  @Input() entityId: number;
  @Input() relationTable: string;
  @Input() projectId: number;
  @Input() activeOrganizationId: number;

  entityComments: Array<Comment> = new Array<Comment>();
  commentForm: UntypedFormGroup;
  stateMappings: Array<StateMapping> = new Array<StateMapping>();
  isGettingComments = false;

  commentsForm: UntypedFormGroup;

  constructor(private commentService: CommentService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    await this.createCommentForm();
    await this.getEntityComments();
  }

  async createCommentForm() {
    this.commentsForm = this.fb.group({
      newComment: ['', Validators.required],
      comments: new UntypedFormArray([])
    });
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        if (!this.activeOrganizationId) {
          return;
        }
        try {
          this.createCommentForm();
        } catch (error) {
          console.log('Error: ', error);
        }
        try {
          await this.getEntityComments();
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    }
  }

  get f() { return this.commentsForm.controls; }
  get t() { return this.f.comments as UntypedFormArray; }

  async getEntityComments() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId() || this.isGettingComments) {
      return;
    }
    this.isGettingComments = true;

    this.entityComments = new Array<Comment>();
    this.stateMappings = new Array<StateMapping>();

    this.createCommentForm();

    await new Promise((resolve, reject) => this.commentService.getComments(this.entityId, this.relationTable, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.entityComments = res;
          if (this.entityComments && this.entityComments.length) {
            this.entityComments.forEach(comment => {
              this.commentsForm.get('comments')
              let state: StateMapping = {
                id: comment.commentId,
                value: false
              };
              this.stateMappings.push(state);

              this.t.push(this.fb.group({
                commentId: [comment.commentId, Validators.required],
                comment: [comment.comment, Validators.required],
                userId: [comment.userId, Validators.required],
                username: [comment.username, Validators.required]
              }));
            });
          }
          this.isGettingComments = false;
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load comments');
        this.isGettingComments = false;
        reject(new Error('failed'));
      }
    ));
  }

  isOwner(userId: number) {
    if (!userId) {
      return false;
    }
    return (userId === this.authService.currentUser.id);
  }

  canEdit(comment) {
    if (!comment.userId || !comment.commentId) {
      return false;
    }
    return (comment.userId === this.authService.currentUser.id && this.stateMappings.length && this.stateMappings.find(sm => sm.id === comment.commentId)?.value);
  }

  getEditState(commentId: number) {
    if (!this.stateMappings || !this.stateMappings.length || !commentId) {
      return 'Edit';
    }
    return this.stateMappings.find(sm => sm.id === commentId)?.value === true ? 'Save' : 'Edit';
  }

  async saveComment() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    if (!this.commentsForm.value['newComment']) {
      return;
    }

    let commentData = {
      entityId: this.entityId,
      relationTable: this.relationTable,
      userId: this.authService.currentUser.id,
      comment: this.commentsForm.value['newComment']
    };

    await new Promise((resolve, reject) => this.commentService.saveComment(commentData, this.projectId, this.activeOrganizationId)
    .subscribe(
        res => {
          let newComment: Comment = {
            commentId: res.insertId,
            entityId: this.entityId,
            relationTable: this.relationTable,
            userId: this.authService.currentUser.id,
            comment: this.commentsForm.get('newComment').value,
            createdAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            updatedAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            username: this.authService.currentUser.user
          };
          
          this.entityComments.push(newComment);
          this.commentsForm.patchValue({comment: ''});

          this.t.push(this.fb.group({
            commentId: [res.insertId, Validators.required],
            comment: [this.commentsForm.get('newComment').value, Validators.required],
            userId: [this.authService.currentUser.id, Validators.required],
            username: [this.authService.currentUser.user, Validators.required]
        }));

          let state: StateMapping = {
            id: newComment.commentId,
            value: false
          };
          this.stateMappings.push(state);
          this.alertify.success('Saved comment');
          this.commentsForm.value['newComment']
          this.commentsForm.patchValue({newComment: ''})
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save comment');
        reject(new Error('failed'));
      }
    ));
  }

  async activateEditState(comment) {
    if (!comment.commentId || !comment.userId || !comment.comment) {
      return;
    }
    let state: StateMapping = this.stateMappings.find(sm => sm.id === comment.commentId);
    if (!state) {
      return;
    }
    if (state.value) {
      this.editComment(comment);
    }
    state.value = !state.value;
  }

  async editComment(comment) {
    if (!comment.commentId || !comment.userId || !comment.comment || !this.projectId  || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let commentData = {
      commentId: comment.commentId,
      entityId: this.entityId,
      relationTable: this.relationTable,
      userId: this.authService.currentUser.id,
      comment: comment.comment,
      createdAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
      updatedAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
    };

    await new Promise((resolve, reject) => this.commentService.editComment(commentData, this.projectId, this.activeOrganizationId)
    .subscribe(
        res => {
          let newComment: Comment = {
            commentId: comment.commentId,
            entityId: this.entityId,
            relationTable: this.relationTable,
            userId: this.authService.currentUser.id,
            comment: comment.comment,
            createdAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            updatedAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            username: this.authService.currentUser.user
          };
          this.alertify.success('Saved comment');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save comment');
        reject(new Error('failed'));
      }
    ));
  }

  async deleteComment(commentId: number) {
    const comment: Comment = this.entityComments.find(c => c.commentId === commentId);
    if (!comment|| !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to delete comment');
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this comment', () => {
      this.commentService.deleteComments(comment.commentId, this.entityId, this.relationTable, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted comment');
          this.getEntityComments();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete comment')
            }
          }
          reject(new Error('failed'));
        }
      );
    }));
  }
}
