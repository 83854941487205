import { Component, OnInit, ViewChild } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ProjectService } from '../../_services/project.service';
import { TaskService } from '../../_services/task.service';
import * as moment from 'moment';
import { TaskstatesEditComponent } from '../taskstates-edit/taskstates-edit.component';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { PagePermission } from 'src/app/_models/pagePermission';

@Component({
  selector: 'app-task-states',
  templateUrl: './task-states.component.html',
  styleUrls: ['./task-states.component.css']
})
export class TaskStatesComponent implements OnInit {
  projects = [];
  projectId: number;

  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  @ViewChild(TaskstatesEditComponent) child: TaskstatesEditComponent;


  constructor(private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService,
              private projectService: ProjectService, private taskService: TaskService) { }

    ngOnInit(): void {
      this.activeOrganizationService.getOrganizations();
      this.generatePagePermissions();
    }
  
    ngDoCheck() {
      this.checkActiveOrganization();
    }

    generatePagePermissions() {
      let pagePermission1: PagePermission = {
        value: 0,
        permissionstring: 'taskStates.view'
      };
      let pagePermission2: PagePermission = {
        value: 0,
        permissionstring: 'taskStates.edit'
      };
      let pagePermission3: PagePermission = {
        value: 0,
        permissionstring: 'taskStates.create'
      };
      let pagePermission4: PagePermission = {
        value: 0,
        permissionstring: 'project.view'
      };
  
      this.pagePermissions.push(pagePermission1);
      this.pagePermissions.push(pagePermission2);
      this.pagePermissions.push(pagePermission3);
      this.pagePermissions.push(pagePermission4);
    }
  
    async checkActiveOrganization() {
      const previousOrganizationId = this.activeOrganizationId;
      let newId = this.activeOrganizationService.validateActiveOrganization();
      if (!newId) {
        this.activeOrganizationService.getOrganizations();
        return;
      }
      if (previousOrganizationId !== newId && !isNaN(newId)) {
        this.activeOrganizationId = Number(newId);
        await this.activeOrganizationService.checkPermissions(this.pagePermissions);
        this.searchProjects();
      }
    }

  searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projects = res.results;
          this.projects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss');
            }
          });

          let newId = Number(localStorage.getItem('currentProject'));

          if (newId && this.projectId !== newId) {
            this.projectId = newId;
          }

          if (!this.projects.find(p => p.projectId === this.projectId)){
            this.projectId = this.projects[0].projectId;
            localStorage.setItem('currentProject', this.projectId.toString());
          }
        }, err => {
          this.alertify.error('Failed to save changes');
      }
    );
  }

  setProject(projectId: number) {
    if (this.projects.find(p => p.projectId === projectId)){
      localStorage.setItem('currentProject', projectId.toString());
      this.projectId = projectId;
    }
  }

  search() {
    console.log('Implement searching of something here?');
  }

  getProjectName(): string {
    return this.projects.find(p => p.projectId === this.projectId).projectName;
  }

  updateTaskStates(events: any) {
    if (!this.activeOrganizationId || !this.child) {
      return;
    }

    this.child.searchTaskStates(this.projectId);
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
