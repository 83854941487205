<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new project</h3>
        </div>

        <div class="card-body">
          <form *ngIf="projectTypes" [formGroup]="projectCreateForm" class="form">
            <table style="width: 100%;">
              <tr>
                  <td>
                    <label for="">*Project name</label>
                    <input 
                      [ngClass]="{'is-invalid': projectCreateForm.get('projectname').errors && projectCreateForm.get('projectname').touched}"
                      formControlName="projectname"
                      name="projectname"
                      type="text"
                      class="form-control">
                      <div class="invalid-feedback worktime-date-field">Project name is required</div>
                  </td>
              </tr>
              <tr>
                <td>
                    <label for="">Description</label>
                    <textarea 
                      formControlName="description"
                      name="description"
                      type="text"
                      class="form-control">
                    </textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="">*Project type</label>
                  <div *ngIf="projectTypes.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="button-basic"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Project type <span class="caret"></span>
                    </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let type of projectTypes" role="menuitem" (click)="setProjectType(type.projectTypeId, type.projectTypeName)">{{type.projectTypeName}}</li>
                      </ul>
                  </div>
                  <input 
                      *ngIf="activeProjectType"
                      [ngClass]="{'is-invalid': projectCreateForm.get('projecttype').errors && projectCreateForm.get('projecttype').touched}"
                      formControlName="projecttype"
                      name="projecttype"
                      type="text"
                      class="form-control">
                  <div class="invalid-feedback worktime-date-field">Project type is required</div>
                  <div class="alert" *ngIf="projectTypes.length == 0">No project types to select from!</div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="">Team</label>
                  <div *ngIf="organizationTeams.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="button-basic"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Team <span class="caret"></span>
                    </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" (click)="setTeam(null, null)">No team</li>
                        <li *ngFor="let team of organizationTeams" role="menuitem" (click)="setTeam(team.teamId, team.name)">{{team.name}}</li>
                      </ul>
                  </div>
                  <input 
                      *ngIf="activeTeam"
                      [ngClass]="{'is-invalid': projectCreateForm.get('teamname').errors && projectCreateForm.get('teamname').touched}"
                      formControlName="teamname"
                      name="teamname"
                      type="text"
                      class="form-control">
                  <div class="alert" *ngIf="organizationTeams.length == 0">No project types to select from!</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="">Deadline</label>
                    <input formControlName="projectdeadline"
                    name="projectdeadline"
                    class="form-control worktime-time-field worktime-date-field-input"
                    placeholder="Project deadline"
                    type="text"
                    bsDatepicker
                    [bsConfig]="bsConfig">
                </div>
              </td>
            </tr>
            <tr class="bottom-row">
              <td>
                <button [disabled]="!projectCreateForm.valid" (click)="saveProject()" type="button" class="btn btn-success float-left">Save</button>
              </td>
            </tr>
          </table>
        </form>

        </div>
      </div>
    </div>
  </div>

