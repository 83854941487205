<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Register</h3>
        </div>
        <div class="card-body">
          <p>Registration has been temporarily disabled for safety reasons...</p>
          <p>This software is currently in development/testing phase and not meant for public use</p>
          <!-- <form [formGroup]="registerForm" class="form">
            <div class="form-group">
              <label for="">*Username</label>
              <input formControlName="username" name="username" type="text" [ngClass]="{'is-invalid': registerForm.get('username').errors 
              && registerForm.get('username').touched}" class="form-control rounded-0" required>
              <div class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <label for="">Firstname</label>
                <input formControlName="firstname" name="firstname" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">Lastname</label>
              <input formControlName="lastname" name="lastname" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">Weekly workhours</label>
              <input formControlName="weeklyWorkHours" name="weeklyWorkHours" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">Daily workhours</label>
              <input formControlName="dailyWorkHours" name="dailyWorkHours" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">Allow external entities</label>
              <input formControlName="allowExternalEntities" name="allowExternalEntities" type="checkbox" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">*Password</label>
              <input type="password"
                [ngClass]="{'is-invalid': registerForm.get('password').errors
                  && registerForm.get('password').touched}"
                class="form-control"
                formControlName="password"
                placeholder="Password">
              <div class="invalid-feedback"
                *ngIf="registerForm.get('password').hasError('required')
                && registerForm.get('password').touched">
                Password is required
              </div>
              <div class="invalid-feedback"
                *ngIf="registerForm.get('password').hasError('minlength')
                && registerForm.get('password').touched">
                Password must be at least {{minPasswordLength}} characters
              </div>
              <div class="invalid-feedback"
                *ngIf="registerForm.get('password').hasError('maxlength')
                && registerForm.get('password').touched">
                Password cannot exceed {{maxPasswordLength}} characters
              </div>
            </div>

            <div class="form-group">
              <label for="">*Confirm password</label>
              <input type="password" 
                [ngClass]="{'is-invalid': registerForm.get('confirmPassword').errors
                  && registerForm.get('confirmPassword').touched
                  || registerForm.get('confirmPassword').touched
                  && registerForm.hasError('mismatch')}"
                class="form-control" 
                formControlName="confirmPassword" 
                placeholder="Confirm Password">
              <div class="invalid-feedback"
                *ngIf="registerForm.get('confirmPassword').hasError('required')
                && registerForm.get('confirmPassword').touched">
                Password is required
              </div>
              <div class="invalid-feedback"
                *ngIf="registerForm.hasError('mismatch')
                && registerForm.get('confirmPassword').touched">
                Passwords must match
              </div>
            </div>

            <button (click)="registerUser()" [disabled]="!registerForm.valid" type="button" class="btn btn-primary float-right">Register</button>
          </form> -->
        </div>
      </div>
    </div>
  </div>