import { Component, OnInit, ViewChild } from '@angular/core';
import { TaskEditComponent } from '../task-edit/task-edit.component';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { TaskCreateComponent } from '../task-create/task-create.component';
import { PagePermission } from 'src/app/_models/pagePermission';


@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.css']
})
export class TasksComponent implements OnInit {

  @ViewChild(TaskEditComponent) child: TaskEditComponent;

  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'tasks.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'tasks.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'tasks.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };
    let pagePermission5: PagePermission = {
      value: 0,
      permissionstring: 'taskStates.view'
    };
    let pagePermission6: PagePermission = {
      value: 0,
      permissionstring: 'taskTypes.view'
    };


    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
    this.pagePermissions.push(pagePermission5);
    this.pagePermissions.push(pagePermission6);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      this.updateTasks(null);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    }
  }

  updateTasks(events: any) {
    if (!this.child || !this.activeOrganizationId) {
      return;
    }

    this.child.getTasks();
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
