<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <!-- <app-search pageHeader="Edit teams" [hasDates]=false [hasOnlyProject]=false [hasSelectAllProjects]=false [hasSelectAll]=true [searchTags]=false (searchParametersChanged)="search($event)"></app-search> -->
        <div class="card-header">
          <h3 class="mb-0">Edit roles</h3>
          <!-- <div class="worktime-search-limit">
            <div class="worktime-search-field">
                <label for="">From:</label>
                <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <div class="worktime-search-field">
              <label for="">To:</label>
              <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <button class="worktime-search-field" (click)="searchTeams()" [disabled]="false" type="button" class="btn btn-info mt-4 mb-0">Search</button>
          </div> -->

        <div class="card-body">
            <form class="form">
              <div class="horizontal-line mb-5">
                <button *ngIf="roles && roles.length > 0" (click)="saveRoles()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
                <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
              </div>
                <table class="table-full-width" *ngIf="roles">
                      <span *ngFor="let row of expandedRoles">
                          <tr>
                              <td style="width: 25%;">
                              <!-- <button *ngIf="isOwner(row.organization.organizationId)" name="deleteOrganizationButton_{{row.organization.organizationName}}" (click)="deleteOrganization(row.organization.organizationId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button> -->
                              <button name="deleteRoleButton_{{row.role.roleName}}" (click)="deleteRole(row.role.id, row.role.organizationId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                              <button name="expandButton_{{row.role.roleName}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                              </td>
                          </tr>
                          <span *ngIf="!row.isActive">
                              <tr>
                                  <td style="width: 25%;">
                                      <label for="">*Role name</label>
                                      <p>
                                      {{row.role.roleName}}
                                      </p>
                                  </td>
                              </tr>
                          </span>
                          <span *ngIf="row.isActive">
                          <tr>
                              <td style="width: 25%;">
                              <label for="">*Role name</label>
                              <input [(ngModel)]="row.role.roleName"
                              name="rolename{{row.role.id}}"
                              type="text"
                              class="form-control">
                              <div class="invalid-feedback worktime-date-field">Role name is required</div>
                              </td>
                          </tr>
                          <tr>
                              <td>
                              <label for="">Description</label>
                              <textarea 
                              [(ngModel)]="row.role.description"
                                  name="description{{row.role.id}}"
                                  type="text"
                                  class="form-control">
                              </textarea>
                              </td>
                          </tr>
                          <tr style="width: 100%;">
                              <span class="permission-row" *ngFor="let permissionGroup of permissions; let i = index">
                                <span *ngFor="let permission of permissionGroup; let j = index">
                                  <div class="permission-col" *ngIf="permission.roleId === row.role.id">
                                      <label class="permission-label" for="">{{getReadableName(permission.permissionString)}}</label>
                                      <input [(ngModel)]="permission.value" 
                                          name="value{{row.role.id}}{{permission.permissionId}}" 
                                          class="permission-checkbox"
                                          type="checkbox">
                                  </div>
                                </span>
                              </span>
                          </tr>
                          <div class="bottom-row"></div>
                      </span>
                  </span>
                </table>
                <div class="horizontal-line mb-5">
                  <button *ngIf="roles && roles.length > 0" (click)="saveRoles()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
                </div>
            </form>
            <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>
          <div>
            <h2 *ngIf="!roles || roles.length == 0">No data for selected time period</h2>
          </div>
          <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      <!-- </div> -->
    </div>
  </div>
