import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { ProjectType } from '../_models/projectType';
import { TaskTypes } from '../_models/taskTypes';
import { TaskState } from '../_models/taskState';
import { Task } from '../_models/task';
import { Comment } from '../_models/comment';

@Injectable({
  providedIn: 'root'
})
export class TeamService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getTeams(organizationId: number, perPage?: number, pageNumber?: number) {
    const ownerId = this.authService.decodedToken.id;
    return this.http.get<any>(this.baseUrl + 'teams/get/' + organizationId + '/'  + ownerId + '/' + (perPage ? perPage : 0) + '/' + (pageNumber ? pageNumber : 0));
  }

  saveTeam(teamData) {
    return this.http.post<any>(this.baseUrl + 'team/create', teamData);
  }

  editTeams(teamsData, organizationId: number) {
    teamsData.userId = this.authService.decodedToken.id;
    teamsData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'teams/edit', teamsData);
  }

  deleteTeam(teamId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.decodedToken.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'team/delete/' + teamId, options);
  }

  getTeamUsers(teamId: number, organizationId: number) {
    const body = {
      userId: this.authService.decodedToken.id,
      teamId,
      organizationId
    };
    return this.http.post<any>(this.baseUrl + 'teamUsers/get', body);
  }

  saveTeamUser(teamUserData) {
    teamUserData.ownerId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'teamUser/save', teamUserData);
  }

  deleteTeamUser(teamId: number, userId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ownerId: this.authService.currentUser.id,
        userId,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'teamUser/delete/' + teamId, options);
  }

  setTeamLeader(leaderId: number, teamId: number, organizationId: number) {
    const data = {
      userId: this.authService.currentUser.id,
      leaderId: leaderId,
      teamId: teamId,
      organizationId
    }

    return this.http.post<any>(this.baseUrl + 'team/setLeader', data);
  }
}
