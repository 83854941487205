import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../_services/project.service';
import { ProjectVersion } from 'src/app/_models/projectVersion';
import { Project } from 'src/app/_models/project';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-project-versions-edit',
  templateUrl: './project-versions-edit.component.html',
  styleUrls: ['./project-versions-edit.component.css']
})
export class ProjectVersionsEditComponent implements OnInit {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;

  taskTypeEditForm: UntypedFormGroup;
  projectVersions: Array<ProjectVersion>;
  projects: Array<Project>;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";

  constructor(private router: Router, private fb: UntypedFormBuilder, private spinner: NgxSpinnerService,
              private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService,
              private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.projectId = Number(localStorage.getItem('currentProject'));
    this.spinner.show();
    try {
      await this.searchProjectVersions();
    } catch (error) {
      console.log('Error: ', error);
    }
    this.spinner.hide();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
        if (this.projectId) {
          this.spinner.show();
          try {
            await this.searchProjectVersions();
          } catch (error) {
            console.log('Error: ', error);
          }
          this.spinner.hide();
        }
      }
    }

  }

  async searchProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectVersions = new Array<ProjectVersion>();

    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
    .subscribe(
      res => {
        this.projectVersions = res;
        resolve(true);
    }, err => {
      this.alertify.error('Failed to load project versions');
      reject(new Error('failed'));
    }
  ));
  }

  async saveProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to save changes');
      return;
    }

    let editedProjectVersions = [];
    for (let i = 0; i < this.projectVersions.length; i++) {

      const formatedTasktypesData = {
        versionId: this.projectVersions[i].versionId,
        projectId: this.projectVersions[i].projectId,
        versionName: this.projectVersions[i].versionName
      };

      editedProjectVersions.push(formatedTasktypesData);
    }

    const projectVersionData =
    {
      versionData: editedProjectVersions
    };

    await new Promise((resolve, reject) => this.projectService.editProjectVersions(projectVersionData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved changes');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to save changes');
            }
          }
          reject(new Error('failed'));
        }
      ));
  }

  async deleteProjectVersion(versionId: number) {
    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this version?', () => {
      this.projectService.deleteProjectVersion(versionId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted version');
          this.searchProjectVersions();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete version');
            }
          }
          reject(new Error('failed'));
        }
      );
    }));
  }

}
