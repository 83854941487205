import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Organization } from 'src/app/_models/organization';
import { PagePermission } from 'src/app/_models/pagePermission';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { TeamService } from 'src/app/_services/team.service';
import { TeamEditComponent } from '../team-edit/team-edit.component';

@Component({
  selector: 'app-teams',
  templateUrl: './teams.component.html',
  styleUrls: ['./teams.component.css']
})
export class TeamsComponent implements OnInit {

  @ViewChild(TeamEditComponent) child: TeamEditComponent;
  perPage: number = 10;
  pageNumber: number = 0;
  organizations: Array<Organization> = new Array<Organization>();
  resultCount: number = 0;
  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private teamService: TeamService, private authService: AuthService, private alertify: AlertifyService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService,
    private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
    this.searchOrganizations();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'team.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'team.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'team.create'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
  }

  searchOrganizations() {
    this.organizationService.getOrganizations(this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.organizations = res.results;
          this.resultCount = res.count[0]['totalCount'];
        }, err => {
          this.alertify.error('Failed to load organizations');
      }
    );
  }

  ngDoCheck() {
    if (!this.organizations || this.organizations.length === 0) {
      return;
    }

    let organizationId = this.organizationService.getActiveOrganizationId();
    if (!organizationId || (this.activeOrganizationId && (organizationId === this.activeOrganizationId.toString())) || isNaN(Number(organizationId)) || Number(organizationId) <= 0) {
      return;
    }

    this.setActiveOrganization(organizationId);
  }
  
  async setActiveOrganization(organizationId: string) {
    if (!organizationId) {
      this.activeOrganizationId = null;
      return;
    }

    this.activeOrganizationId = this.organizations.find(o => o.organizationId.toString() === organizationId).organizationId ?? null;
    await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    this.updateTeams(null);
  }

  updateTeams(events: any) {
    if (!this.activeOrganizationId || !this.child) {
      return;
    }

    this.child.searchTeams();
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
