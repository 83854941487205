import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectType } from '../../_models/projectType';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableProjectResult } from 'src/app/_models/expandableProjectResult';
import { OrganizationService } from 'src/app/_services/organization.service';
import { Organization } from 'src/app/_models/organization';
import { ExpandableOrganizationResult } from 'src/app/_models/expandableOrganizationResult';
import { UserEntityOptions } from 'src/app/_enums/user-entity-options';
import { UserToShow } from 'src/app/_models/userToShow';
import { resolve } from 'dns';
import { Role } from 'src/app/_models/role';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-organization-edit',
  templateUrl: './organization-edit.component.html',
  styleUrls: ['./organization-edit.component.css']
})
export class OrganizationEditComponent implements OnInit {
  userOrganizations = Array<Organization>();
  minDate;
  maxDate;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedStates: Array<ExpandableOrganizationResult> = new Array<ExpandableOrganizationResult>();
  isAllExpanded: boolean = false;
  entityName: UserEntityOptions = UserEntityOptions.Organization;
  isInitialized: boolean = false;
  organizationUsers = new Map<number, Array<UserToShow>>();
  organizationRoles = new Map<number, Array<Role>>();
  relationTable: string = 'organizations';

  constructor(private authService: AuthService, private alertify: AlertifyService, private roleService: RoleService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService) { }

  async ngOnInit() {
    // this.spinner.show();

    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    // await this.searchOrganizations();
    // await this.findOrganizationUsers();
    // await this.findOrganizationRoles();
    // this.spinner.hide();
    await this.refresh();
  }

  async changePage(pagination: PaginParameters) {
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    await this.refresh();
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async refresh() {
    this.spinner.show();
    await this.searchOrganizations();
    await this.findOrganizationUsers();
    await this.findOrganizationRoles();
    this.spinner.hide();
  }

  async searchOrganizations() {
    this.expandedStates = new Array<ExpandableOrganizationResult>();

    await new Promise((resolve, reject) => this.organizationService.getOrganizations(this.perPage, this.pageNumber)
    .subscribe(
      res => {
        this.userOrganizations = res.results;
        this.userOrganizations.forEach(organization => {
          this.expandedStates.push(
            {
              organization,
              isActive: this.isAllExpanded
            }
            );
          });
          this.resultCount = res.count[0]['totalCount'];

          resolve(true);
        }, err => {
          this.alertify.error('Failed to load organizations');
          reject(new Error('failed'));
        }
      ));
  }

  async findOrganizationUsers() {
    this.organizationUsers = new Map<number, Array<UserToShow>>();

    for (let organization of this.userOrganizations) {
      await this.getOrganizationUsers(organization.organizationId);
   }
  }

  async getOrganizationUsers(organizationId: number) {
    if (!organizationId){
      return;
    }

    await new Promise((resolve, reject) => this.organizationService.getOrganizationUsers(organizationId)
      .subscribe(
        res => {
          this.organizationUsers.set(organizationId, res);
          resolve(true);
        }, err => {
          this.alertify.error('Something went wrong');
          reject(new Error('failed'));
        }
      )
    );
  }

  async getOrganizationUserRoles(organizationId: number, userId: number) {
    if (!organizationId){
      return;
    }

    await new Promise((resolve, reject) => this.organizationService.getOrganizationUsers(organizationId)
      .subscribe(
        res => {
          this.organizationUsers.set(organizationId, res);
          resolve(true);
        }, err => {
          this.alertify.error('Something went wrong');
          reject(new Error('failed'));
        }
      )
    );
  }

  getOrganizationUser(organizationId: number) {
    return this.organizationUsers.get(organizationId);
  }

  isOwner(organizationId: number): boolean {
    const organization = this.userOrganizations.find(uo => uo.organizationId === organizationId);
    if (!organization) {
      return false;
    }

    return organization.organizationOwner === this.authService.currentUser.id;
  }

  async findOrganizationRoles() {
    this.organizationRoles = new Map<number, Array<Role>>();

    for (let organization of this.userOrganizations) {
      await this.getOrganizationRoles(organization.organizationId);
   }
  }

  async getOrganizationRoles(organizationId: number) {
    if (!organizationId){
      return;
    }

    await new Promise((resolve, reject) => this.roleService.getRoles(organizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.organizationRoles.set(organizationId, res.results);
          resolve(true);
        }, err => {
          this.alertify.error('Something went wrong');
          reject(new Error('failed'));
        }
      )
    );
  }

  getOrganizationRole(organizationId: number) {
    return this.organizationRoles.get(organizationId);
  }

  async saveOrganizations() {
    let editedOrganizationData = [];

    for (let i = 0; i < this.userOrganizations.length; i++) {
      const organizationFormatedData = {
        organizationId: this.userOrganizations[i].organizationId,
        ownerId: this.authService.decodedToken.id,
        organizationName: this.userOrganizations[i].organizationName,
        logo: null,
        defaultRole: this.userOrganizations[i].defaultRole,
        description: this.userOrganizations[i].organizationDescription
      };

      editedOrganizationData.push(organizationFormatedData);
    }

    const organizationsData = {
      organizationsData: editedOrganizationData
    };

    await new Promise((resolve, reject) => this.organizationService.editOrganizations(organizationsData)
      .subscribe(
        res => {
          this.alertify.success('Saved organization changes');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save organization changes');
        reject(new Error('failed'));
      }
    ));
  }

  async deleteOrganization(organizationId: number) {
    if (!organizationId){
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this organization', () => {
      this.organizationService.deleteOrganization(organizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted organization');
          this.searchOrganizations();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete organization');
            }
          }
          reject(new Error('failed'));
        }
      );
    }));
  }

  async saveUser(event: any) {
    const organizationUserData = {
      organizationId: event.entityId,
      username: event.username
    };
    this.organizationService.saveOrganizationUser(organizationUserData).subscribe(
      res => {
        const newUser: UserToShow = {
          userId: res.userId,
          username: event.username,
          firstname: res.firstname,
          lastname: res.lastname
        }
        let users = this.organizationUsers.get(event.entityId);
        users.push(newUser);
        this.organizationUsers.set(event.entityId, users);
        this.alertify.success('Added user to organization');
      }, err => {
        this.alertify.error('Failed to save changes');      
      }
    );
  }

  async deleteUser(event: any) {
    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to remove this user from the organization?', () => {
      this.organizationService.deleteOrganizationUser(event.entityId, event.userId)
      .subscribe(
        res => {
          let users = this.organizationUsers.get(event.entityId);
          let userToRemove = users.find(u => u.userId === event.userId);
          let index = users.indexOf(userToRemove);
          users.splice(index, 1);
          this.organizationUsers.set(event.entityId, users);
          this.alertify.success('Succesfully removed user from the organization');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to remove user from organization');
            }
          }
          reject(new Error('failed'));
        }
      );
    }));
  }

  getLockedUsersForOrganization(organizationId: number) {
    const organizationOwnerId: number = this.userOrganizations.find(uo => uo.organizationId === organizationId).organizationOwner;
    const organizationUsersList = this.organizationUsers.get(organizationId);
    let organizationOwner: Array<UserToShow> = new Array<UserToShow>();
    organizationOwner.push(organizationUsersList.find(oul => oul.userId === organizationOwnerId));
    return organizationOwner;
  }

  async updateUserRole(event: any) {
    if (!event.organizationId || !event.roleId || !event.userId) {
      return;
    }

    await new Promise((resolve, reject) => this.roleService.updateUserRole(event.organizationId, event.roleId, event.userId, this.relationTable)
      .subscribe(
        res => {
          this.alertify.success('Set user role');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to set user role');
            }
          }
          reject(new Error('failed'));
        }
      )
    );
  }

  setRole(role: Role, organizationId: number) {
    let organization = this.userOrganizations.find(o => o.organizationId === organizationId);
    organization.defaultRole = role.id;
  }

  getRoles(organizationId: number): Array<Role> {
    return this.organizationRoles.get(organizationId);
  }

  getRoleName(organizationId: number, roleId: number) {
    const roles = this.getRoles(organizationId);
    const organization = this.userOrganizations.find(o => o.organizationId === organizationId);
    return roles.find(r => r.id === organization.defaultRole).roleName;
  }

  expandRow(row: ExpandableOrganizationResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

}
