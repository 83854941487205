<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new priority</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="priorityCreateForm" class="form">
            <table style="width: 100%;">
                <tr>
                    <td>
                        <label for="">*Priority name</label>
                        <input 
                          [ngClass]="{'is-invalid': priorityCreateForm.get('priorityName').errors && priorityCreateForm.get('priorityName').touched}"
                          formControlName="priorityName"
                          name="priorityName"
                          type="text"
                          class="form-control">
                        <div class="invalid-feedback worktime-date-field">Priority name is required</div>
                    </td>
                </tr>
                <tr class="bottom-row">
                    <td>
                      <button [disabled]="!priorityCreateForm.valid" name="savePriority" (click)="savePriority()" type="button" class="btn btn-success float-left">Save</button>
                    </td>
                  </tr>
            </table>
          </form>

        </div>
      </div>
    </div>
  </div>

