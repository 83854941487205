<div class="row pt-5">
    <div class="col-md-12 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Cut worktimes</h3>
        </div>

        <form [formGroup]="cutWorktimeForm" class="form">
          <div class="card-body">
            <table>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="start-date">
                    <label for="">*Start Date</label>
                    <input formControlName="originalStartdate"
                      name="originalStartdate"
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('originalStartdate').errors && cutWorktimeForm.get('originalStartdate').touched}"
                      class="form-control worktime-time-field worktime-date-field-input"
                      placeholder="Start date"
                      type="text"
                      bsDatepicker
                      [bsConfig]="bsConfig">
                      <div class="invalid-feedback worktime-date-field">Start date is required</div>
                  </div>
                </td>
                <td class="half-row">
                  <div class="form-group">
                    <label for="">*Start Time</label>
                    <timepicker 
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('originalStarttime').errors && cutWorktimeForm.get('originalStarttime').touched}"
                      formControlName="originalStarttime"
                      name="originalStarttime"
                      [showMeridian]="ismeridian">
                    </timepicker>
                    <div class="invalid-feedback">Start time is required</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="end-date">
                    <label for="">*End Date</label>
                    <input formControlName="originalEnddate"
                      name="originalEnddate"
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('originalEnddate').errors && cutWorktimeForm.get('originalEnddate').touched}"
                      class="form-control worktime-time-field worktime-date-field-input" 
                      placeholder="End date" 
                      type="text" 
                      bsDatepicker 
                      [bsConfig]="bsConfig">
                      <div class="invalid-feedback worktime-date-field">End date is required</div>
                  </div>
                </td>
                <td class="half-row">
                  <label for="">*End Time</label>
                  <div class="worktime-time-field">
                    <timepicker timepicker 
                      formControlName="originalEndtime" 
                      name="originalEndtime" 
                      [showMeridian]="ismeridian">
                    </timepicker>
                    <div class="invalid-feedback">End time is required</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                    <label for="">*Project</label>
                    <div *ngIf="projects && projects.length > 0" class="btn-group" style="width: 100%;" dropdown>
                      <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Projects <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li *ngFor="let project of projects" role="menuitem" (click)="setProject(project.projectId, 'originalProject')">{{project.projectName}}</li>
                      </ul>
                    </div>
                    <p *ngIf="originalActiveProject" type="text" class="form-control">
                      {{originalActiveProject.projectName}}
                    </p>

                  <div class="alert" *ngIf="projects.length == 0">Create projects in project management before adding worktime entries!</div>
                </td>
                <td>
                  <label for="">Task</label>
                  <div *ngIf="projectTasks && projectTasks.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button id="original-task-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Tasks <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem" (click)="setTask(0, 'originalTask')">No task</li>
                    <li *ngFor="let task of projectTasks" role="menuitem" (click)="setTask(task.taskId, 'originalTask')">{{task.taskName}}</li>
                    </ul>
                  </div>
                  <p *ngIf="originalActiveTask" type="text" class="form-control">
                    {{originalActiveTask.taskName}}
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Comment</label>
                  <input formControlName="originalComment" name="originalComment" type="text" class="form-control">
                </td>
              </tr>
              <tr>
                <td class="bottom-row" colspan="2"></td>
              </tr>
            </table>

          </div>

          <div class="card-body">
            <table>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="start-date">
                    <label for="">*Start Date</label>
                    <input formControlName="editedStartdate"
                      name="editedStartdate"
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('editedStartdate').errors && cutWorktimeForm.get('editedStartdate').touched}"
                      class="form-control worktime-time-field worktime-date-field-input"
                      placeholder="Start date"
                      type="text"
                      bsDatepicker
                      [bsConfig]="bsConfig">
                      <div class="invalid-feedback worktime-date-field">Start date is required</div>
                  </div>
                </td>
                <td class="half-row">
                  <div class="form-group">
                    <label for="">*Start Time</label>
                    <timepicker 
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('editedStarttime').errors && cutWorktimeForm.get('editedStarttime').touched}"
                      formControlName="editedStarttime"
                      name="editedStarttime"
                      [showMeridian]="ismeridian">
                    </timepicker>
                    <div class="invalid-feedback">Start time is required</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="end-date">
                    <label for="">*End Date</label>
                    <input formControlName="editedEnddate"
                      name="editedEnddate"
                      [ngClass]="{'is-invalid': cutWorktimeForm.get('editedEnddate').errors && cutWorktimeForm.get('editedEnddate').touched}"
                      class="form-control worktime-time-field worktime-date-field-input" 
                      placeholder="End date" 
                      type="text" 
                      bsDatepicker 
                      [bsConfig]="bsConfig">
                      <div class="invalid-feedback worktime-date-field">End date is required</div>
                  </div>
                </td>
                <td class="half-row">
                  <label for="">*End Time</label>
                  <div class="worktime-time-field">
                    <timepicker timepicker 
                      formControlName="editedEndtime" 
                      name="editedEndtime" 
                      [showMeridian]="ismeridian">
                    </timepicker>
                    <div class="invalid-feedback">End time is required</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                    <label for="">*Project</label>
                    <div *ngIf="projects && projects.length > 0" class="btn-group" style="width: 100%;" dropdown>
                      <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Projects <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li *ngFor="let project of projects" role="menuitem" (click)="setProject(project.projectId, 'editedProject')">{{project.projectName}}</li>
                      </ul>
                    </div>
                    <p *ngIf="editedActiveProject" type="text" class="form-control">
                      {{editedActiveProject.projectName}}
                    </p>

                  <div class="alert" *ngIf="projects.length == 0">Create projects in project management before adding worktime entries!</div>
                </td>
                <td>
                  <label for="">Task</label>
                  <div *ngIf="projectTasks && projectTasks.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button id="edited-task-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Tasks <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem" (click)="setTask(0, 'editedTask')">No task</li>
                    <li *ngFor="let task of projectTasks" role="menuitem" (click)="setTask(task.taskId, 'editedTask')">{{task.taskName}}</li>
                  </ul>
                  </div>
                  <p *ngIf="editedActiveTask" type="text" class="form-control">
                    {{editedActiveTask.taskName}}
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Comment</label>
                  <input formControlName="editedComment" name="editedComment" type="text" class="form-control">
                </td>
              </tr>
              <tr>
                <td class="bottom-row" colspan="2"></td>
              </tr>
            </table>
            <button [disabled]="!cutWorktimeForm.valid" (click)="save()" type="button" class="btn btn-info float-left">Cut</button>
          </div>
        </form>
      </div>
    </div>
  </div>

