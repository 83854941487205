import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { WorktimeService } from '../../_services/worktime.service';
import * as moment from 'moment';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { Project } from '../../_models/project';
import { Task } from '../../_models/task';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { PagePermission } from 'src/app/_models/pagePermission';

@Component({
  selector: 'app-worktime-create',
  templateUrl: './worktime-create.component.html',
  styleUrls: ['./worktime-create.component.css']
})
export class WorktimeCreateComponent implements OnInit {
  worktimeCreateData = {userId: '', projectId: '', taskId: '', startDate: '', startTime: '', endDate: '', endTime: '', comment: ''};
  worktimeFormatedData = {userId: '', projectId: '', taskId: '', startDate: '', endDate: '', comment: ''};
  localFormat = 'YYYY-MM-DD HH:mm:ss';
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  worktimeCreateForm: UntypedFormGroup;
  userProjects = [];
  projectTasks = [];
  activeProject: Project;
  activeTask: Task;
  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  counter = 0;
  maxRetryAttemps = 30;

  constructor(private work: WorktimeService, private router: Router, private fb: UntypedFormBuilder, private activeOrganizationService: ActiveOrganizationService,
              private authService: AuthService, private alertify: AlertifyService, private projects: ProjectService) { }

  ngOnInit(): void {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.activeOrganizationService.getOrganizations();

    this.generatePagePermissions();
    this.createWorktimeForm();
    this.initializeStartDate();
    this.counter = 0;
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.view'
    };
    let pagePermission5: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.edit'
    };
    let pagePermission6: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.create'
    };
    let pagePermission7: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
    this.pagePermissions.push(pagePermission5);
    this.pagePermissions.push(pagePermission6);
    this.pagePermissions.push(pagePermission7);
  }

  async checkActiveOrganization() {
    if (this.counter >= this.maxRetryAttemps) {
      return;
    }
    this.counter++;

    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.counter = 0;
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
      await this.getUserProjects();
    }
  }

  createWorktimeForm() {
    this.worktimeCreateForm = this.fb.group({
      projectname: ['', Validators.required],
      taskname: ['', ''],
      startdate: ['', Validators.required],
      starttime: ['', Validators.required],
      enddate: ['', ''],
      endtime: ['', ''],
      comment: ['', '']
    });
  }

  toggleMode(): void {
    this.ismeridian = !this.ismeridian;
  }

  initializeStartDate() {
    this.worktimeCreateForm.controls['startdate'].setValue(moment().format('YYYY-MM-DD'));
    this.worktimeCreateForm.controls['starttime'].setValue(moment().format('YYYY-MM-DD HH:mm'));
  }

  async getUserProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projects.getUsersProjects(this.activeOrganizationId)
    .subscribe(
      res => {
        this.userProjects = res.results;

        let projectId = Number(localStorage.getItem('currentProject'));
        if (!projectId || projectId === 0) {
          projectId = this.userProjects[0].projectId;
        }

        if (this.userProjects && this.userProjects.length && !this.userProjects.find(p => p.projectId === projectId)){
          projectId = this.userProjects[0].projectId;
          localStorage.setItem('currentProject', projectId.toString());
        }

        if (this.userProjects.find(p => p.projectId === projectId)){
          this.setProject(projectId);
        }
        resolve(true);
      }, err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to load projects');
          }
        }
        reject(new Error('Failed'));
      }
    ));
  }

  async getProjectTasks(projectId) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projects.getProjectTasks(this.activeOrganizationId, projectId)
    .subscribe(
      res => {
        this.projectTasks = res.results;
        resolve(true);
      }, err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to load tasks');
          }
        }
        reject(new Error('Failed'));
      }
    ));
  }

  async setProject(projectId: number) {
    this.projectTasks = [];
    this.activeProject = this.userProjects.find(p => p.projectId === projectId);
    this.worktimeCreateForm.controls['projectname'].setValue(this.activeProject.projectName);

    await this.getProjectTasks(this.activeProject.projectId);
  }

  setTask(taskId: number) {
    if (taskId === 0) {
      this.activeTask = null;
      this.worktimeCreateForm.controls['taskname'].setValue(null);
    }
    else {
      this.activeTask = this.projectTasks.find(t => t.taskId === taskId);
      this.worktimeCreateForm.controls['taskname'].setValue(this.activeTask.taskName);
    }
  }

  async saveWorkTime() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const formatedStartDate = moment(this.worktimeCreateForm.controls['startdate'].value).format('YYYY-MM-DD');
    const formattedStartTime = moment(this.worktimeCreateForm.controls['starttime'].value).format('HH:mm');
    let formatedEndtDate;
    let formattedEndTime;

    if (this.isValidDate(this.worktimeCreateForm.controls['enddate'].value)) {
      formatedEndtDate = moment(this.worktimeCreateForm.controls['enddate'].value).format('YYYY-MM-DD');
      formattedEndTime = moment(this.worktimeCreateForm.controls['endtime'].value).format('HH:mm');
    }
    else {
      formatedEndtDate = '0000-00-00';
      formattedEndTime = '00:00:00';
    }

    const worktimeFormatedData = {
      organizationId: this.activeOrganizationId,
      userId: this.authService.decodedToken.id,
      projectId: this.activeProject.projectId,
      taskId: this.activeTask ? this.activeTask.taskId : 0,
      startTime: moment(formatedStartDate + ' ' + formattedStartTime).format(this.localFormat),
      endTime: moment(formatedEndtDate + ' ' + formattedEndTime).format(this.localFormat),
      comment: this.worktimeCreateForm.controls['comment'].value
    };

    if (!moment(worktimeFormatedData.startTime).isValid()) {
      this.alertify.error('Invalid start time');
      return;
    }
    if (!moment(worktimeFormatedData.startTime).isValid()) {
      this.alertify.error('Invalid end time');
      return;
    }
    if (worktimeFormatedData.startTime >= worktimeFormatedData.endTime) {
      this.alertify.error('Start time has to be greater than end time');
      return;
    }

    if (!this.activeProject) {
      this.alertify.error('No project selected');
      return;
    }

    await new Promise((resolve, reject) => this.work.saveWorktime(worktimeFormatedData)
      .subscribe(
        res => {
          this.alertify.success('Saved worktime');
          this.createWorktimeForm();
          this.activeProject = null;
          this.activeTask = null;
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to save worktime');
            }
          }
          reject(new Error('Failed'));
        }
      ));
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }

  isValidDate(d) {
    const formattedDate = new Date(moment(d).format('YYYY-MM-DD HH:mm'));
    return formattedDate instanceof Date && !isNaN(Number(formattedDate));
  }
}
