<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Add new worktimes</h3>
        </div>

        <div class="card-body" *ngIf="(checkPagePermissions('worktimes.own.view') && checkPagePermissions('worktimes.own.create') 
        || checkPagePermissions('worktimes.others.view') && checkPagePermissions('worktimes.others.create')) 
        && checkPagePermissions('project.view')">
          <form [formGroup]="worktimeCreateForm" class="form">
            <table>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="start-date">
                    <label for="">*Start Date</label>
                    <input formControlName="startdate"
                      name="startdate"
                      [ngClass]="{'is-invalid': worktimeCreateForm.get('startdate').errors && worktimeCreateForm.get('startdate').touched}"
                      class="form-control worktime-time-field worktime-date-field-input"
                      placeholder="Start date"
                      type="text"
                      bsDatepicker
                      [bsConfig]="bsConfig">
                      <div class="invalid-feedback worktime-date-field">Start date is required</div>
                  </div>
                </td>
                <td class="half-row">
                  <div class="form-group">
                    <label for="">*Start Time</label>
                    <timepicker 
                      [ngClass]="{'is-invalid': worktimeCreateForm.get('starttime').errors && worktimeCreateForm.get('starttime').touched}"
                      formControlName="starttime"
                      name="starttime"
                      [showMeridian]="ismeridian">
                    </timepicker>
                    <div class="invalid-feedback">Start time is required</div>
                  </div>
                </td>
              </tr>
              <tr>
                <td class="half-row">
                  <div class="form-group" name="end-date">
                    <label for="">End Date</label>
                    <input formControlName="enddate"
                      name="enddate" 
                      class="form-control worktime-time-field worktime-date-field-input" 
                      placeholder="End date" 
                      type="text" 
                      bsDatepicker 
                      [bsConfig]="bsConfig">
                  </div>
                </td>
                <td class="half-row">
                  <label for="">End Time</label>
                  <div class="worktime-time-field">
                      <timepicker timepicker formControlName="endtime" name="endtime" [showMeridian]="ismeridian"></timepicker>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                    <label for="">*Project</label>
                    <div *ngIf="userProjects.length > 0" class="btn-group" style="width: 100%;" dropdown>
                      <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Projects <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                      <li *ngFor="let project of userProjects" role="menuitem" (click)="setProject(project.projectId)">{{project.projectName}}</li>
                      </ul>
                    </div>
                    <p *ngIf="activeProject" type="text" class="form-control">
                      {{activeProject.projectName}}
                    </p>

                  <div class="alert" *ngIf="userProjects.length == 0">Create projects in project management before adding worktime entries!</div>
                </td>
                <td>
                  <label for="">Task</label>
                  <div *ngIf="projectTasks.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                    aria-controls="dropdown-basic">
                    Tasks <span class="caret"></span>
                  </button>
                  <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                    <li role="menuitem" (click)="setTask(0)">No task</li>
                    <li *ngFor="let task of projectTasks" role="menuitem" (click)="setTask(task.taskId)">{{task.taskName}}</li>
                    </ul>
                  </div>
                  <p *ngIf="activeTask" type="text" class="form-control">
                    {{activeTask.taskName}}
                  </p>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Comment</label>
                  <input formControlName="comment" name="comment" type="text" class="form-control">
                </td>
              </tr>
              <tr></tr>
              <tr class="bottom-row">
                <td colspan="2">
                  <button [disabled]="!worktimeCreateForm.valid" (click)="saveWorkTime()" type="button" class="btn btn-success float-left">Save</button>
                </td>
                <td></td>
              </tr>
            </table>
          </form>

        </div>
      </div>
    </div>
  </div>
