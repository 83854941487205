import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { NgxSpinnerModule } from "ngx-spinner";

import { AppComponent } from './app.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './_services/auth.service';
import { WorktimeCreateComponent } from './worktime/worktime-create/worktime-create.component';
import { WorktimeEditComponent } from './worktime/worktime-edit/worktime-edit.component';
import { AuthGuard } from './auth.guard';
import { TokenInterceptorService } from './_services/token-interceptor.service';
import { WorktimeService } from './_services/worktime.service';
import { WorktimeReportingComponent } from './worktime/worktime-reporting/worktime-reporting.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ProjectEditComponent } from './ProjectManagement/project-edit/project-edit.component';
import { ProjectCreateComponent } from './ProjectManagement/project-create/project-create.component';
import { TaskCreateComponent } from './TaskManagement/task-create/task-create.component';
import { TaskEditComponent } from './TaskManagement/task-edit/task-edit.component';
import { TaskTypesComponent } from './TaskManagement/task-types/task-types.component';
import { TasktypesCreateComponent } from './TaskManagement/tasktypes-create/tasktypes-create.component';
import { TasktypesEditComponent } from './TaskManagement/tasktypes-edit/tasktypes-edit.component';
import { TaskStatesComponent } from './TaskManagement/task-states/task-states.component';
import { TaskstatesCreateComponent } from './TaskManagement/taskstates-create/taskstates-create.component';
import { TaskstatesEditComponent } from './TaskManagement/taskstates-edit/taskstates-edit.component';
import { ProjectTypesComponent } from './ProjectManagement/project-types/project-types.component';
import { ProjecttypesCreateComponent } from './ProjectManagement/projecttypes-create/projecttypes-create.component';
import { ProjecttypesEditComponent } from './ProjectManagement/projecttypes-edit/projecttypes-edit.component';
import { BoardComponent } from './board/board.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TaskEditSingleComponent } from './TaskManagement/task-edit-single/task-edit-single.component';
import { ProjectVersionsComponent } from './ProjectManagement/project-versions/project-versions.component';
import { ProjectVersionsCreateComponent } from './ProjectManagement/project-versions-create/project-versions-create.component';
import { ProjectVersionsEditComponent } from './ProjectManagement/project-versions-edit/project-versions-edit.component';
import { TasksComponent } from './TaskManagement/tasks/tasks.component';
import { ProjectsComponent } from './ProjectManagement/projects/projects.component';
import { PrioritiesComponent } from './ProjectManagement/priorities/priorities.component';
import { PriorityCreateComponent } from './ProjectManagement/priority-create/priority-create.component';
import { PriorityEditComponent } from './ProjectManagement/priority-edit/priority-edit.component';
import { CommentComponent } from './comment/comment.component';
import { AssigneeComponent } from './assignee/assignee.component';
import { SearchComponent } from './search/search.component';
import { PaginationComponent } from './pagination/pagination.component';
import { TagComponent } from './tag/tag.component';
import { WorktimeLoggingComponent } from './worktime-logging/worktime-logging.component';
import { CutWorktimesComponent } from './worktime/cut-worktimes/cut-worktimes.component';
import { NgxEchartsModule } from 'ngx-echarts';
import { OrganizationsComponent } from './Organization/organizations/organizations.component';
import { OrganizationCreateComponent } from './Organization/organization-create/organization-create.component';
import { OrganizationEditComponent } from './Organization/organization-edit/organization-edit.component';
import { TeamCreateComponent } from './Teams/team-create/team-create.component';
import { TeamsComponent } from './Teams/teams/teams.component';
import { TeamEditComponent } from './Teams/team-edit/team-edit.component';
import { OrganizationSelectionComponent } from './Organization/organization-selection/organization-selection.component';
import { OrganizationService } from './_services/organization.service';
import { EntityUsersComponent } from './entity-users/entity-users.component';
import { RolesComponent } from './RoleManagement/roles/roles.component';
import { RoleCreateComponent } from './RoleManagement/role-create/role-create.component';
import { RoleEditComponent } from './RoleManagement/role-edit/role-edit.component';
import { DecimalPipe } from '@angular/common';
import { NgMultiSelectDropDownModule } from "ng-multiselect-dropdown";
import { TodoListsComponent } from './Productivity/todo-lists/todo-lists.component';
import { TodoitemComponent } from './Productivity/todoitem/todoitem.component';
import { TodoCreateComponent } from './Productivity/todo-create/todo-create.component';

@NgModule({
  declarations: [
    AppComponent,
    RegisterComponent,
    LoginComponent,
    WorktimeCreateComponent,
    WorktimeEditComponent,
    WorktimeReportingComponent,
    UserManagementComponent,
    ProjectEditComponent,
    ProjectCreateComponent,
    TaskCreateComponent,
    TaskEditComponent,
    TaskTypesComponent,
    TasktypesCreateComponent,
    TasktypesEditComponent,
    TaskStatesComponent,
    TaskstatesCreateComponent,
    TaskstatesEditComponent,
    ProjectTypesComponent,
    ProjecttypesCreateComponent,
    ProjecttypesEditComponent,
    BoardComponent,
    TaskEditSingleComponent,
    ProjectVersionsComponent,
    ProjectVersionsCreateComponent,
    ProjectVersionsEditComponent,
    TasksComponent,
    ProjectsComponent,
    PrioritiesComponent,
    PriorityCreateComponent,
    PriorityEditComponent,
    CommentComponent,
    AssigneeComponent,
    SearchComponent,
    PaginationComponent,
    TagComponent,
    WorktimeLoggingComponent,
    CutWorktimesComponent,
    OrganizationsComponent,
    OrganizationCreateComponent,
    OrganizationEditComponent,
    TeamCreateComponent,
    TeamsComponent,
    TeamEditComponent,
    OrganizationSelectionComponent,
    EntityUsersComponent,
    RolesComponent,
    RoleCreateComponent,
    RoleEditComponent,
    TodoListsComponent,
    TodoitemComponent,
    TodoCreateComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    PaginationModule.forRoot(),
    BsDatepickerModule.forRoot(),
    TimepickerModule.forRoot(),
    BsDropdownModule.forRoot(),
    ModalModule.forRoot(),
    NgxSpinnerModule,
    NgxEchartsModule.forRoot({
      echarts: () => import('echarts'),
    }),
    NgMultiSelectDropDownModule.forRoot()
  ],
  providers: [AuthService, AuthGuard, WorktimeService, DecimalPipe,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: TokenInterceptorService,
    multi: true
  }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
  
})
export class AppModule { }
