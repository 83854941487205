import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { WorktimeService } from '../../_services/worktime.service';
import * as moment from 'moment';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { TaskTypes } from 'src/app/_models/taskTypes';
import { ProjectVersion } from 'src/app/_models/projectVersion';
import { ProjectService } from 'src/app/_services/project.service';
import { Project } from 'src/app/_models/project';
import { TaskService } from 'src/app/_services/task.service';
import { Priority } from 'src/app/_models/priority';
import { PriorityService } from 'src/app/_services/priority.service';
import { Task } from 'src/app/_models/task';
import { SearchParameters } from 'src/app/_models/searchParameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { EChartsOption } from 'echarts';
import { WorktimesByTaskType } from 'src/app/_models/worktimeByTaskType';
import { __spreadArrays } from 'tslib';
import { WorktimesByProject } from 'src/app/_models/worktimesByProjects';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { DecimalPipe } from '@angular/common';
import { UserWorktimes } from 'src/app/_models/userWorktimes';
import { PagePermission } from 'src/app/_models/pagePermission';
import { WorktimeReportData } from 'src/app/_models/worktimeReportData';

@Component({
  selector: 'app-reporting',
  templateUrl: './worktime-reporting.component.html',
  styleUrls: ['./worktime-reporting.component.css']
})
export class WorktimeReportingComponent implements OnInit {
  @Input() roundRules = '1.0-2';

  worktimeReportData = [];
  totalHoursOnPeriod: number = 0;
  requiredHoursOnPeriod: number = 0;
  averageHoursOnPeriod: number = 0;
  acceptedFlexOnPeriod: number = 0.5;
  expectedWorkHours: number = 0;
  userWorktimes = [];
  userDailyHours = [];
  localFormat = 'YYYY-MM-DDTHH:mm:ss';
  minDate: any;
  maxDate: any;
  bsConfig: Partial<BsDatepickerConfig>;
  projectVersions: ProjectVersion[];
  projectPriorites: Priority[];
  taskTypes: Array<TaskTypes>;
  versionId: number;
  typeId: number;
  projects: Array<Project>;
  projectId: number;
  priorityId: number;
  priorityIds: Array<number>;
  tasks: Array<Task>;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  worktimesByTaskType: Array<WorktimesByTaskType> = new Array<WorktimesByTaskType>();
  worktimesByProjects: Array<WorktimesByProject> = new Array<WorktimesByProject>();
  showTasktypesBarChart: boolean = true;
  showProjectsBarChart: boolean = false;

  chartOption1: EChartsOption = {};
  chartOption2: EChartsOption = {};
  chartOption3: EChartsOption = {};
  chartOption4: EChartsOption = {};

  activeOrganizationId: number = null;
  userId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  months = Array<WorktimeReportData>();
  isGroupingByWeeks: boolean = false;

  constructor(private work: WorktimeService, private router: Router, private projectService: ProjectService,
              private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService,
              private priorityService: PriorityService, private spinner: NgxSpinnerService,
              private activeOrganizationService: ActiveOrganizationService, private decimalPipe: DecimalPipe) { }

  ngOnInit(): void {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.own.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.own.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.own.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.others.view'
    };
    let pagePermission5: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.others.edit'
    };
    let pagePermission6: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.reporting.others.create'
    };
    let pagePermission7: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
    this.pagePermissions.push(pagePermission5);
    this.pagePermissions.push(pagePermission6);
    this.pagePermissions.push(pagePermission7);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    }
  }

  async search(searchParameters: SearchParameters) {
    this.projectId = searchParameters.projectId ?? undefined;
    this.priorityIds = searchParameters.priorityId && searchParameters.priorityId.length ? searchParameters.priorityId : null;
    this.versionId = searchParameters.versionId ?? undefined;
    this.typeId = searchParameters.typeId ?? undefined;
    this.minDate = searchParameters.minDate ?? undefined;
    this.maxDate = searchParameters.maxDate ?? undefined;
    this.userId = searchParameters.userId ?? undefined;
    await this.searchWorktimes();
  }

  async searchWorktimes() {
    this.spinner.show();

    const formatedMinDate = moment(this.minDate).format('YYYY-MM-DD HH:MM:SS');
    const formatedMaxDate = moment(this.maxDate).format('YYYY-MM-DD HH:MM:SS');

    if (formatedMinDate && !moment(formatedMinDate).isValid()) {
      this.alertify.error('Invalid date');
      return;
    }
    if (formatedMaxDate && !moment(formatedMaxDate).isValid()) {
      this.alertify.error('Invalid date');
      return;
    }
    if (formatedMinDate >= formatedMaxDate) {
      this.alertify.error('mindate must be earlier than maxdate');
      return;
    }

    await this.getUserWorktimes();
  }

  async searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projects = Array<Project>();

    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projects = res.results;
          this.projects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
            }
          });

          this.projectId = Number(localStorage.getItem('currentProject'));
          if (!this.projects.find(p => p.projectId === this.projectId)){
            this.projectId = this.projects[0].projectId;
            localStorage.setItem('currentProject', this.projectId.toString());
          }

          this.getProjectTasks();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('failed'));
      }
    ));
  }

  async getProjectTasks() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.tasks = new Array<Task>();
    await new Promise((resolve, reject) => this.taskService.getProjectTasks(this.activeOrganizationId, this.projectId, null, null)
      .subscribe(
        res => {
          if (res.results.length > 0) {
            this.tasks = res.results;
          }
          else {
            this.tasks = new Array<Task>();
          }

          this.getProjectVersions();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project tasks');
          reject(new Error('Failed'));
      }
    ));
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectVersions = new Array<ProjectVersion>();
    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectVersions = res;
          }
          else {
            this.projectVersions = new Array<ProjectVersion>();
          }

          this.getProjectPriorities();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'));
      }
    ));
  }

  async getProjectPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectPriorites = new Array<Priority>();
    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectPriorites = res;
          }
          else {
            this.projectPriorites = new Array<Priority>();
          }

          this.searchTaskTypes(this.projectId);
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          reject(new Error('Failed'));
      }
    ));
  }

  searchTaskTypes(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.taskTypes = Array<TaskTypes>();

    this.taskService.getTaskTypes(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskTypes = res;
        }, err => {
          this.alertify.error('Failed to save changes');
      }
    );
  }

  async getUserWorktimes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.worktimeReportData = [];
    this.totalHoursOnPeriod = 0;
    this.requiredHoursOnPeriod = 0;
    this.averageHoursOnPeriod = 0;

    this.userWorktimes = [];
    this.worktimesByTaskType = [];
    this.worktimesByProjects = [];

    const searchParams = {
      ownerId: this.authService.decodedToken.id ?? null,
      organizationId: this.activeOrganizationId ?? null,
      minDate: moment(this.minDate).format(this.localFormat) ?? null,
      maxDate: moment(this.maxDate).format(this.localFormat) ?? null,
      projectId: this.projectId ?? null,
      taskType: this.typeId ?? null,
      versionId: this.versionId ?? null,
      priorityIds: this.priorityIds ?? null,
      userId: this.userId ?? null
    };

    await new Promise((resolve, reject) => this.work.getWorktimesReport(searchParams)
      .subscribe(
        res => {
          this.months = Array<WorktimeReportData>();
          this.userWorktimes = res.report;
          this.worktimesByTaskType = res.reportByTaskType;
          this.worktimesByProjects = res.reportByProject;
          this.userDailyHours = res.userDailyHours;
          for (let i = 0; i < this.userWorktimes.length; i++) {
            // let dailyHours = 0;

            let dailyHours = Number(this.userDailyHours.find(t => t.workerUserId === this.userWorktimes[i].workerUserId).dailyHours);
            // for (let j = 0; j < this.userDailyHours.length; j++) {
            //   console.log('test: ', this.userDailyHours[j].workerUserId, ' userWorktimes: ', this.userWorktimes[i].workerUserId);
            //   if (this.userDailyHours[j].workerUserId === this.userWorktimes[i].workerUserId) {
            //     dailyHours = Number(this.userDailyHours.find(t => t.workerUserId === this.userWorktimes[i].workerUserId).dailyHours);
            //     console.log('dailyHours: ', dailyHours);
            //   }
            // }
            
            const worktime = {
              id: this.userWorktimes[i].id,
              totalWorkHours: this.userWorktimes[i].totalWorkHours,
              workday: this.userWorktimes[i].workday,
              dailyHours: dailyHours,
              missingHours: this.userWorktimes[i].totalWorkHours - dailyHours,
              username: this.userWorktimes[i].username,
              year: this.userWorktimes[i].year,
              month: this.userWorktimes[i].month,
              week: this.userWorktimes[i].week,
              dayOfWeek: this.userWorktimes[i].dayOfWeek
            };

            
            worktime.totalWorkHours = this.convertTimeToDecimal(worktime.totalWorkHours);
            if (worktime != null) {
              this.totalHoursOnPeriod += Number(worktime.totalWorkHours);
              this.requiredHoursOnPeriod += worktime.dailyHours;
            }
            worktime.missingHours = Number(worktime.totalWorkHours) - dailyHours;
            this.getWorkMonths(worktime.year, worktime.month, worktime.week, worktime.totalWorkHours);

            this.worktimeReportData.push(worktime);
          }

          if (this.userWorktimes.length > 0) {
            this.averageHoursOnPeriod = Number(this.decimalPipe.transform(this.totalHoursOnPeriod / this.userWorktimes.length) ?? 0);
            this.expectedWorkHours = Number(this.decimalPipe.transform(this.worktimeReportData[0].dailyHours) ?? 0);
          }

          JSON.stringify(res);
          this.worktimeReportData.sort((a, b) => {
            a = moment(a.workday).format(this.localFormat);
            b = moment(b.workday).format(this.localFormat);
            return a > b ? -1 : a < b ? 1 : 0;
          });
          this.createWorktimeByTaskTypeBarChart();
          this.createWorktimeByProjectPieChart();
          this.spinner.hide();
          resolve(true);
        }, err => {
           console.log(err);
           this.spinner.hide();
           reject(new Error('failed'));
        }
      ));
  }

  getWorkMonths(year, month, week, hours) {
    const yearMonth = year + '-' + month;
    const existingMonth = this.months.find(ym => ym.month === yearMonth);
    if (!existingMonth) {
      let weeks: any[] = [];
      weeks.push({week, weeklySummary: Number(hours)});
      let newMonth: WorktimeReportData = {month: yearMonth, monthlySummary: Number(hours), weeks};
      this.months.push(newMonth);
    }
    else {
      existingMonth.monthlySummary += Number(hours);
      const existingWeek = existingMonth.weeks.find(w => w.week === week);
      if (!existingWeek) {
        existingMonth.weeks.push({week, weeklySummary: Number(hours)});
      }
      else
      {
        existingWeek.weeklySummary += Number(hours);
      }
    }
  }

  checkYearMonth(yearMonth: string, week: number, year: string, month: string, weekNumber: number): boolean {
    const isMatchingYearMonth = yearMonth === year + '-' + month && week === weekNumber;
    if (isMatchingYearMonth) {
      return true;
    }
    else {
      return false;
    }
  }

  convertTimeToDecimal(timeToConvert) {
    let timeArray = timeToConvert.split(".");
    let decimal = (parseInt(timeArray[1])/60);
    let decimalArray = decimal.toString().split('.');
    const endResult = parseInt(timeArray[0]) + '.' + (decimalArray[1] ? decimalArray[1] : '00');
    return endResult;
  }

  createWorktimeByTaskTypeBarChart() {
    this.chartOption1 = null;
    let dataX = [];
    let dataY = [];

    if (!this.worktimesByTaskType || this.worktimesByTaskType.length === 0) {
      return;
    }

    this.worktimesByTaskType.forEach(worktime => {
      dataX.push(worktime.typeName);
      dataY.push(worktime.totalWorkHours);
    });

    this.chartOption1 = {
      title: {
        text: 'Worktimes by task types'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      xAxis: {
        type: 'category',
        data: dataX,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: dataY,
          type: 'bar',
        },
      ],
    };
  }

  createWorktimeByTaskTypePieChart() {
    this.chartOption1 = null;
    let data = [];

    if (!this.worktimesByTaskType) {
      return;
    }

    this.worktimesByTaskType.forEach(worktime => {
      data.push({
        value: worktime.totalWorkHours,
        name: worktime.typeName
      });
    });

    this.chartOption1 = {
      title: {
        text: 'Worktimes by task types'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      series: [
        {
          name: 'area',
          type: 'pie',
          data: data
        }
      ]
    };
  }

  createWorktimeByProjecteBarChart() {
    this.chartOption2 = null;
    let dataX = [];
    let dataY = [];

    if (!this.worktimesByProjects) {
      return;
    }

    this.worktimesByProjects.forEach(worktime => {
      dataX.push(worktime.projectName);
      dataY.push(worktime.totalWorkHours);
    });

    this.chartOption2 = {
      title: {
        text: 'Worktimes by projects'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      xAxis: {
        type: 'category',
        data: dataX,
      },
      yAxis: {
        type: 'value',
      },
      series: [
        {
          data: dataY,
          type: 'bar',
        },
      ],
    };
  }

  createWorktimeByProjectPieChart() {
    this.chartOption2 = null;
    let data = [];

    if (!this.worktimesByProjects) {
      return;
    }

    this.worktimesByProjects.forEach(worktime => {
      data.push({
        value: worktime.totalWorkHours,
        name: worktime.projectName
      });
    });

    this.chartOption2 = {
      title: {
        text: 'Worktimes by projects'
      },
      tooltip: {
        trigger: 'item',
        formatter: '{a} <br/>{b} : {c} ({d}%)'
      },
      series: [
        {
          name: 'area',
          type: 'pie',
          data: data
        }
      ]
    };
  }

  setProject(projectId: number) {
    if (this.projects.find(p => p.projectId === projectId || projectId === 0)) {
      this.projectId = projectId > 0 ? projectId : null;
      this.versionId = null;
      this.typeId = null;
      localStorage.setItem('currentProject', this.projectId?.toString());
      this.getProjectVersions();
    }
  }

  setVersion(versionId: number) {
    if (this.projectVersions.find(pv => pv.versionId === versionId) || versionId === 0){
      this.versionId = versionId > 0 ? versionId : null;
      localStorage.setItem('currentVersion', versionId?.toString());
    }
  }

  setPriority(priorityId: number) {
    if (this.projectPriorites.find(pp => pp.priorityId === priorityId) || priorityId === 0){
      this.priorityId = priorityId > 0 ? priorityId : null;
      //localStorage.setItem('currentVersion', priorityId?.toString());
    }
  }

  setType(typeId: number) {
    if (this.taskTypes.find(tt => tt.taskTypeId === typeId) || typeId === 0){
      this.typeId = typeId > 0 ? typeId : null;
      localStorage.setItem('currentType', typeId?.toString());
    }
  }

  getProjectName(): string {
    if (this.projectId === 0) {
      return;
    }

    return this.projects.find(p => p.projectId === this.projectId)?.projectName;
  }

  getVersionName(): string {
    if (this.versionId === 0 && this.projectVersions.length > 0) {
      return;
    }

    return this.projectVersions.find(pv => pv.versionId === this.versionId)?.versionName;
  }

  getTypeName(): string {
    if (this.typeId === 0 && this.taskTypes.length > 0) {
      return;
    }

    return this.taskTypes.find(tt => tt.taskTypeId === this.typeId)?.typeName;
  }

  getPriorityName(): string {
    if (this.priorityId === 0 && this.projectPriorites.length > 0) {
      return;
    }

    return this.projectPriorites.find(pp => pp.priorityId === this.priorityId)?.priorityName;
  }

  changeTaskTypeChart() {
    this.showTasktypesBarChart = !this.showTasktypesBarChart;
    
    this.showTasktypesBarChart ? this.createWorktimeByTaskTypeBarChart() : this.createWorktimeByTaskTypePieChart();
  }

  changeProjectChart() {
    this.showProjectsBarChart = !this.showProjectsBarChart;

    this.showProjectsBarChart ? this.createWorktimeByProjecteBarChart() : this.createWorktimeByProjectPieChart();
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
