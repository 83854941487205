import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { IDropdownSettings } from 'ng-multiselect-dropdown';

import { Priority } from '../_models/priority';
import { Project } from '../_models/project';
import { ProjectVersion } from '../_models/projectVersion';
import { Task } from '../_models/task';
import { TaskTypes } from '../_models/taskTypes';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { PriorityService } from '../_services/priority.service';
import { ProjectService } from '../_services/project.service';
import { TaskService } from '../_services/task.service';
import { WorktimeService } from '../_services/worktime.service';
import * as moment from 'moment';
import { SearchParameters } from '../_models/searchParameters';
import { TagService } from '../_services/tag.service';
import { Tag } from '../_models/tag';
import { ActiveOrganizationService } from '../_services/active-organization.service';
import { UserToShow } from '../_models/userToShow';
import { Team } from '../_models/team';
import { TeamService } from '../_services/team.service';
import { PrioritySearch } from '../_models/searchData';
import { SearchDropdownOptions } from '../_enums/search-dropdown-options';
import { TagSearch } from '../_models/tagSearch';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.css']
})
export class SearchComponent implements OnInit {
  @Input() pageHeader: string;
  @Input() hasSelectAllProjects: boolean = true;
  @Input() hasSelectAll: boolean = false;
  @Input() hasDates: boolean = true;
  @Input() hasOnlyProject: boolean = false;
  @Input() searchTags: boolean = false;
  @Input() searchUsers: boolean = false;
  @Input() searchTeams: boolean = false;
  @Input() isWideView: boolean = false;
  @Output() searchParametersChanged: EventEmitter<any> = new EventEmitter();
  localFormat = 'YYYY-MM-DDTHH:mm:ss';
  minDate: any;
  maxDate: any;
  bsConfig: Partial<BsDatepickerConfig>;
  projectVersions: ProjectVersion[];
  projectPriorites: Priority[];
  projectPriorityIds: Array<PrioritySearch> = new Array<PrioritySearch>();
  projectUsers: Array<UserToShow>;
  projectTeams: Array<Team>;
  taskTypes: Array<TaskTypes>;
  projectTags: Array<Tag>;
  tags: Array<Tag>;
  versionId: number;
  typeId: number;
  projects: Array<Project>;
  projectId: number;
  priorityId: number;
  priorities: Array<Priority> = new Array<Priority>();
  priorityIds: Array<number> = new Array<number>();
  tagName: string;
  tagEntityId: number;
  selectedTags: Array<TagSearch> = new Array<TagSearch>();
  tasks: Array<Task>;
  activeOrganizationId: number = null;
  userId: number = null;
  username: string = null;
  teamId: number = null;
  teamname: string = null;
  maxItemCount: number = 10;
  isInitialized: boolean = false;
  priorityDropdownSettings: IDropdownSettings = {};
  tagDropdownSettings: IDropdownSettings = {};
  
  constructor(private work: WorktimeService, private router: Router, private projectService: ProjectService, private teamService: TeamService,
              private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService,
              private priorityService: PriorityService, private tagService: TagService, private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.priorityDropdownSettings = {
      singleSelection: false,
      idField: 'priorityId',
      textField: 'priorityName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data to show',
      noFilteredDataAvailablePlaceholderText: 'No matching items'
    };

    this.tagDropdownSettings = {
      singleSelection: false,
      idField: 'tagId',
      textField: 'tagName',
      selectAllText: 'Select All',
      unSelectAllText: 'Unselect All',
      itemsShowLimit: 3,
      allowSearchFilter: true,
      noDataAvailablePlaceholderText: 'No data to show',
      noFilteredDataAvailablePlaceholderText: 'No matching items'
    };

    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.activeOrganizationService.getOrganizations();
    await this.refreshResources();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      this.isInitialized = false;
      this.search();
    }
  }

  async search() {
    await this.refreshResources();
    let searchParameters: SearchParameters = {
      projectId: this.projectId,
      versionId: this.versionId,
      typeId: this.typeId,
      priorityId: this.priorityIds,
      tagsToSearch: this.selectedTags,
      userId: this.userId,
      username: this.username,
      minDate: moment(this.minDate).format(this.localFormat),
      maxDate: moment(this.maxDate).format(this.localFormat)
    };
    this.searchParametersChanged.emit(searchParameters);
  }

  async refreshResources() {
    await this.searchProjects();

    if (!this.projectId) {
      return;
    }

    try {
      await this.getProjectTasks();
      await this.getProjectVersions();
      await this.searchTaskTypes();
      await this.getProjectPriorities();
      await this.searchProjectTags();
      await this.searchProjectUsers();
      await this.searchProjectTeams();
    } catch (error) {
      console.log('error: ', error);
    }
  }

  async searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.projects = Array<Project>();

    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projects = res.results;
          this.projects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
            }
          });

          if (this.projectId === undefined && !this.isInitialized) {
            this.projectId = Number(localStorage.getItem('currentProject'));
          }
          if (!this.isInitialized && !this.projects.find(p => p.projectId === this.projectId)){
            this.isInitialized = true;
            this.projectId = this.projects[0].projectId;
            localStorage.setItem('currentProject', this.projectId.toString());
          }

          resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('failed'));
      }
    ));
  }

  async getProjectTasks() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.tasks = new Array<Task>();
    await new Promise((resolve, reject) => this.taskService.getProjectTasks(this.activeOrganizationId, this.projectId, null, null)
      .subscribe(
        res => {
          if (res.results.length > 0) {
            this.tasks = res.results;
          }
          else {
            this.tasks = new Array<Task>();
          }

          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project tasks');
          reject(new Error('failed'));
      }
    ));
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectVersions = new Array<ProjectVersion>();
    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectVersions = res;
          }
          else {
            this.projectVersions = new Array<ProjectVersion>();
          }

          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project versions');
          reject(new Error('failed'));
      }
    ));
  }

  async getProjectPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectPriorites = new Array<Priority>();
    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            for (const r of res) {
              const priorityParameter: PrioritySearch = {priority: r, isChecked: false};
              this.projectPriorityIds.push(priorityParameter);
            }
            this.projectPriorites = res;
          }
          else {
            this.projectPriorites = new Array<Priority>();
          }

          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project priorities');
          reject(new Error('failed'));
      }
    ));
  }

  async searchTaskTypes() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.taskTypes = Array<TaskTypes>();
    await new Promise((resolve, reject) => this.taskService.getTaskTypes(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.taskTypes = res;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('failed'));
      }
    ));
  }

  async searchProjectTags() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectTags = Array<Tag>();
    await new Promise((resolve, reject) => this.tagService.getProjectTags(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectTags = res;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get tags');
          reject(new Error('failed'));
      }
    ));
  }

  async searchProjectUsers() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectUsers = Array<UserToShow>();
    await new Promise((resolve, reject) => this.projectService.getProjectUsers(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectUsers = res;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get users');
          reject(new Error('failed'));
      }
    ));
  }

  async searchProjectTeams() {
    if (!this.activeOrganizationId) {
      return;
    }

    this.projectTeams = Array<Team>();
    await new Promise((resolve, reject) => this.teamService.getTeams(this.activeOrganizationId, this.maxItemCount)
      .subscribe(
        res => {
          this.projectTeams = res.results;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get teams');
          reject(new Error('failed'));
      }
    ));
  }

  setProject(projectId: number) {
    if (this.projects.find(p => p.projectId === projectId || projectId === 0)) {
      this.projectId = (projectId && projectId > 0) ? projectId : null;
      this.versionId = null;
      this.typeId = null;
      this.tagEntityId = null;
      this.tagName = '';
      localStorage.setItem('currentProject', this.projectId?.toString());
      this.getProjectVersions();
      this.resetMultiselects();
      this.search();
    }
  }

  setVersion(versionId: number) {
    if (this.projectVersions.find(pv => pv.versionId === versionId) || versionId === 0){
      this.versionId = versionId > 0 ? versionId : null;
      localStorage.setItem('currentVersion', versionId?.toString());
    }
  }

  setPriority(priorityId: number, addingToList: boolean) {
    if (this.projectPriorites.find(pp => pp.priorityId === priorityId)){
      this.priorityId = priorityId > 0 ? priorityId : null;
      if (!this.priorityIds.find(p => p === priorityId) || addingToList) {
        this.priorityIds.push(priorityId);
        this.priorityIds.sort((a, b) => a - b);
      }
      else {
        const index = this.priorityIds.indexOf(priorityId, 0);
        if (index > -1) {
          this.priorityIds.splice(index, 1);
        }
      }
    }
    if (!this.priorityIds || this.priorityIds.length === 0) {
      this.priorityIds = new Array<number>();
    }
  }

  setType(typeId: number) {
    if (this.taskTypes.find(tt => tt.taskTypeId === typeId) || typeId === 0){
      this.typeId = typeId > 0 ? typeId : null;
      localStorage.setItem('currentType', typeId?.toString());
    }
  }

  setTag(tagId: number, tagName: string, addingToList: boolean) {
    if (this.projectTags.find(tt => tt.tagId === tagId && tt.projectId === this.projectId) || tagId === 0) {
      this.tagEntityId = tagId > 0 ? tagId : null;
      this.tagName = tagName ? tagName : null;

      if (!this.selectedTags.find(st => st.entityId === tagId && st.tagName === tagName) || addingToList) {
        this.selectedTags.push({entityId: tagId, tagName});
        this.selectedTags.sort((a, b) => a.entityId - b.entityId);
      }
      else {
        const tagToSearch: TagSearch = {entityId: tagId, tagName};
        const index = this.selectedTags.findIndex((tag) => {
          return tag.entityId === tagToSearch.entityId;
        });
        if (index > -1) {
          this.selectedTags.splice(index, 1);
        }
      }
    }
    if (!this.selectedTags || this.selectedTags.length === 0) {
      this.selectedTags = new Array<TagSearch>();
    }
  }

  setUser(userId: number, username: string) {
    if (this.projectUsers.find(pu => pu.userId === userId) || userId === 0){
      this.userId = userId > 0 ? userId : null;
      this.username = username ? username : null;
    }
  }

  setTeam(teamId: number, teamname: string) {
    if (this.projectTeams.find(pt => pt.teamId === teamId) || teamId === 0){
      this.teamId = teamId > 0 ? teamId : null;
      this.teamname = teamname ? teamname : null;
    }
  }

  getProjectName(): string {
    if (!this.projectId || isNaN(this.projectId) || this.projectId === 0) {
      return 'All projects';
    }

    return this.projects.find(p => p.projectId === this.projectId)?.projectName;
  }

  getVersionName(): string {
    if ((!this.versionId || isNaN(this.versionId) || this.versionId === 0) && this.projectVersions.length > 0) {
      return 'All versions';
    }

    return this.projectVersions.find(pv => pv.versionId === this.versionId)?.versionName;
  }

  getTypeName(): string {
    if ((!this.typeId || isNaN(this.typeId) || this.typeId === 0) && this.taskTypes.length > 0) {
      return 'All types';
    }

    return this.taskTypes.find(tt => tt.taskTypeId === this.typeId)?.typeName;
  }

  getUsername(): string {
    if (!this.userId || isNaN(this.userId) || this.userId === 0) {
      return 'All users';
    }

    return this.projectUsers.find(pu => pu.userId === this.userId)?.username;
  }

  getTeamName(): string {
    if (!this.teamId || isNaN(this.teamId) || this.teamId === 0) {
      return 'All teams';
    }

    return this.projectTeams.find(pt => pt.teamId === this.teamId)?.name;
  }

  onItemSelect(item: any, type: String) {
    this.selectItem(item, type, true);
  }
  onSelectAll(items: any, type: String) {
    for (const item of items) {
      this.selectItem(item, type, true);
    }
  }
  onDeSelectAll(items: any, type: String) {
    if (type === SearchDropdownOptions.Priority) {
      this.priorityIds = new Array<number>();
    }
    else if (type === SearchDropdownOptions.Tag) {
      this.selectedTags = new Array<TagSearch>();
    }
  }
  onItemDeSelect(item: any, type: String) {
    this.selectItem(item, type, false);
  }

  selectItem(item: any, type: String, addToList: boolean) {
    if (type === SearchDropdownOptions.Priority) {
      this.setPriority(item.priorityId, addToList);
    }
    else if (type === SearchDropdownOptions.Tag) {
      this.setTag(item.tagId, item.tagName, addToList);
    }
  }

  resetMultiselects() {
    this.priorities = [];
    this.projectPriorites = [];
    this.onDeSelectAll([], 'Priority');
    this.onDeSelectAll([], 'Tag');
  }
}
