import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import { HttpErrorResponse } from '@angular/common/http';
import { Task } from 'src/app/_models/task';
import { TaskState } from 'src/app/_models/taskState';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-taskstates-edit',
  templateUrl: './taskstates-edit.component.html',
  styleUrls: ['./taskstates-edit.component.css']
})
export class TaskstatesEditComponent implements OnInit, OnChanges {

  @Input() projectId: number;
  @Input() activeOrganizationId: number = null;

  taskStateEditForm: UntypedFormGroup;
  taskStates = [];
  tasks: Task[] = Array <Task>();
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";

  constructor(private router: Router, private fb: UntypedFormBuilder, private spinner: NgxSpinnerService,
              private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService, 
              private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
        if (this.projectId) {
          this.spinner.show();
          try {
            await this.searchTaskStates(this.projectId);
          } catch (error) {
            console.log('Error: ', error)
          }
          try {
            await this.getProjectTasks(this.projectId);
          } catch (error) {
            console.log('Error: ', error)
          }
          this.spinner.hide();
        }
      }
    }
  }

  async searchTaskStates(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.taskStates = [];

    await new Promise((resolve, reject) => this.taskService.getTaskStates(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.taskStates = res;
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load task types');
        reject(new Error('failed'));
      }
    ));
  }

  async getProjectTasks(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load tasks');
      return;
    }

    this.tasks = Array <Task>();

    await new Promise((resolve, reject) => this.taskService.getProjectTasks(this.activeOrganizationId, projectId, null, null)
      .subscribe(
        res => {
          this.tasks = res.results;
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load tasks');
        reject(new Error('failed'));
      }
    ));
  }

  hasStateTasks(stateId) {
    let stateTasks = this.tasks.find(t => t.taskState === stateId);
    return stateTasks ? true : false;
  }

  async saveTaskStates() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let editedTaskStates = [];

    for (let i = 0; i < this.taskStates.length; i++) {

      const formatedTaskstatesData = {
        stateId: this.taskStates[i].stateId,
        stateName: this.taskStates[i].stateName,
        closed: this.taskStates[i].closed ? 1 : 0,
        billable: this.taskStates[i].billable ? 1 : 0
      };

      editedTaskStates.push(formatedTaskstatesData);
    }

    const taskStateData =
    {
      taskStatesData: editedTaskStates
    };

    await new Promise((resolve, reject) => this.taskService.editTaskStates(taskStateData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved changes');
          this.searchTaskStates(this.projectId);
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to save changes');
            }
          }
          reject(new Error('Failed'));
        }
      ));
  }

  async deleteTaskState(typeId) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this task state?', () => {
      this.taskService.deleteTaskState(typeId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted task state');
          this.searchTaskStates(this.projectId);
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete task state');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }

  async changeOrder(stateId: number, direction: string) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let state2: TaskState;
    let state1: TaskState = this.taskStates.find(ts => ts.stateId === stateId);
    let orderIds: number[] = this.taskStates.map(ts => ts.orderId);
    let maxOrderId: number = Math.max(...orderIds);

    if (direction === 'up' && state1.orderId > 1) {
      state2 = this.taskStates.find(ts => ts.orderId === state1.orderId - 1);
    }
    else if (direction === 'down' && state1.orderId < maxOrderId) {
      state2 = this.taskStates.find(ts => ts.orderId === state1.orderId + 1);
    }
    
    const body = {
      organizationId: this.activeOrganizationId,
      projectId: this.projectId,
      stateId1: state1.stateId,
      stateId2: state2.stateId,
      orderId1: state1.orderId,
      orderId2: state2.orderId
    }
    await new Promise((resolve, reject) => this.taskService.updateTaskStateOrder(body)
    .subscribe(
      res => {
        const orderId = state1.orderId
        state1.orderId = state2.orderId;
        state2.orderId = orderId;
        this.orderTaskStates();
        resolve(true);
      }, err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to save changes');
          }
        }
        reject(new Error('Failed'));
      }
    ));
  }

  orderTaskStates() {
    this.taskStates = this.taskStates.sort((left, right): number => {
      if (left.orderId < right.orderId) return -1;
      if (left.orderId > right.orderId) return 1;
      return 0;
    });
  }

  isFirstOrLastState(stateId: number, direction: string) {
    let orderId = this.taskStates.find(ts => ts.stateId === stateId).orderId;
    if (direction === 'up') {
      return orderId <= 1 ? true : false;
    }
    else if (direction === 'down') {
      return this.taskStates.find(ts => ts.orderId > orderId) ? false : true;
    }

    return true;
  }
}
