<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <!-- <app-search pageHeader="Edit teams" [hasDates]=false [hasOnlyProject]=false [hasSelectAllProjects]=false [hasSelectAll]=true [searchTags]=false (searchParametersChanged)="search($event)"></app-search> -->
        <div class="card-header">
          <h3 class="mb-0">Edit teams</h3>
          <div class="worktime-search-limit">
            <div class="worktime-search-field">
                <label for="">From:</label>
                <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <div class="worktime-search-field">
              <label for="">To:</label>
              <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <button class="worktime-search-field" (click)="searchTeams()" [disabled]="false" type="button" class="btn btn-info mt-4 mb-0">Search</button>
          </div>

        <div class="card-body">
            <form class="form">
              <div class="horizontal-line mb-5">
                <button *ngIf="teams && teams.length > 0" (click)="saveTeams()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
                <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
              </div>
                <table class="table-full-width" *ngIf="teams">
                    <span *ngFor="let row of expandedStates">
                      <tr>
                        <td style="width: 25%;">
                          <!-- <button *ngIf="isOwner(row.organization.organizationId)" name="deleteOrganizationButton_{{row.organization.organizationName}}" (click)="deleteOrganization(row.organization.organizationId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button> -->
                          <button name="deleteTeamButton_{{row.team.name}}" (click)="deleteTeam(row.team.teamId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                          <button name="expandButton_{{row.team.name}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                        </td>
                      </tr>
                      <span *ngIf="!row.isActive">
                        <tr>
                          <td style="width: 25%;">
                            <label for="">*Team name</label>
                            <p>
                              {{row.team.name}}
                            </p>
                          </td>
                        </tr>
                      </span>
                    <span *ngIf="row.isActive">
                      <tr>
                        <td style="width: 25%;">
                          <label for="">*Team name</label>
                          <input [(ngModel)]="row.team.name"
                          name="teamname{{row.team.teamId}}"
                          type="text"
                          class="form-control">
                          <div class="invalid-feedback worktime-date-field">Team name is required</div>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <label for="">Description</label>
                          <textarea 
                          [(ngModel)]="row.team.description"
                            name="description{{row.team.name}}"
                            type="text"
                            class="form-control">
                          </textarea>
                        </td>
                      </tr>
                      <tr>
                        <td *ngIf="row.team.teamLeader">
                          <label for="">Team leader:</label>
                          <p 
                            type="text"
                            class="form-control">{{row.team.username}}</p>
                          <p class="team-link" (click)="setTeamLeader(null, row.team.teamId)">clear</p>
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <!-- <app-organization-users [organizationId]="row.organization.organizationId"></app-organization-users> -->
                          <app-entity-users 
                            [entityUsers]="getTeamUser(row.team.teamId)"
                            [organizationId]="activeOrganizationId"
                            [entityId]="row.team.teamId"
                            [canHighlight]="true"
                            (addUserEvent)="saveUser($event)"
                            (deleteUserEvent)="deleteUser($event)"
                            (highlightUserEvent)="setTeamLeader($event.userId, $event.entityId)"
                          >
                          </app-entity-users>
                        </td>
                      </tr>
                      <div class="bottom-row">
                      </div>
                    </span>
                  </span>
                </table>
                <div class="horizontal-line mb-5">
                  <button *ngIf="teams && teams.length > 0" (click)="saveTeams()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
                </div>
            </form>
            <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>
          <div>
            <h2 *ngIf="!teams || teams.length == 0">No data for selected time period</h2>
          </div>
          <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      <!-- </div> -->
    </div>
  </div>
