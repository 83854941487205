<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit organizations</h3>
          <div class="worktime-search-limit">
            <div class="worktime-search-field">
                <label for="">From:</label>
                <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <div class="worktime-search-field">
              <label for="">To:</label>
              <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <button class="worktime-search-field" (click)="searchOrganizations()" [disabled]="false" type="button" class="btn btn-info mt-4 mb-0">Search</button>
          </div>
        </div>

        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="userOrganizations && userOrganizations.length > 0" (click)="saveOrganizations()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
            </div>
              <table class="table-full-width" *ngIf="userOrganizations">
                  <span *ngFor="let row of expandedStates">
                    <tr>
                      <td style="width: 25%;">
                        <button *ngIf="isOwner(row.organization.organizationId)" name="deleteOrganizationButton_{{row.organization.organizationName}}" (click)="deleteOrganization(row.organization.organizationId)" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                        <button name="expandButton_{{row.organization.organizationName}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                      </td>
                    </tr>
                    <span *ngIf="!row.isActive">
                      <tr>
                        <td style="width: 25%;">
                          <label for="">*Organization name</label>
                          <p>
                            {{row.organization.organizationName}}
                          </p>
                        </td>
                      </tr>
                    </span>
                  <span *ngIf="row.isActive">
                    <tr>
                      <td style="width: 25%;">
                        <label for="">*Organization name</label>
                        <input [(ngModel)]="row.organization.organizationName"
                        name="organizationname{{row.organization.organizationId}}"
                        type="text"
                        class="form-control">
                        <div class="invalid-feedback worktime-date-field">Organization name is required</div>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <label for="">Description</label>
                        <textarea 
                        [(ngModel)]="row.organization.organizationDescription"
                          name="description{{row.organization.organizationDescription}}"
                          type="text"
                          class="form-control">
                        </textarea>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div *ngIf="getOrganizationRole(row.organization.organizationId).length > 0" style="width: 25%;">
                          <label for="">Default Role</label>
                          <div class="btn-group" style="width: 100%;" dropdown>
                              <button id="role-button{{row.organization.organizationId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                              aria-controls="dropdown-basic">
                              Default Role <span class="caret"></span>
                              </button>
                              <ul id="role-dropdown{{row.organization.organizationId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                  <li *ngFor="let role of getOrganizationRole(row.organization.organizationId)" role="menuitem" (click)="setRole(role, row.organization.organizationId)">{{role.roleName}}</li>
                              </ul>
                          </div>
                          <p *ngIf="row.organization.defaultRole" type="text" class="form-control">
                            {{getRoleName(row.organization.organizationId, row.organization.defaultRole)}}
                          </p>
                        </div>
                      </td>
                    </tr>
                    <tr *ngIf="isOwner(row.organization.organizationId)">
                      <td>
                        <!-- <app-organization-users [organizationId]="row.organization.organizationId"></app-organization-users> -->
                        <app-entity-users 
                          [entityUsers]="getOrganizationUser(row.organization.organizationId)"
                          [organizationId]="row.organization.organizationId"
                          [entityId]="row.organization.organizationId"
                          [userRoles]="getOrganizationRole(row.organization.organizationId)"
                          [lockedUsers]="getLockedUsersForOrganization(row.organization.organizationId)"
                          (addUserEvent)="saveUser($event)"
                          (deleteUserEvent)="deleteUser($event)"
                          (updateUserRoleEvent)="updateUserRole($event)"
                        >
                        </app-entity-users>
                      </td>
                    </tr>
                    <div class="bottom-row">
                    </div>
                  </span>
                </span>
              </table>
              <div class="horizontal-line mb-5">
                <button *ngIf="userOrganizations && userOrganizations.length > 0" (click)="saveOrganizations()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              </div>
          </form>
          <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>
        <div>
          <h2 *ngIf="!userOrganizations || userOrganizations.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
