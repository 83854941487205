import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class TodoService {

  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getTodoLists(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId
    }
    return this.http.post<any>(this.baseUrl + 'todoLists/get/' + projectId, body);
  }

  getTodoListItems(todoId: number, projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId,
      projectId
    }
    return this.http.post<any>(this.baseUrl + 'todoListItems/get/' + todoId, body);
  }

  saveTodoList(todoListData, projectId: number, organizationId: number) {
    todoListData.userId = this.authService.currentUser.id;
    todoListData.projectId = projectId;
    todoListData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'todoList/save', todoListData);
  }

  saveTodoListItem(todoListItemData, projectId: number, organizationId: number) {
    todoListItemData.userId = this.authService.currentUser.id;
    todoListItemData.projectId = projectId;
    todoListItemData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'todoListItem/save', todoListItemData);
  }

  editTodoLists(todoListData, projectId: number, organizationId: number) {
    todoListData.organizationId = organizationId;
    const todoListsData = {
      todoListsData: todoListData,
      userId: this.authService.currentUser.id,
      projectId,
      organizationId
    };
    return this.http.post<any>(this.baseUrl + 'todoLists/edit', todoListsData);
  }

  editTodoListItems(data, projectId: number, organizationId: number, todoId: number) {
    const todoItemsData = {
      todoItemsData: data,
      userId: this.authService.currentUser.id,
      projectId,
      organizationId,
      todoId
    };
    return this.http.post<any>(this.baseUrl + 'todoListItems/edit', todoItemsData);
  }

  deleteTodoList(todoId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.currentUser.id,
        projectId,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'todoList/delete/' + todoId, options);
  }

  deleteTodoListItem(itemId: number, todoId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.currentUser.id,
        projectId,
        organizationId,
        todoId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'todoListItem/delete/' + itemId, options);
  }
}
