import { Component, OnInit, Input, Output, OnChanges, SimpleChanges, SimpleChange, EventEmitter } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { TaskTypes } from '../../_models/taskTypes';
import { TaskState } from '../../_models/taskState';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectService } from '../../_services/project.service';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import * as moment from 'moment';
import { Task } from '../../_models/task';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectVersion } from '../../_models/projectVersion';
import { Priority } from 'src/app/_models/priority';
import { PriorityService } from 'src/app/_services/priority.service';
import { BoardTask } from 'src/app/_models/boardTask';
import { Tag } from 'src/app/_models/tag';
import { TagService } from 'src/app/_services/tag.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { async } from 'rxjs/internal/scheduler/async';

@Component({
  selector: 'app-task-edit-single',
  templateUrl: './task-edit-single.component.html',
  styleUrls: ['./task-edit-single.component.css']
})
export class TaskEditSingleComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() task: BoardTask;
  @Input() taskState: TaskState;
  @Input() version: ProjectVersion;
  @Input() priority: Priority;
  @Input() activeOrganizationId: number;

  @Output() taskSaved: EventEmitter<any> = new EventEmitter();

  localFormat = 'YYYY-MM-DD HH:mm:ss';
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  taskCreateForm: UntypedFormGroup;
  projects = [];
  tasks = [];
  taskTypes: TaskTypes[];
  taskStates: TaskState[];
  projectVersions: ProjectVersion[];
  projectPriorities: Priority[];
  activeTaskType;
  activeTaskState;
  activeProject;
  activeVersion: ProjectVersion;
  activePriority: Priority;
  relationTable: string = 'Tasks';
  projectTags: Array<Tag> = new Array<Tag>();
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  dataLoading = {
    isLoading1: true,
    isLoading2: true,
    isLoading3: true,
    isLoading4: true,
    isLoading5: true
  };

  constructor(private projectService: ProjectService, private router: Router, private fb: UntypedFormBuilder,
              private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService, 
              private priorityService: PriorityService, private tagService: TagService, private spinner: NgxSpinnerService,
              private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.spinner.show();

    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.createTaskForm();
    try {
      await this.getTaskTypes();
      await this.getTaskStates();
      await this.getProjectVersions();
      await this.getPriorities();
      await this.getProjectTags();
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'task') {
      }
    }

    if (!this.taskCreateForm) {
       this.createTaskForm();
    }

    if (this.taskCreateForm && this.taskTypes && this.taskStates && this.task) {
      this.taskCreateForm.setValue({
        taskname: this.task?.taskName,
        taskprojectid: this.projectId,
        tasktype: this.activeTaskType?.typeName,
        tasktypeid: this.task?.taskType,
        taskstate: this.activeTaskState?.stateName,
        taskstateid: this.task?.taskState,
        taskversionid: this.task?.versionId !== 0 ? this.task?.versionId : this.version ? this.version.versionId : 0,
        taskpriorityid: this.task?.priorityId !== 0 ? this.task?.priorityId : this.priority ? this.priority.priorityId : 0,
        taskdeadline: this.task?.deadline,
        description: this.task?.description,
        estimatedDays: this.task?.estimatedDays,
        estimatedHours: this.task?.estimatedHours,
        estimatedMinutes: this.task?.estimatedMinutes
      });
    }
  }

  createTaskForm() {
    this.taskCreateForm = this.fb.group({
      taskname: ['', Validators.required],
      taskprojectid: ['', Validators.required],
      tasktypeid: ['', Validators.required],
      taskstateid: ['', Validators.required],
      taskversionid: ['', ''],
      taskpriorityid: ['', ''],
      taskdeadline: ['', ''],
      taskdescription: ['', ''],
      description: ['', ''],
      estimatedDays: ['', ''],
      estimatedHours: ['', ''],
      estimatedMinutes: ['', '']
    });
  }

  async getTaskTypes() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to get task types');
      this.dataLoading.isLoading1 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.taskService.getTaskTypes(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskTypes = res;
            if (this.taskCreateForm) {
              if (this.task) {
                this.activeTaskType = this.taskTypes.find(tt => tt.taskTypeId === this.task.taskType);
                this.taskCreateForm.patchValue({taskname: this.task.taskName});
                this.taskCreateForm.patchValue({taskdescription: this.task.description});
                this.taskCreateForm.patchValue({tasktype: this.activeTaskType?.typeName});
                this.taskCreateForm.patchValue({tasktypeid: this.activeTaskType?.taskTypeId});
                this.taskCreateForm.patchValue({estimatedDays: this.task.estimatedDays});
                this.taskCreateForm.patchValue({estimatedHours: this.task.estimatedHours});
                this.taskCreateForm.patchValue({estimatedMinutes: this.task.estimatedMinutes});
              }
              this.taskCreateForm.patchValue({taskprojectid: this.projectId});
              this.dataLoading.isLoading1 = false;
              this.isSpinnerVisible();
            }
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get task types');
          this.dataLoading.isLoading1 = false;
          this.isSpinnerVisible();
          reject(new Error('Failed'));
      }
    ));
  }

  async getTaskStates() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to get task states');
      this.dataLoading.isLoading2 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.taskService.getTaskStates(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskStates = res;
            if (this.taskCreateForm) {
              if (this.task) {
                this.activeTaskState = this.taskStates.find(ts => ts.stateId === this.task.taskState);
                this.taskCreateForm.patchValue({taskname: this.task.taskName});
                this.taskCreateForm.patchValue({taskdescription: this.task.description});
                this.taskCreateForm.patchValue({taskstate: this.activeTaskState?.stateName});
                this.taskCreateForm.patchValue({taskstateid: this.activeTaskState?.stateId});
                this.taskCreateForm.patchValue({estimatedDays: this.task.estimatedDays});
                this.taskCreateForm.patchValue({estimatedHours: this.task.estimatedHours});
                this.taskCreateForm.patchValue({estimatedMinutes: this.task.estimatedMinutes});
              }
              this.taskCreateForm.patchValue({taskprojectid: this.projectId});

              if (this.taskState && !this.task) {
                this.setTaskState(this.taskState.stateId, this.taskState.stateName);
              }
            }
            this.dataLoading.isLoading2 = false;
            this.isSpinnerVisible();
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get project types');
          this.dataLoading.isLoading2 = false;
          this.isSpinnerVisible();
          reject(new Error('Failed'));
      }
    ));
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to get project versions');
      this.dataLoading.isLoading3 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectVersions = res;
            if (this.version) {
              this.activeVersion = this.projectVersions.find(pv => pv && pv.versionId === this.version.versionId);
              this.setVersion(this.activeVersion.versionId);
            }
          }
          else {
            this.projectVersions = [];
          }
          this.dataLoading.isLoading3 = false;
          this.isSpinnerVisible();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project versions');
          this.dataLoading.isLoading3 = false;
          this.isSpinnerVisible();
          reject(new Error('Failed'));
      }
    ));
  }

  async getPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load priorities');
      this.dataLoading.isLoading4 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
    .subscribe(
      res => {
        this.projectPriorities = res;
        if (this.priority) {
            this.activePriority = this.projectPriorities.find(pp => pp && pp.priorityId === this.priority.priorityId);
            this.setPriority(this.activePriority.priorityId);
          }
          this.dataLoading.isLoading4 = false;
          this.isSpinnerVisible();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load priorities');
          this.dataLoading.isLoading4 = false;
          this.isSpinnerVisible();
          reject(new Error('Failed'));
      }
    ));
  }

  setTaskType(taskTypeId, typeName) {
    this.taskCreateForm.patchValue({tasktype: typeName});
    this.taskCreateForm.patchValue({tasktypeid: taskTypeId});
    this.activeTaskType = this.taskTypes.find(tt => tt.taskTypeId === taskTypeId);
  }

  setTaskState(stateId, stateName) {
    this.taskCreateForm.patchValue({taskstate: stateName});
    this.taskCreateForm.patchValue({taskstateid: stateId});
    this.activeTaskState = this.taskStates.find(ts => ts.stateId === stateId);
  }

  setVersion(versionId: number) {
    this.taskCreateForm.patchValue({taskversionid: versionId});
    this.activeVersion = this.projectVersions.find(v => v.versionId === versionId);
  }

  setPriority(priorityId: number) {
    this.taskCreateForm.patchValue({taskpriorityid: priorityId});
    this.activePriority = this.projectPriorities.find(pp => pp.priorityId === priorityId);
  }

  async saveTask() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let deadline = this.taskCreateForm.value['projectdeadline'];
    if (!deadline) {
      deadline = null;
    }
    else {
      moment(deadline).format(this.localFormat);
    }

    const taskData: BoardTask = {
      taskId: this.task ? this.task.taskId : null,
      taskName: this.taskCreateForm.value['taskname'],
      projectId: this.taskCreateForm.value['taskprojectid'],
      ownerId: this.authService.decodedToken.id,
      taskType: this.taskCreateForm.value['tasktypeid'],
      taskState: this.taskCreateForm.value['taskstateid'],
      versionId: this.activeVersion ? this.activeVersion.versionId : null,
      deadline,
      priorityId: this.activePriority ? this.activePriority.priorityId : null,
      assigneeId: this.task ? this.task.assigneeId : null,
      assignee: this.task ? this.task.assignee : null,
      description: this.taskCreateForm.value['taskdescription'],
      estimatedDays: this.taskCreateForm.value['estimatedDays'],
      estimatedHours: this.taskCreateForm.value['estimatedHours'],
      estimatedMinutes: this.taskCreateForm.value['estimatedMinutes']
    };

    if (deadline != null && !moment(taskData.deadline).isValid()) {
      this.alertify.error('Invalid deadline');
      return;
    }

    await new Promise((resolve, reject) => this.taskService.editSingleTask(taskData, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved task');
          this.taskSaved.emit(taskData);
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save task');
          reject(new Error('Failed'));
      }
    ));
  }

  async updateAssignee(assignee) {
    this.task.assignee = assignee ? assignee.username : '';
    this.task.assigneeId = assignee ? assignee.assigneeId : null;

    await this.saveTask();
  }

  async deleteTask() {
    if (!this.task || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this task', () => {
      this.taskService.deleteTask(this.task.taskId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted task');
          this.taskSaved.emit(this.task);
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete task');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }

  async getProjectTags() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load tags');
      this.dataLoading.isLoading5 = false;
      this.isSpinnerVisible();
      return;
    }

    this.projectTags = new Array<Tag>();

    await new Promise((resolve, reject) => this.tagService.getProjectTags(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          res;
          res.forEach(tag => {
            this.projectTags.push(tag);
          });
          this.dataLoading.isLoading5 = false;
          this.isSpinnerVisible();
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load tags');
        this.dataLoading.isLoading5 = false;
        this.isSpinnerVisible();
        reject(new Error('Failed'));
      }
    ));
  }

  getTaskId() {
    return this.task ? this.task.taskId : null;
  }

  getProjectId() {
    return this.task ? this.task.projectId : null;
  }

  isSpinnerVisible() {
    if (!this.dataLoading.isLoading1
      || !this.dataLoading.isLoading2
      || !this.dataLoading.isLoading3
      || !this.dataLoading.isLoading4
      || !this.dataLoading.isLoading5) {
      this.spinner.hide();
    }
  }
}
