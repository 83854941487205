import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { ProjectService } from 'src/app/_services/project.service';

@Component({
  selector: 'app-organization-create',
  templateUrl: './organization-create.component.html',
  styleUrls: ['./organization-create.component.css']
})
export class OrganizationCreateComponent implements OnInit {
  @Input() activeOrganizationId: number = null;
  @Output() organizationCreated: EventEmitter<any> = new EventEmitter();

  localFormat = 'YYYY-MM-DD HH:mm:ss';
  organizationCreateForm: UntypedFormGroup;
  projects = [];

  constructor(private organizationService: OrganizationService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.createOrganizationForm();
  }

  createOrganizationForm() {
    this.organizationCreateForm = this.fb.group({
      organizationname: ['', Validators.required],
      description: ['', ''],
    });
  }

  async saveOrganisation()
  {
    if (!this.activeOrganizationId) {
      this.alertify.error('No organization selected');
      return;
    }

    const organizationData = {
      ownerId: this.authService.decodedToken.id,
      organizationName: this.organizationCreateForm.value['organizationname'],
      description: this.organizationCreateForm.value['description'],
      organizationId: this.activeOrganizationId
    };

    await new Promise((resolve, reject) => this.organizationService.saveOrganization(organizationData)
      .subscribe(
        res => {
          this.organizationCreated.emit();
          this.organizationCreateForm.reset();
          this.alertify.success('Saved organization');
          resolve(true);
        }, err => {
          this.alertify.error('Failed to save organization');
          reject(new Error('failed'));
      }
    ));
  }
}
