<div>
    <form [formGroup]="commentsForm" class="form comment-container">
        <label for="">Comments:</label>
        <div class="message-area" *ngIf="t && t.controls">
            <div formArrayName="comments" *ngFor="let comment of t.controls; let i = index">
                <div [formGroup]="comment" [formGroupName]="i">
                    <div>
                        <p class="comment-owner-text" [attr.for]="'comment'+i">Sender: {{commentsForm.get('comments').value[i].username}}</p>
                        <div *ngIf="isOwner(commentsForm.get('comments').value[i].userId)" class="edit-button">
                            <button (click)="activateEditState(commentsForm.get('comments').value[i])" [disabled]="false" type="button" class="btn btn-success mb-3">{{getEditState(commentsForm.get('comments').value[i].commentId)}}</button>
                            <button (click)="deleteComment(commentsForm.get('comments').value[i].commentId)" [disabled]="false" type="button" class="btn btn-warning mb-3">Delete</button>
                        </div>
                    </div> 
    
                    <textarea *ngIf="canEdit(commentsForm.get('comments').value[i])" formControlName="comment"  [id]="'a-comment'+i" class="text-box comment-text">{{commentsForm.get('comments').value[i].comment}}</textarea>
                    <p *ngIf="!canEdit(commentsForm.get('comments').value[i])" [id]="'p-comment'+i" class="text-box comment-text">{{commentsForm.get('comments').value[i].comment}}</p>
                </div>
            </div>
        </div>

        <div class="new-comment-row">
            <p>New comment:</p>
            <textarea 
            formControlName="newComment"
            name="newComment" 
            class="text-box new-comment-text" 
            placeholder="Add comment"></textarea>
            <button [disabled]="!commentsForm.valid" (click)="saveComment()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
        </div>
    </form>
</div>