<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new project type</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="projectTypeForm" class="form">
            <table style="width: 100%;">
                <tr>
                    <td colspan="2">
                        <label for="">*Project type name</label>
                        <input 
                          [ngClass]="{'is-invalid': projectTypeForm.get('projecttypename').errors && projectTypeForm.get('projecttypename').touched}"
                          formControlName="projecttypename"
                          name="projecttypename"
                          type="text"
                          class="form-control">
                        <div class="invalid-feedback worktime-date-field">Project type name is required</div>
                    </td>
                </tr>
              <tr class="bottom-row">
                <td>
                  <button [disabled]="!projectTypeForm.valid" name="createProjectTypeSave" (click)="saveProjectType()" type="button" class="btn btn-success float-left">Save</button>
                </td>
              </tr>
            </table>
          </form>

        </div>
      </div>
    </div>
  </div>

