<div>
    <label for="">Assignee: {{assignee ? assignee.username : ''}}</label>
    <button *ngIf="assignee" (click)="setAssignee(-1)" type="button" class="btn btn-info unassign-btn">Unassign</button>
    <div>
        <p class="assignee-link" (click)="setAssignee()">Assign to me</p>
        <div class="assignee-area" *ngIf="projectUsers && projectUsers.length > 0">
            <div *ngFor="let user of projectUsers" class="assignee-row">
                <p class="assignee-name">{{user.username}}</p>
                <button *ngIf="projectUsers && projectUsers.length > 0" (click)="setAssignee(user.userId)" type="button" class="btn btn-warning float-left assignee-button">Assign to</button>
            </div>
        </div>
    </div>
</div>
