import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  loginUserData = {username: '', password: ''};

  constructor(private authService: AuthService, private router: Router, private alertify: AlertifyService) { }

  ngOnInit(): void {
  }

  loginUser(event = null) {
    if (event && event.keyCode !== 13) {
      return;
    }
    this.authService.loginUser(this.loginUserData)
      .subscribe(
        res => {
          this.alertify.success('Logged in');
          this.router.navigate(['/worktime/create']);
        }, err =>
        {
          console.log(err);
          this.alertify.error('Failed to login');
        }
      );
  }
}
