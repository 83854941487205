<div *ngIf="userOrganizations.length > 1">
  <a class="dropdown-toggle text-light" dropdownToggle>
    Organization (<a class="menu-group-info">{{organizationService.getActiveOrganizationName()}}</a>)
  </a>

  <div class="dropdown-menu mt-3" *dropdownMenu>
    <div *ngFor="let organization of userOrganizations">
      <a class="dropdown-item" (click)="setActiveOrganization(organization)">
        <i class="fa fa-user">{{organization.organizationName}}</i>
      </a>
      <div *ngIf="userOrganizations.length > 1" class="dropdown-divider"></div>
    </div>
  </div>
</div>

<div *ngIf="userOrganizations.length <= 1">
  <a class="text-light">
    Organization (<a class="menu-group-info">{{organizationService.getActiveOrganizationName()}}</a>)
  </a>
</div>
