<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit task types</h3>
        </div>
        
        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="taskTypes.length > 0" (click)="saveTaskTypes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>

            <table class="table-full-width">
              <span *ngFor="let type of taskTypes" class="full-row">
                <tr>
                  <td class="full-row">
                    <button (click)="deleteTaskType(type.taskTypeId)" name="deleteTaskType_{{type.typeName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                  </td>
                </tr>
                <tr>
                  <td class="half-row bottom-row">
                    <label for="">*Tasktype name</label>
                    <input [(ngModel)]="type.typeName" id="typeName_{{type.typeName}}" name="typeName{{type.taskTypeId}}" class="form-control" placeholder="{{type.typeName}}" type="text">
                  </td>
                  <td class="half-row bottom-row">
                    <label for="">Billable</label>
                    <input [(ngModel)]="type.billable" name="billable{{type.taskTypeId}}" class="form-control" placeholder="{{type.billable}}" type="checkbox">
                  </td>
              </span>
            </table>

            <div class="mt-5">
              <button *ngIf="taskTypes.length > 0" (click)="saveTaskTypes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>
          </form>

        <div>
          <h2 *ngIf="taskTypes.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
