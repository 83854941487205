import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { WorktimeCreateComponent } from './worktime/worktime-create/worktime-create.component';
import { WorktimeEditComponent } from './worktime/worktime-edit/worktime-edit.component';
import { AuthGuard } from './auth.guard';
import { WorktimeReportingComponent } from './worktime/worktime-reporting/worktime-reporting.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { ProjectEditComponent } from './ProjectManagement/project-edit/project-edit.component';
import { ProjectCreateComponent } from './ProjectManagement/project-create/project-create.component';
import { TaskCreateComponent } from './TaskManagement/task-create/task-create.component';
import { TaskEditComponent } from './TaskManagement/task-edit/task-edit.component';
import { TaskTypesComponent } from './TaskManagement/task-types/task-types.component';
import { TaskStatesComponent } from './TaskManagement/task-states/task-states.component';
import { ProjectTypesComponent } from './ProjectManagement/project-types/project-types.component';
import { BoardComponent } from './board/board.component';
import { ProjectVersionsComponent } from './ProjectManagement/project-versions/project-versions.component';
import { TasksComponent } from './TaskManagement/tasks/tasks.component';
import { ProjectsComponent } from './ProjectManagement/projects/projects.component';
import { PrioritiesComponent } from './ProjectManagement/priorities/priorities.component';
import { PriorityCreateComponent } from './ProjectManagement/priority-create/priority-create.component';
import { PriorityEditComponent } from './ProjectManagement/priority-edit/priority-edit.component';
import { OrganizationsComponent } from './Organization/organizations/organizations.component';
import { TeamsComponent } from './Teams/teams/teams.component';
import { RolesComponent } from './RoleManagement/roles/roles.component';
import { TodoListsComponent } from './Productivity/todo-lists/todo-lists.component';


const routes: Routes = [
  {
    path: '',
    redirectTo: 'worktime/create',
    pathMatch: 'full'
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'users/edit',
    component: UserManagementComponent
  },
  {
    path: 'worktime/create',
    component: WorktimeCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'worktime/edit',
    component: WorktimeEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'worktime/reporting',
    component: WorktimeReportingComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'organization/organizations',
    component: OrganizationsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'roles/roles',
    component: RolesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'teams/teams',
    component: TeamsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/projects',
    component: ProjectsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/create',
    component: ProjectCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'projects/edit',
    component: ProjectEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'priority/priorities',
    component: PrioritiesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'priority/create',
    component: PriorityCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'priority/edit',
    component: PriorityEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'task/tasks',
    component: TasksComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'task/create',
    component: TaskCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'task/edit',
    component: TaskEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'task/types',
    component: TaskTypesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'task/states',
    component: TaskStatesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project/types',
    component: ProjectTypesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'project/versions',
    component: ProjectVersionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'board',
    component: BoardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'productivity/todo-lists',
    component: TodoListsComponent,
    canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
