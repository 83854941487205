<div class="col-md-6 mx-auto card-header">
    <h3 class="mb-0">Task states</h3>
    <div class="board-search-field">
        <label for="">Project</label>
        <div *ngIf="projects.length > 0 && checkPagePermissions('project.view') && checkPagePermissions('project.view') && checkPagePermissions('taskStates.view')" class="btn-group" style="width: 100%;" dropdown>
            <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
            aria-controls="dropdown-basic">
            Projects <span class="caret"></span>
            </button>
            <ul id="project-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
            <li *ngFor="let project of projects" role="menuitem" (click)="setProject(project.projectId)">{{project.projectName}}</li>
            </ul>
            <p>{{getProjectName()}}</p>
        </div>
    </div>
</div>

<ng-container *ngIf="checkPagePermissions('taskStates.view') && checkPagePermissions('project.view')">
    <app-taskstates-create *ngIf="checkPagePermissions('taskStates.create') && checkPagePermissions('project.view')" [projectId]="projectId" [activeOrganizationId]="activeOrganizationId" (taskstateCreated)="updateTaskStates($event)"></app-taskstates-create>
    <app-taskstates-edit *ngIf="checkPagePermissions('taskStates.edit') && checkPagePermissions('project.view')" [projectId]="projectId" [activeOrganizationId]="activeOrganizationId"></app-taskstates-edit>
</ng-container>