import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Organization } from 'src/app/_models/organization';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { RoleService } from 'src/app/_services/role.service';

@Component({
  selector: 'app-role-create',
  templateUrl: './role-create.component.html',
  styleUrls: ['./role-create.component.css']
})
export class RoleCreateComponent implements OnInit {

  // @Input() organizations: Array<Organization> = new Array<Organization>();
  @Input() activeOrganizationId: number = null;

  @Output() roleCreated: EventEmitter<any> = new EventEmitter();

  localFormat = 'YYYY-MM-DD HH:mm:ss';
  roleCreateForm: UntypedFormGroup;
  roles = [];
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;

  constructor(private roleService: RoleService, private organizationService: OrganizationService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createRoleForm();
  }

  createRoleForm() {
    this.roleCreateForm = this.fb.group({
      name: ['', Validators.required],
      description: ['', '']
    });
  }

  saveRole()
  {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const roleData = {
      userId: this.authService.decodedToken.id,
      name: this.roleCreateForm.value['name'],
      description: this.roleCreateForm.value['description'],
      organizationId: this.activeOrganizationId,
    };

    this.roleService.saveRole(roleData)
      .subscribe(
        res => {
          this.roleCreated.emit();
          this.roleCreateForm.reset();
          this.alertify.success('Saved role');
        }, err => {
          this.alertify.error('Failed to save role');
      }
    );
  }

}
