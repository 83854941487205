import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { RoleService } from 'src/app/_services/role.service';
import { RoleEditComponent } from '../role-edit/role-edit.component';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.css']
})
export class RolesComponent implements OnInit {
  @ViewChild(RoleEditComponent) child: RoleEditComponent;
  // perPage: number = 10;
  // pageNumber: number = 0;
  // organizations: Array<Organization> = new Array<Organization>();
  resultCount: number = 0;
  activeOrganizationId: number = null;

  constructor(private roleService: RoleService, private authService: AuthService, private alertify: AlertifyService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService,
    private activeOrganizationService: ActiveOrganizationService) { }

    ngOnInit(): void {
      this.activeOrganizationService.getOrganizations();
    }
  
    ngDoCheck() {
      this.checkActiveOrganization();
    }
  
    checkActiveOrganization() {
      const previousOrganizationId = this.activeOrganizationId;
      let newId = this.activeOrganizationService.validateActiveOrganization();
      if (!newId) {
        this.activeOrganizationService.getOrganizations();
        return;
      }
      if (previousOrganizationId !== newId && !isNaN(newId)) {
        this.activeOrganizationId = Number(newId);
        this.updateRoles(null);
      }
    }

  updateRoles(events: any) {
    if (!this.child) {
      return;
    }
    this.child.searchRoles();
  }
}
