<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit tasks</h3>
          <div class="worktime-search-limit">
            <div class="worktime-search-field">
                <label for="">From:</label>
                <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>
            <div class="worktime-search-field">
              <label for="">To:</label>
              <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
            </div>

            <label for="">Project selection</label>

            <div *ngIf="userProjects && userProjects.length > 0" class="btn-group project-search-header" dropdown>
              <div class="project-search-dropdown">
                <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                  aria-controls="dropdown-basic">
                  Projects <span class="caret"></span>
                </button>
                <ul id="project-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                  <li *ngFor="let project of userProjects" role="menuitem" (click)="setProject(project.projectId)">{{project.projectName}}</li>
                </ul>
              </div>
              <div class="selected-project">
                <h4>{{getProjectName()}}</h4>
              </div>
            </div>

            <button class="worktime-search-field" (click)="searchProjects()" [disabled]="false" type="button" class="btn btn-info mt-4 mb-0">Search</button>
          </div>
        </div>

        <div class="card-body">
          <form class="form" *ngIf="userProjects && userProjects.length > 0 && projectTasks && projectTasks.length > 0 && taskTypes && taskStates">
            <div class="horizontal-line mb-5">
              <button *ngIf="userProjects.length > 0" (click)="saveTasks()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
            </div>
              <table class="table-full-width" *ngIf="userProjects && projectTasks">
                <span *ngFor="let row of expandedStates">
                  <tr>
                    <td style="width: 25%;" class="top-row">
                      <button (click)="deleteTask(row.task.taskId)" name="deleteTaskButton_{{row.task.taskName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                      <button name="expandButton_{{row.task.taskName}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                    </td>
                  </tr>
                  <span *ngIf="!row.isActive">
                    <tr>
                      <td class="minified-column" colspan="2">
                        <label for="">*Task name</label>
                        <p>{{row.task.taskName}}</p>
                      </td>
                      <td class="minified-column">
                        <label for="">Task state:</label>
                        <p *ngIf="row.task.taskState">{{getTaskStateFromStateId(row.task.taskState)}}</p>
                        <p *ngIf="!row.task.taskState">No state</p>
                      </td>
                      <td class="minified-column">
                        <label for="">Priority</label>
                        <p *ngIf="row.task.priorityId">{{getTaskPriorityFromPriorityId(row.task.priorityId)}}</p>
                        <p *ngIf="!row.task.priorityId">No priority</p>
                      </td>
                      <td class="minified-column">
                        <label for="">Deadline</label>
                        <p *ngIf="row.task.deadline">{{getSimplifiedDeadline(row)}}</p>
                        <p *ngIf="!row.task.deadline">No deadline</p>
                      </td>
                    </tr>
                  </span>

                  <span *ngIf="row.isActive">
                    <tr>
                      <td colspan="2">
                        <label for="">*Task name</label>
                        <input [(ngModel)]="row.task.taskName"
                        name="taskname{{row.task.taskId}}"
                        id="edit_taskname_{{row.task.taskName}}"
                        type="text"
                        class="form-control">
                        <div class="invalid-feedback worktime-date-field">Task name is required</div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label for="">Description</label>
                        <textarea 
                        [(ngModel)]="row.task.description"
                          name="taskname{{row.task.description}}"
                          type="text"
                          class="form-control">
                        </textarea>
                      </td>
                    </tr>
                    <tr>
                      <td style="width: 25%;">
                          <label for="">*Task type</label>
                          <div *ngIf="taskTypes.length > 0" id="taskType_{{row.task.taskName}}" class="btn-group" style="width: 100%;" dropdown>
                            <button id="type-button{{row.task.taskId}}" name="taskType_{{row.task.taskName}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                            aria-controls="dropdown-basic">
                            Types <span class="caret"></span>
                          </button>
                          <ul id="type-dropdown{{row.task.taskId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li *ngFor="let type of taskTypes" role="menuitem" (click)="setType(row.task.taskId, type.taskTypeId)">{{type.typeName}}</li>
                            </ul>
                          </div>
                          <p *ngIf="row.task" type="text" class="form-control">
                            {{getTaskTypeFromTypeId(row.task.taskType)}}
                          </p>
                      </td>
                      <td style="width: 25%;">
                        <label for="">*Task state</label>
                        <div *ngIf="taskStates.length > 0" id="taskState_{{row.task.taskName}}" class="btn-group" style="width: 100%;" dropdown>
                          <button id="state-button{{row.task.taskId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          States <span class="caret"></span>
                        </button>
                        <ul id="state-dropdown{{row.task.taskId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let state of taskStates" role="menuitem" (click)="setState(row.task.taskId, state.stateId)">{{state.stateName}}</li>
                          </ul>
                        </div>
                        <p *ngIf="row.task" type="text" class="form-control">
                          {{getTaskStateFromStateId(row.task.taskState)}}
                        </p>
                      </td>
                    <tr>
                    <tr>
                      <td style="width: 25%;">
                        <label for="">Version</label>
                        <div *ngIf="taskStates.length > 0" id="taskVersion_{{row.task.taskName}}" class="btn-group" style="width: 100%;" dropdown>
                          <button id="version-button{{row.task.taskId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Version <span class="caret"></span>
                        </button>
                        <ul id="version-dropdown{{row.task.taskId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" (click)="setVersion(row.task.taskId, 0)">No version</li>
                          <li *ngFor="let version of projectVersions" role="menuitem" (click)="setVersion(row.task.taskId, version.versionId)">{{version.versionName}}</li>
                          </ul>
                        </div>
                        <p *ngIf="row.task" type="text" class="form-control">
                          {{getTaskVersionFromVersionId(row.task.versionId)}}
                        </p>
                    </td>
                    <td>
                      <label for="">Priority</label>
                        <div *ngIf="taskStates.length > 0" id="taskPriority_{{row.task.taskName}}" class="btn-group" style="width: 100%;" dropdown>
                          <button id="priority-button{{row.task.taskId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Priority <span class="caret"></span>
                        </button>
                        <ul id="priority-dropdown{{row.task.taskId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" (click)="setPriority(row.task.taskId, 0)">No priority</li>
                          <li *ngFor="let priority of priorities" role="menuitem" (click)="setPriority(row.task.taskId, priority.priorityId)">{{priority.priorityName}}</li>
                          </ul>
                        </div>
                        <p *ngIf="row.task" type="text" class="form-control">
                          {{getTaskPriorityFromPriorityId(row.task.priorityId)}}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2"> 
                        <label for="">Task deadline</label>
                        <input [(ngModel)]="row.task.deadline" id="deadline{{row.task.projectId}}" name="taskDeadline_{{row.task.taskName}}" class="form-control" placeholder="Project deadline" type="text" bsDatepicker [bsConfig]="bsConfig">
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-assignee [projectId]="projectId" [taskId]="row.task.taskId" [activeOrganizationId]="activeOrganizationId" [taskId]="row.task.taskId"></app-assignee>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-worktime-logging [projectId]="row.task.projectId" [taskId]="row.task.taskId" [activeOrganizationId]="activeOrganizationId"></app-worktime-logging>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <label for="">Estimated worktime</label>
                        <div>
                          <label class="worktime-estimation-days-label" for="">Days: </label>
                          <input 
                          [(ngModel)]="row.task.estimatedDays"
                            name="estimatedDays{{row.task.estimatedDays}}"
                            type="number"
                            min="0" 
                            class="form-control worktime-estimation">
                          <label class="worktime-estimation-label" for="">Hours: </label>
                          <input 
                          [(ngModel)]="row.task.estimatedHours"
                            name="estimatedHours{{row.task.estimatedHours}}"
                            type="number"
                            min="0"
                            max="24"
                            class="form-control worktime-estimation">
                          <label class="worktime-estimation-label" for="">Minutes: </label>
                          <input 
                          [(ngModel)]="row.task.estimatedMinutes"
                            name="estimatedMinutes{{row.task.estimatedMinutes}}"
                            type="number"
                            min="0" 
                            max="60"
                            class="form-control worktime-estimation">
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">
                        <app-comment entityId={{row.task.taskId}} [activeOrganizationId]="activeOrganizationId" [relationTable]="relationTable" projectId={{row.task.projectId}}></app-comment>
                      </td>
                    </tr>
                    <tr>
                      <td class="bottom-row" colspan="2">
                        <app-tag entityId={{row.task.taskId}} [activeOrganizationId]="activeOrganizationId" [relationTable]="relationTable" projectId={{row.task.projectId}} [projectTags]="projectTags"></app-tag>
                      </td>
                    </tr>
                  </span>
                </span> 
              </table>
              <div class="horizontal-line mb-5">
                <button *ngIf="userProjects.length > 0" (click)="saveTasks()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              </div>
            </form>
          <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>
        <div>
          <h2 *ngIf="!userProjects || userProjects.length === 0 || !projectTasks || projectTasks.length === 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
