<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Project versions</h3>
        </div>
        
        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="projectVersions && projectVersions.length > 0" (click)="saveProjectVersions()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>

            <table class="table-full-width">
              <span *ngFor="let version of projectVersions" class="full-row">
                <tr>
                  <td class="full-row">
                    <button (click)="deleteProjectVersion(version.versionId)" name="deleteProjectVersion_{{version.versionName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                  </td>
                </tr>
                <tr>
                    <label for="">*Version name</label>
                    <input [(ngModel)]="version.versionName" name="versionName_{{version.versionName}}" class="form-control" placeholder="{{version.versionName}}" type="text">
              </span>
            </table>

            <div class="mt-5">
              <button *ngIf="projectVersions && projectVersions.length > 0" (click)="saveProjectVersions()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>
          </form>

        <div>
          <h2 *ngIf="projectVersions && projectVersions.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
