<div [ngClass]="isWideView ? 'search-header-wide' : 'search-header-narrow'" class="card-header">
    <h3 class="mb-0">{{pageHeader}}</h3>
    <div [ngClass]="isWideView ? 'worktime-search-limit' : null">
      <div *ngIf="hasDates">
        <div class="worktime-search-field">
            <label for="">From:</label>
            <input [(ngModel)]="minDate" name="minDate" class="form-control rounded-0" placeholder="From" type="text" bsDatepicker [bsConfig]="bsConfig">
        </div>
        
        <div class="worktime-search-field">
          <label for="">To:</label>
          <input [(ngModel)]="maxDate" name="maxDate" class="form-control rounded-0" placeholder="To" type="text" bsDatepicker [bsConfig]="bsConfig">
        </div>
    </div>

    <div class="search-option-selection">
      <button class="worktime-search-field" (click)="search()" [disabled]="false" type="button" class="btn btn-info mb-0">Search</button>
    </div>

      <div *ngIf="projects && projects.length > 0" 
        [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
        class="btn-group project-search-header search-option-selection" 
        dropdown>
            <div class="project-search-dropdown">
              <button id="project-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                Projects <span class="caret"></span>
              </button>
              <ul id="project-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-override" role="menu" aria-labelledby="button-basic">
                <li *ngIf="hasSelectAllProjects" role="menuitem" (click)="setProject(0)">All projects</li>
                <li *ngFor="let project of projects" role="menuitem" (click)="setProject(project.projectId)">{{project.projectName}}</li>
              </ul>
            </div>
            <div class="selected-project search-selection">
              <h4>{{getProjectName()}}</h4>
            </div>
          </div>

          <div *ngIf="projectVersions && projectVersions.length > 0 && projectId && !hasOnlyProject"
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <div class="project-search-dropdown">
              <button id="version-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                Version <span class="caret"></span>
              </button>
              <ul id="version-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-override" role="menu" aria-labelledby="button-basic">
                <li *ngIf="hasSelectAll" role="menuitem" (click)="setVersion(0)">All versions</li>
                <li *ngFor="let version of projectVersions" role="menuitem" (click)="setVersion(version.versionId)">{{version.versionName}}</li>
              </ul>
            </div>
            <div class="selected-project search-selection">
              <h4>{{getVersionName()}}</h4>
            </div>
          </div>

          <div *ngIf="taskTypes && taskTypes.length > 0 && tasks && tasks.length > 0 && projectId && !hasOnlyProject" 
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <div class="project-search-dropdown">
              <button id="type-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                Task type <span class="caret"></span>
              </button>
              <ul id="type-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-override" role="menu" aria-labelledby="button-basic">
                <li *ngIf="hasSelectAll" role="menuitem" (click)="setType(0)">All types</li>
                <li *ngFor="let type of taskTypes" role="menuitem" (click)="setType(type.taskTypeId)">{{type.typeName}}</li>
              </ul>
            </div>
            <div class="selected-project search-selection">
              <h4>{{getTypeName()}}</h4>
            </div>
          </div>

          <div *ngIf="projectPriorites && projectPriorites.length > 0 && projectId && !hasOnlyProject" 
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <ng-multiselect-dropdown
            [placeholder]="'Priorities'"
            [settings]="priorityDropdownSettings"
            [data]="projectPriorites"
            [(ngModel)]="priorities"
            (onSelect)="onItemSelect($event, 'Priority')"
            (onSelectAll)="onSelectAll($event, 'Priority')"
            (onDeSelectAll)="onDeSelectAll($event, 'Priority')"
            (onDeSelect)="onItemDeSelect($event, 'Priority')"
            [class]="'multi-select-dropdown'"
            >
            </ng-multiselect-dropdown>
          </div>

          <div *ngIf="projectTags && projectTags.length > 0 && tasks && tasks.length > 0 && projectId && !hasOnlyProject && searchTags" 
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <ng-multiselect-dropdown
              [placeholder]="'Tags'"
              [settings]="tagDropdownSettings"
              [data]="projectTags"
              [(ngModel)]="tags"
              (onSelect)="onItemSelect($event, 'Tag')"
              (onSelectAll)="onSelectAll($event, 'Tag')"
              (onDeSelectAll)="onDeSelectAll($event, 'Tag')"
              (onDeSelect)="onItemDeSelect($event, 'Tag')"
              [class]="'multi-select-dropdown'"
            >
            </ng-multiselect-dropdown>
          </div>

          <div *ngIf="projectUsers && projectUsers.length > 1 && !hasOnlyProject && searchUsers" 
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <div class="project-search-dropdown">
              <button id="user-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                Users <span class="caret"></span>
              </button>
              <ul id="user-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-override" role="menu" aria-labelledby="button-basic">
                <li *ngIf="hasSelectAll" role="menuitem" (click)="setUser(0, null)">All users</li>
                <li *ngFor="let user of projectUsers" role="menuitem" (click)="setUser(user.userId, user.username)">{{user.username}}</li>
              </ul>
            </div>
            <div class="selected-project search-selection">
              <h4>{{getUsername()}}</h4>
            </div>
          </div>

          <div *ngIf="projectTeams && projectTeams.length > 0 && !hasOnlyProject && searchTeams" 
            [ngClass]="isWideView ? 'search-selection-container-wide' : 'search-selection-container-narrow'" 
            class="btn-group project-search-header search-option-selection" 
            dropdown>
            <div class="project-search-dropdown">
              <button id="team-button" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                aria-controls="dropdown-basic">
                Teams <span class="caret"></span>
              </button>
              <ul id="team-dropdown" *dropdownMenu class="dropdown-menu dropdown-menu-override" role="menu" aria-labelledby="button-basic">
                <li *ngIf="hasSelectAll" role="menuitem" (click)="setTeam(0, null)">All teams</li>
                <li *ngFor="let team of projectTeams" role="menuitem" (click)="setUser(team.teamId, team.name)">{{team.name}}</li>
              </ul>
            </div>
            <div class="selected-project search-selection">
              <h4>{{getTeamName()}}</h4>
            </div>
          </div>
    </div>
  </div>