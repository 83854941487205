import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { TaskTypes } from '../../_models/taskTypes';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-tasktypes-create',
  templateUrl: './tasktypes-create.component.html',
  styleUrls: ['./tasktypes-create.component.css']
})
export class TasktypesCreateComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  @Output() tasksTypeCreated: EventEmitter<any> = new EventEmitter();
  taskTypesCreateData = {taskTypeId: '', taskTypeName: '', billable: ''};
  taskTypeForm: UntypedFormGroup;
  taskTypes: TaskTypes[];
  activeTaskState;

  constructor(private router: Router, private fb: UntypedFormBuilder, private authService: AuthService, private alertify: AlertifyService,
    private taskService: TaskService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createTaskForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
      }
    }
  }

  createTaskForm() {
    this.taskTypeForm = this.fb.group({
      tasktypename: ['', Validators.required],
      billable: ['', '']
    });
  }

  saveTaskType() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const billable = this.taskTypeForm.value['billable'] ? 1 : 0;
    const tasktypeData = {
      typeName: this.taskTypeForm.value['tasktypename'],
      billable,
      projectId: this.projectId,
      organizationId: this.activeOrganizationId
    };

    this.taskService.saveTaskType(tasktypeData)
      .subscribe(
        res => {
            this.alertify.success('Saved type');
            this.tasksTypeCreated.emit();
        }, err => {
          this.alertify.error('Failed to save type');
      }
    );
  }

}
