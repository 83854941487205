import { Component, OnInit, TemplateRef } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { WorktimeService } from '../../_services/worktime.service';
import * as moment from 'moment';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup, FormControl, Validators, FormBuilder } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { Observable } from 'rxjs';
import { Project } from 'src/app/_models/project';
import { SearchParameters } from 'src/app/_models/searchParameters';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Worktime } from 'src/app/_models/worktime';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableWorktimeResult } from 'src/app/_models/expandableWorktimeResult';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { PagePermission } from 'src/app/_models/pagePermission';

@Component({
  selector: 'app-worktime-edit',
  templateUrl: './worktime-edit.component.html',
  styleUrls: ['./worktime-edit.component.css']
})
export class WorktimeEditComponent implements OnInit {
  worktimeEditData = [];
  userWorktimes = [];
  localFormat = 'YYYY-MM-DDTHH:mm:ss';
  minDate: any;
  maxDate: any;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  worktimeEditForm: UntypedFormGroup;
  userProjects = [];
  projectTasks = [];
  taskLists = [];
  projectId: number;
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  modalRef: BsModalRef;
  worktimeToCut: Worktime;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedStates: Array<ExpandableWorktimeResult> = new Array<ExpandableWorktimeResult>();
  isAllExpanded: boolean = false;
  activeOrganizationId: number = null;
  isSearching: boolean = false;
  isInitialized: boolean = false;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private worktimeService: WorktimeService, private router: Router, private modalService: BsModalService,
              private authService: AuthService, private alertify: AlertifyService, private projects: ProjectService,
              private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.own.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.view'
    };
    let pagePermission5: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.edit'
    };
    let pagePermission6: PagePermission = {
      value: 0,
      permissionstring: 'worktimes.others.create'
    };
    let pagePermission7: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
    this.pagePermissions.push(pagePermission5);
    this.pagePermissions.push(pagePermission6);
    this.pagePermissions.push(pagePermission7);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    }
  }

  toggleMode(): void {
    this.ismeridian = !this.ismeridian;
  }

  search(searchParameters: SearchParameters) {
    this.projectId = searchParameters.projectId ? searchParameters.projectId : undefined;
    this.minDate = searchParameters.minDate ? searchParameters.minDate : undefined;
    this.maxDate = searchParameters.maxDate ? searchParameters.maxDate : undefined;
    if (!this.isSearching && this.activeOrganizationId) {
      this.isSearching = true;
      this.searchWorktimes();
    }
  }

  changePage(pagination: PaginParameters) {
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    this.searchWorktimes();
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async getUserProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projects.getUsersProjects(this.activeOrganizationId)
    .subscribe(
      res => {
        this.userProjects = res.results;
        let index = 0;
        let isLast = false;

        if (!this.isInitialized) {
          this.isInitialized = true;
        }

        // this.userProjects.forEach(project => {
        //   index++;
        //   isLast = index >= this.userProjects.length;
        //   try {
        //     this.getUserTasks(project.projectId, isLast);
        //   } catch (error) {
        //     console.log('error: ', error);
        //   }
        // });
        resolve(true);
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to load projects');
            reject(new Error('failed'));
          }
        }
      }
    ));
  }

  async getUserTasks(projectId: number, isLast: boolean) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projects.getProjectTasks(this.activeOrganizationId, projectId)
    .subscribe(
      res => {
        this.addToProjectTasks(res.results, projectId, isLast);
        resolve(true);
      },
      err => {
        if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
            this.alertify.error('Failed to load tasks');
            reject(new Error('Failed'));
          }
        }
      }
    ));
  }

  addToProjectTasks(task, projectId: number, isLast: boolean) {
    this.taskLists.push([projectId, task]);
    this.projectTasks.push(task);
  }

  setProject(worktimeId: number, projectId: number) {
    this.worktimeEditData.find(w => w.id === worktimeId).projectId = projectId;
    this.worktimeEditData.find(w => w.id === worktimeId).projectName =
      this.userProjects.find(up => up.projectId === projectId).projectName;
  }

  setTask(worktimeId: number, taskId: number) {
    this.projectTasks.forEach(taskList => {
      const taskName = taskList.find(t => t.taskId === taskId)?.taskName;

      if (taskId === 0) {
        this.worktimeEditData.find(w => w.id === worktimeId).taskId = 0;
        this.worktimeEditData.find(w => w.id === worktimeId).taskName = '';
      }
      else if (taskName) {
        this.worktimeEditData.find(w => w.id === worktimeId).taskId = taskId;
        this.worktimeEditData.find(w => w.id === worktimeId).taskName = taskName;
      }
    });
  }

  getProjectTaskList(projectId: number) {
    let tasks = [];
    this.taskLists.forEach(task => {
      if (task[0] === projectId) {
        tasks = task[1];
      }
    });
    return tasks;
  }

  async searchWorktimes() {
    this.spinner.show();
    
    const formatedMinDate = moment(this.minDate).format('YYYY-MM-DD');
    const formatedMaxDate = moment(this.maxDate).format('YYYY-MM-DD');

    if (formatedMinDate && !moment(formatedMinDate).isValid()) {
      this.alertify.error('Invalid date');
      return;
    }
    if (formatedMaxDate && !moment(formatedMaxDate).isValid()) {
      this.alertify.error('Invalid date');
      return;
    }
    if (formatedMinDate >= formatedMaxDate) {
      this.alertify.error('mindate must be earlier than maxdate');
      return;
    }

    await this.getUserProjects();

    let index = 0;
    let isLast = false;

    for (let project of this.userProjects) {
      index++;
      isLast = index >= this.userProjects.length;
      try {
        await this.getUserTasks(project.projectId, isLast);
      } catch (error) {
        console.log('error: ', error);
      }

      if (isLast) {
        await this.getUserWorktimes();
      }
    }
  }

  async getUserWorktimes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.expandedStates = new Array<ExpandableWorktimeResult>();
    this.worktimeEditData = [];
    this.userWorktimes = [];

    const searchParams = {
      minDate: this.minDate,
      maxDate: this.maxDate,
      projectId: this.projectId,
      organizationId: this.activeOrganizationId,
      perPage: this.perPage,
      pageNumber: this.pageNumber
    };

    await new Promise((resolve, reject) => this.worktimeService.loadUsersWorktimes(searchParams)
      .subscribe(
        res => {
          this.userWorktimes = res.results;
          this.resultCount = res.count[0]['totalCount'];
          for (let i = 0; i < this.userWorktimes.length; i++) {
            let currentTask;
            if (this.userWorktimes[i].taskId !== 0) {
              for (let j = 0; j < this.taskLists.length; j++) {
                if (this.taskLists[j][1].length > 0 && this.taskLists[j][0] === this.userWorktimes[i].projectId) {
                  currentTask = this.projectTasks[j];
                  continue;
                }
              }
            }

            const projectName = this.userProjects
              .find(p => p.projectId === this.userWorktimes[i].projectId)?.projectName;
            const taskName = currentTask
              ?.find(t => t.projectId === this.userWorktimes[i].projectId && t.taskId === this.userWorktimes[i].taskId)?.taskName;

            const worktime = {
              id: this.userWorktimes[i].id,
              userId: this.userWorktimes[i].userId,
              projectId: this.userWorktimes[i].projectId,
              projectName: projectName ? projectName : '',
              taskId: this.userWorktimes[i].taskId,
              taskName: taskName != null ? taskName : '',
              startDate: moment(this.userWorktimes[i].startTime).format('YYYY-MM-DD'),
              startTime: moment(this.userWorktimes[i].startTime).format('YYYY-MM-DD HH:mm'),
              endDate: moment(this.userWorktimes[i].endTime).format('YYYY-MM-DD'),
              endTime: moment(this.userWorktimes[i].endTime).format('YYYY-MM-DD HH:mm'),
              comment: this.userWorktimes[i].comment
            };
            this.worktimeEditData.push(worktime);
          }

          this.worktimeEditData.sort((a, b) => {
            a = moment(a.startDate + ' ' + moment(a.startTime).format('HH:mm')).format(this.localFormat);
            b = moment(b.startDate + ' ' + moment(b.startTime).format('HH:mm')).format(this.localFormat);
            return a > b ? -1 : a < b ? 1 : 0;
          });

          this.worktimeEditData.forEach(worktime => {
            this.expandedStates.push(
              {
                worktime,
                isActive: this.isAllExpanded
              }
            );
          });
          this.spinner.hide();
          this.isSearching = false;
          resolve(true);
        },
        err => {
          this.spinner.hide();
          this.isSearching = false;
          reject(new Error('Failed'));
        } 
      ));
  }

  saveWorkTimes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let editedWorktimes = [];

    for (let i = 0; i < this.worktimeEditData.length; i++) {
      const formatedStartDate = moment(this.worktimeEditData[i].startDate).format('YYYY-MM-DD');
      const formattedStartTime =  moment(this.worktimeEditData[i].startTime).format('HH:mm:ss');
      const formatedEndtDate = this.isValidDate(this.worktimeEditData[i].endDate) ? moment(this.worktimeEditData[i].endDate).format('YYYY-MM-DD') : '0000-00-00';
      const formattedEndTime = this.isValidDate(this.worktimeEditData[i].endDate) ? moment(this.worktimeEditData[i].endTime).format('HH:mm:ss') : '00:00:00';

      const worktimeFormatedData = {
        id: this.worktimeEditData[i].id,
        userId: this.worktimeEditData[i].userId,
        projectId: this.worktimeEditData[i].projectId,
        taskId: this.worktimeEditData[i] ? this.worktimeEditData[i].taskId : 0,
        startTime: moment(formatedStartDate + ' ' + formattedStartTime).format(this.localFormat),
        endTime: moment(formatedEndtDate + ' ' + formattedEndTime).format(this.localFormat),
        comment: this.worktimeEditData[i].comment
      };

      if (!moment(worktimeFormatedData.startTime).isValid()) {
        this.alertify.error('Invalid start time');
        return;
      }
      if (!moment(worktimeFormatedData.startTime).isValid()) {
        this.alertify.error('Invalid end time');
        return;
      }
      if (worktimeFormatedData.startTime >= worktimeFormatedData.endTime) {
        this.alertify.error('Start time has to be greater than end time');
        return;
      }

      editedWorktimes.push(worktimeFormatedData);
    }

    const worktimeData =
    {
      userId: this.authService.decodedToken.id,
      worktimeData: editedWorktimes
    };

    this.worktimeService.saveWorktimes(worktimeData, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved changes');
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to save changes');
            }
          }
        }
      );
  }

  deleteWorkTime(id: number, projectId: number) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.alertify.confirm('Are you sure you want to delete this worktime', () => {
      this.worktimeService.deleteWorktimes(id, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted worktime');
          this.searchWorktimes();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete worktime');
            }
          }
        }
      );
    });
  }

  cutWorktime(template: TemplateRef<any>, worktimeToCut) {
    this.worktimeToCut = worktimeToCut;
    this.modalRef = this.modalService.show(template);
  }

  canCutWorktime(worktime) {
    if (worktime && worktime.endTime && moment(worktime.endTime).isValid()) {
      return true;
    }
    return false;
  }

  closeCutModal() {
    this.modalRef.hide();
  }

  expandRow(row: ExpandableWorktimeResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

  getValidTime(timeToCheck: Date) {
    if (!timeToCheck) {
      return;
    }
    if (moment(timeToCheck).isValid()) {
      return moment(timeToCheck).format('DD-MM-YYYY HH:mm');
    }
    else {
      return 'No endtime set';
    }
  }

  isValidDate(d) {
    const formattedDate = new Date(moment(d).format('YYYY-MM-DD HH:mm'));
    return formattedDate instanceof Date && !isNaN(Number(formattedDate));
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}