import { Component, OnInit, OnChanges, SimpleChanges, Input } from '@angular/core';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { PriorityService } from 'src/app/_services/priority.service';
import { Router } from '@angular/router';
import { Priority } from 'src/app/_models/priority';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-priority-edit',
  templateUrl: './priority-edit.component.html',
  styleUrls: ['./priority-edit.component.css']
})
export class PriorityEditComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number = null;
  
  projectPriorities: Priority[];
  isSearching: boolean = false;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";

  constructor(private authService: AuthService, private alertify: AlertifyService, private priorityService: PriorityService, 
              private router: Router, private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.spinner.show();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
        this.spinner.show();
        await this.searchPriorities();
        this.spinner.hide();
      }
    }
  }

  async searchPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.projectPriorities = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get priorities');
          reject(new Error('failed'));
      }
    ));
  }

  async savePriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let editedPrioritiesData = [];

    for (let i = 0; i < this.projectPriorities.length; i++) {
      const priorityFormatedData = {
        priorityId: this.projectPriorities[i].priorityId,
        priorityName: this.projectPriorities[i].priorityName,
        projectId: this.projectPriorities[i].projectId
      };

      editedPrioritiesData.push(priorityFormatedData);
    }

    const prioritiesData = {
      prioritiesData: editedPrioritiesData
    };

    await new Promise((resolve, reject) => this.priorityService.editPriorities(prioritiesData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved priority changes');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save priority changes');
        reject(new Error('Failed'));
      }
    ));
  }

  async deletePriority(priorityId: number) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this priority', () => {
      this.priorityService.deletePriority(priorityId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted priority');
          this.searchPriorities();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete priority');
              reject(new Error('Failed'));
            }
          }
        }
      );
    }));
  }

}
