<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <app-search *ngIf="(checkPagePermissions('worktimes.reporting.own.view') || checkPagePermissions('worktimes.reporting.others.view')) 
        && checkPagePermissions('project.view')"
         pageHeader="Worktime reporting" [hasSelectAll]=true [searchUsers]=true (searchParametersChanged)="search($event)"></app-search>

        <div class="card-body" *ngIf="(checkPagePermissions('worktimes.reporting.own.view') || checkPagePermissions('worktimes.reporting.others.view')) 
        && checkPagePermissions('project.view')">
          <form class="form">
            <table class="summary-table" style="width: 100%;">
              <tr>
                <td style="width: 50%;">
                  <label for="">Total workhours on period:</label>
                </td>
                <td>
                  <p
                    [ngClass]="{
                    'negative-day': totalHoursOnPeriod < requiredHoursOnPeriod - acceptedFlexOnPeriod,
                    'positive-day': totalHoursOnPeriod > requiredHoursOnPeriod + acceptedFlexOnPeriod,
                    'full-day': totalHoursOnPeriod >= requiredHoursOnPeriod - acceptedFlexOnPeriod && totalHoursOnPeriod <= requiredHoursOnPeriod + acceptedFlexOnPeriod}"
                    class="rounded-0"
                    name="totalWorkhours"
                    type="text">{{totalHoursOnPeriod | number:'1.1-2'}}
                  </p>
                </td>
              </tr>
              <tr *ngIf="userDailyHours && userDailyHours.length == 1">
                <td style="width: 50%;">
                  <label for="">Required workhours on period:</label>
                </td>
                <td>
                  <p
                    [ngClass]="{
                    'negative-day': totalHoursOnPeriod < requiredHoursOnPeriod - acceptedFlexOnPeriod,
                    'positive-day': totalHoursOnPeriod > requiredHoursOnPeriod + acceptedFlexOnPeriod,
                    'full-day': totalHoursOnPeriod >= requiredHoursOnPeriod - acceptedFlexOnPeriod && totalHoursOnPeriod <= requiredHoursOnPeriod + acceptedFlexOnPeriod}"
                    class="rounded-0"
                    name="requiredWorkhours"
                    type="text">{{requiredHoursOnPeriod | number:'1.1-2'}}
                  </p>
                </td>
              </tr>
              <tr>
                <td style="width: 50%;">
                  <label for="">Average workhours on period:</label>
                </td>
                <td>
                  <p
                    [ngClass]="{
                    'negative-day': averageHoursOnPeriod < expectedWorkHours - acceptedFlexOnPeriod,
                    'positive-day': averageHoursOnPeriod > expectedWorkHours + acceptedFlexOnPeriod,
                    'full-day': averageHoursOnPeriod >= expectedWorkHours - acceptedFlexOnPeriod && averageHoursOnPeriod <= expectedWorkHours + acceptedFlexOnPeriod}"
                    class="rounded-0"
                    name="averageWorkhours"
                    type="text">{{averageHoursOnPeriod | number:'1.1-2'}}
                  </p>
                </td>
              </tr>
            </table>

            <table class="worktime-report-table">
              <tr>
                <th class="worktime-report-header"><label for="">Date</label></th>
                <th class="worktime-report-header"><label for="">Workhour</label></th>
                <th class="worktime-report-header"><label for="">Expected hours</label></th>
                <th class="worktime-report-header"><label for="">Hour balance</label></th>
                <th class="worktime-report-header"><label for="">Username</label></th>
              </tr>
              <ng-container *ngFor="let month of months">
                <ng-container *ngFor="let week of month.weeks">
                  <tr>
                    <td colspan="3" class="month-summary-row">
                      {{month.month}} - total: {{month.monthlySummary | number:'1.1-2'}}
                    </td>
                    <td colspan="2" class="week-summary-row">
                      week: {{week.week}} - total: {{week.weeklySummary | number:'1.1-2'}}
                    </td>
                  </tr>
                  <ng-container *ngFor="let hours of worktimeReportData">
                  <tr *ngIf="checkYearMonth(month.month, week.week, hours.year, hours.month, hours.week)">
                    <td>
                      <p
                        [ngClass]="{
                        'negative-day': hours.totalWorkHours < hours.dailyHours - acceptedFlexOnPeriod,
                        'positive-day': hours.totalWorkHours > hours.dailyHours + acceptedFlexOnPeriod,
                        'full-day': hours.totalWorkHours >= hours.dailyHours - acceptedFlexOnPeriod && hours.totalWorkHours <= hours.dailyHours + acceptedFlexOnPeriod}"
                        type="text">
                        {{hours.workday}}
                      </p>
                    </td>
                    <td>
                      <p
                        [ngClass]="{
                        'negative-day': hours.totalWorkHours < hours.dailyHours - acceptedFlexOnPeriod,
                        'positive-day': hours.totalWorkHours > hours.dailyHours + acceptedFlexOnPeriod,
                        'full-day': hours.totalWorkHours >= hours.dailyHours - acceptedFlexOnPeriod && hours.totalWorkHours <= hours.dailyHours + acceptedFlexOnPeriod}"
                        type="text">
                        {{hours.totalWorkHours | number:'1.1-2'}}
                      </p>
                    </td>
                    <td>
                      <p
                        [ngClass]="{
                        'negative-day': hours.totalWorkHours < hours.dailyHours - acceptedFlexOnPeriod,
                        'positive-day': hours.totalWorkHours > hours.dailyHours + acceptedFlexOnPeriod,
                        'full-day': hours.totalWorkHours >= hours.dailyHours - acceptedFlexOnPeriod && hours.totalWorkHours <= hours.dailyHours + acceptedFlexOnPeriod}"
                        type="text">
                        {{hours.dailyHours | number:'1.1-2'}}
                      </p>
                    </td>
                    <td>
                      <p
                        [ngClass]="{
                        'negative-day': hours.totalWorkHours < hours.dailyHours - acceptedFlexOnPeriod,
                        'positive-day': hours.totalWorkHours > hours.dailyHours + acceptedFlexOnPeriod,
                        'full-day': hours.totalWorkHours >= hours.dailyHours - acceptedFlexOnPeriod && hours.totalWorkHours <= hours.dailyHours + acceptedFlexOnPeriod}"
                        type="text">
                        {{hours.missingHours | number:'1.1-2'}}
                      </p>
                    </td>
                    <td>
                      <p
                        [ngClass]="{
                        'negative-day': hours.totalWorkHours < hours.dailyHours - acceptedFlexOnPeriod,
                        'positive-day': hours.totalWorkHours > hours.dailyHours + acceptedFlexOnPeriod,
                        'full-day': hours.totalWorkHours >= hours.dailyHours - acceptedFlexOnPeriod && hours.totalWorkHours <= hours.dailyHours + acceptedFlexOnPeriod}"
                        type="text">
                        {{hours.username}}
                      </p>
                    </td>
                  </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </table>
          </form>
          <div>
            <h2 *ngIf="worktimeReportData.length == 0">No data for selected time period</h2>
          </div>
          <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
          <div *ngIf="worktimesByTaskType && worktimesByTaskType.length" class="separator">
            <button name="taskTypeChartTypeButton" type="button" class="btn btn-info mb-3 ml-2" (click)="changeTaskTypeChart()">{{!showTasktypesBarChart ? 'Bar chart' : 'Pie chart'}}</button>
            <div echarts [options]="chartOption1" class="demo-chart"></div>
          </div>
          <div *ngIf="worktimesByProjects && worktimesByProjects.length" class="separator">
            <button name="projectChartTypeButton" type="button" class="btn btn-info mb-3 ml-2" (click)="changeProjectChart()">{{!showProjectsBarChart ? 'Bar chart' : 'Pie chart'}}</button>
            <div echarts [options]="chartOption2" class="demo-chart"></div>
          </div>
        </div>
      </div>
    </div>
  </div>

