import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ProjectService } from '../../_services/project.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectType } from '../../_models/projectType';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { TaskTypes } from '../../_models/taskTypes';
import { TaskState } from '../../_models/taskState';
import { TaskService } from '../../_services/task.service';
import { ProjectVersion } from '../../_models/projectVersion';
import { Priority } from 'src/app/_models/priority';
import { PriorityService } from 'src/app/_services/priority.service';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { Tag } from 'src/app/_models/tag';
import { TagService } from 'src/app/_services/tag.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Project } from 'src/app/_models/project';
import { ExpandableTaskResult } from 'src/app/_models/expandableTaskResult';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { rejects } from 'assert';

@Component({
  selector: 'app-task-edit',
  templateUrl: './task-edit.component.html',
  styleUrls: ['./task-edit.component.css']
})
export class TaskEditComponent implements OnInit {
  @Input() activeOrganizationId: number = null;

  userProjects = Array<Project>();
  projectTasks = [];
  minDate;
  maxDate;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  taskTypes: TaskTypes[];
  taskStates: TaskState[];
  projectId: number;
  projectVersions: ProjectVersion[];
  priorities: Priority[];
  relationTable: string = 'Tasks';
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  projectTags: Array<Tag> = new Array<Tag>();
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  dataLoading = {
    isLoading1: true,
    isLoading2: true,
    isLoading3: true,
    isLoading4: true,
    isLoading5: true,
    isLoading6: true,
    isLoading7: true,
  };

  expandedStates: Array<ExpandableTaskResult> = new Array<ExpandableTaskResult>();
  isAllExpanded: boolean = false;

  constructor(private authService: AuthService, private alertify: AlertifyService, private priorityService: PriorityService,
              private projectService: ProjectService, private router: Router, private taskService: TaskService, 
              private tagService: TagService, private spinner: NgxSpinnerService,
              private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.projectId = Number(localStorage.getItem('currentProject'));
    // await this.searchProjects();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        if (!this.activeOrganizationId) {
          return;
        }
        this.spinner.show();
        await this.searchProjects();
        await this.getAllData();
      }
    }
  }

  async getAllData() {
    if (!this.projectId) {
      return;
    }
    this.spinner.show();
    try {
      await this.getTasks();
      await this.getTaskStates();
      await this.getTaskTypes();
      await this.getProjectVersions();
      await this.getPriorities();
      await this.getProjectTags();
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async changePage(pagination: PaginParameters) {
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    if (!this.projectId || !this.activeOrganizationId) {
      // this.alertify.error('Failed to get tasks');
      return;
    }
    await this.getTasks();
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async searchProjects(getData?: boolean) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.userProjects = new Array<Project>();
    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
            this.userProjects = res.results;
            this.userProjects.forEach(project => {
              if (project.projectDeadline) {
                project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
              }
            });
            this.projectId = this.userProjects.find(up => up.projectId === this.projectId) ? this.projectId : this.userProjects[0].projectId;

            if (getData) {
              this.getAllData();
            }

            resolve(true);
        }, err => {
          this.alertify.error('Failed to load projects');
          reject(new Error('failed'));
      }
    ));
  }

  async getTasks() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      // this.alertify.error('Failed to load tasks');
      this.dataLoading.isLoading2 = false;
      this.isSpinnerVisible();
      return;
    }
    this.expandedStates = new Array<ExpandableTaskResult>();
    this.projectTasks = [];
    await new Promise((resolve, reject) => this.taskService.getProjectTasks(this.activeOrganizationId, this.projectId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
            this.projectTasks = res.results;
            this.projectTasks.forEach(task => {
              this.expandedStates.push({
                task,
                isActive: this.isAllExpanded
              });
            });
            this.dataLoading.isLoading2 = false;
            this.resultCount = res.count[0]['totalCount'];
            this.isSpinnerVisible();
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get tasks');
          this.dataLoading.isLoading2 = false;
          this.isSpinnerVisible();
          reject(new Error('failed'));
      }
    ));
  }

  async getTaskTypes() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load task types');
      this.dataLoading.isLoading3 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.taskService.getTaskTypes(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskTypes = res;
            this.dataLoading.isLoading3 = false;
            this.isSpinnerVisible();
            resolve(true);
        }, err => {
          this.alertify.error('Failed to get task types');
          this.dataLoading.isLoading3 = false;
          this.isSpinnerVisible();
          reject(new Error('failed'));
      }
    ));
  }

  async getTaskStates() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load task states');
      this.dataLoading.isLoading4 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) =>  this.taskService.getTaskStates(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.taskStates = res;
          this.dataLoading.isLoading4 = false;
          this.isSpinnerVisible();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get task states');
          this.dataLoading.isLoading4 = false;
          this.isSpinnerVisible();
          reject(new Error('failed'));
      }
    ));
  }

  getTaskTypeFromTypeId(typeId: number) {
    const type = this.taskTypes.find(tt => tt.taskTypeId === typeId);
    if (type) {
      return type.typeName;
    }
    else {
      return null;
    }
  }

  getTaskStateFromStateId(stateId: number) {
    const state = this.taskStates.find(ts => ts.stateId === stateId);
    if (state) {
      return state.stateName;
    }
    else {
      return null;
    }
  }

  getTaskVersionFromVersionId(versionId: number) {
    if (!versionId || (!this.projectVersions || this.projectVersions.length === 0)) {
      return null;
    }
    const version = this.projectVersions.find(pv => pv.versionId === versionId);
    if (version) {
      return version.versionName;
    }
    else {
      return null;
    }
  }

  getTaskPriorityFromPriorityId(priorityId: number) {
    if (!priorityId || (!this.priorities || this.priorities.length === 0)) {
      return null;
    }
    const priority = this.priorities.find(p => p.priorityId === priorityId);
    if (priority) {
      return priority.priorityName;
    }
    else {
      return null;
    }
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load project versions');
      this.dataLoading.isLoading5 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          if (res.length > 0) {
            this.projectVersions = res;
          }
          else {
            this.projectVersions = [];
          }
          this.dataLoading.isLoading5 = false;
          this.isSpinnerVisible();
          resolve(true);
        }, err => {
          this.dataLoading.isLoading5 = false;
          this.isSpinnerVisible();
          this.alertify.error('Failed to get project versions');
          reject(new Error('failed'));
      }
    ));
  }

  async getPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load priorities');
      this.dataLoading.isLoading6 = false;
      this.isSpinnerVisible();
      return;
    }

    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.priorities = res;
          this.dataLoading.isLoading6 = false;
          this.isSpinnerVisible();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load priorities');
          this.dataLoading.isLoading6 = false;
          this.isSpinnerVisible();
          reject(new Error('failed'));
      }
    ));
  }

  setType(taskId: number, typeId: number) {
    this.projectTasks.find(t => t.taskId === taskId).taskType = typeId;
  }

  setState(taskId: number, stateId: number) {
    this.projectTasks.find(t => t.taskId === taskId).taskState = stateId;
  }

  setVersion(taskId: number, versionId: number) {
    this.projectTasks.find(t => t.taskId === taskId).versionId = versionId;
  }

  setPriority(taskId: number, priorityId: number) {
    this.projectTasks.find(t => t.taskId === taskId).priorityId = priorityId;
  }

  setProject(projectId: number) {
    if (this.userProjects.find(p => p.projectId === projectId)){
      this.projectId = projectId;
      localStorage.setItem('currentProject', this.projectId.toString());

      this.getAllData();
    }
  }

  getProjectName(): string {
    if (this.projectId === 0) {
      return;
    }

    return this.userProjects.find(p => p.projectId === this.projectId)?.projectName;
  }

  async saveTasks() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let editedTaskData = [];

    for (let i = 0; i < this.projectTasks.length; i++) {
      const time = '00:00';
      let formatedDeadline;
      const deadline = this.projectTasks[i].deadline;
      if (!deadline) {
        formatedDeadline = null;
      }
      else {
        formatedDeadline = moment(deadline + time).format('YYYY-MM-DD HH:DD');
      }

      const taskFormatedData = {
        taskId: this.projectTasks[i].taskId,
        projectId: this.projectTasks[i].projectId,
        taskName: this.projectTasks[i].taskName,
        taskOwner: this.projectTasks[i].taskOwner,
        taskType: this.projectTasks[i].taskType,
        taskState: this.projectTasks[i].taskState,
        versionId: this.projectTasks[i].versionId && this.projectTasks[i].versionId != 0 ? this.projectTasks[i].versionId : null,
        priorityId: this.projectTasks[i].priorityId && this.projectTasks[i].priorityId != 0 ? this.projectTasks[i].priorityId : null,
        deadline: formatedDeadline,
        description: this.projectTasks[i].description,
        estimatedDays: this.projectTasks[i].estimatedDays,
        estimatedHours: this.projectTasks[i].estimatedHours,
        estimatedMinutes: this.projectTasks[i].estimatedMinutes
      };

      if (formatedDeadline != null && !moment(taskFormatedData.deadline).isValid()) {
        this.alertify.error('Invalid deadline');
        return;
      }

      editedTaskData.push(taskFormatedData);
    }

    const taskData = {
      taskData: editedTaskData
    };

    await new Promise((resolve, reject) => this.taskService.editTasks(taskData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved task changes');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save task changes');
        reject(new Error('Failed'));
      }
    ));
  }

  async deleteTask(taskId: number) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this task', () => {
      this.taskService.deleteTask(taskId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted task');
          this.searchProjects(true);
          resolve(true);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete task');
              reject(new Error('Failed'));
            }
          }
        }
      );
    }));
  }

  async getProjectTags() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      this.alertify.error('Failed to load tags');
      this.dataLoading.isLoading7 = false;
      this.isSpinnerVisible();
      return;
    }
    this.projectTags = new Array<Tag>();

    await new Promise((resolve, reject) => this.tagService.getProjectTags(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          res;
          let test = res;
          test.forEach(element => {
            this.projectTags.push(element);
          });
          this.dataLoading.isLoading7 = false;
          this.isSpinnerVisible();
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load tags');
        this.dataLoading.isLoading7 = false;
        this.isSpinnerVisible();
        reject(new Error('failed'));
      }
    ));
  }

  isSpinnerVisible() {
    if (!this.dataLoading.isLoading1
      || !this.dataLoading.isLoading2
      || !this.dataLoading.isLoading3
      || !this.dataLoading.isLoading4
      || !this.dataLoading.isLoading5
      || !this.dataLoading.isLoading6
      || !this.dataLoading.isLoading7) {
      this.spinner.hide();
    }
  }

  expandRow(row: ExpandableTaskResult) {
    if (!row) {
      return;
    }
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

  getSimplifiedDeadline(row: ExpandableTaskResult) {
    if (!row || !row.task || !row.task.deadline) {
      return;
    }
    return moment(row.task.deadline).format('DD-MM-YYYY');
  }
}
