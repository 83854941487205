import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import * as moment from 'moment';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { ProjectService } from '../../_services/project.service';
import { Project } from '../../_models/project';
import { ProjectType } from '../../_models/projectType';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { Team } from 'src/app/_models/team';
import { TeamService } from 'src/app/_services/team.service';

@Component({
  selector: 'app-project-create',
  templateUrl: './project-create.component.html',
  styleUrls: ['./project-create.component.css']
})
export class ProjectCreateComponent implements OnInit {
  @Input() activeOrganizationId: number = null;
  @Output() projectCreated: EventEmitter<any> = new EventEmitter();

  projectCreateData = {projectId: '', projectName: '', projectType: '', projectDeadline: '', ownerId: ''};
  localFormat = 'YYYY-MM-DD HH:mm:ss';
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  projectCreateForm: UntypedFormGroup;
  userProjects = [];
  projectTypes: ProjectType[];
  activeProjectType;
  activeTeam;
  organizationTeams: Array<Team> = new Array<Team>();
  perPage: number = 10;
  pageNumber: number = 0;

  constructor(private projectService: ProjectService, private router: Router, private fb: UntypedFormBuilder, private teamService: TeamService,
              private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    // this.getProjectTypes();
    await this.createProjectForm();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        await this.getProjectTypes();
        await this.searchTeams();
      }
    }
  }

  createProjectForm() {
    this.projectCreateForm = this.fb.group({
      projectname: ['', Validators.required],
      projecttype: [{value: '', disabled: true}, Validators.required],
      projecttypeid: ['', Validators.required],
      teamname: [{value: '', disabled: true}, null],
      teamid: ['', null],
      projectdeadline: ['', ''],
      description: ['', '']
    });
  }

  async getProjectTypes() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.projectService.getProjectTypes(this.activeOrganizationId)
      .subscribe(
        res => {
            this.projectTypes = res;
            resolve(true);
        }, err => {
          reject(new Error('failed'));
          this.alertify.error('Failed to get project types');
      }
    ));
  }

  async searchTeams() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.teamService.getTeams(this.activeOrganizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.organizationTeams = res.results;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load teams');
          reject(new Error('failed'));
      }
    ));
  }

  setProjectType(typeId: number, typeName: string) {
    this.projectCreateForm.patchValue({projecttype: typeName});
    this.projectCreateForm.patchValue({projecttypeid: typeId});
    this.activeProjectType = this.projectTypes.find(pt => pt.projectTypeId === typeId);
  }

  setTeam(teamId: number, teamName: string) {
    this.projectCreateForm.patchValue({teamname: teamName});
    this.projectCreateForm.patchValue({teamid: teamId});
    this.activeTeam = this.organizationTeams.find(t => t.teamId === teamId);
  }

  saveProject() {
    let deadline = this.projectCreateForm.value['deadline'];
    if (!deadline) {
      deadline = null;
    }
    else {
      moment(deadline).format(this.localFormat);
    }

    const projectData = {
      ownerId: this.authService.decodedToken.id,
      projectName: this.projectCreateForm.value['projectname'],
      projectType: this.projectCreateForm.value['projecttypeid'],
      deadline,
      description: this.projectCreateForm.value['description'],
      organizationId: this.activeOrganizationId,
      teamId: this.projectCreateForm.value['teamid']
    };

    if (deadline != null && !moment(projectData.deadline).isValid()) {
      this.alertify.error('Invalid deadline');
      return;
    }

    this.projectService.saveProject(projectData)
      .subscribe(
        res => {
          this.projectCreated.emit();
          this.projectCreateForm.reset();
          this.activeProjectType = null;
          this.alertify.success('Saved project');
        }, err => {
          this.alertify.error('Failed to save project');
      }
    );
  }

}
