import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { LoggedWorktime } from '../_models/loggedWorktime';
import { Worktime } from '../_models/worktime';
import { PaginatedResults } from '../_models/paginatedResults';

@Injectable({
  providedIn: 'root'
})
export class WorktimeService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  saveWorktime(worktimeData) {
    return this.http.post<any>(this.baseUrl + 'worktimes/add', worktimeData);
  }

  saveWorktimes(worktimeData, organizationId: number) {
    worktimeData.userId = this.authService.currentUser.id;
    worktimeData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'worktimes/edit', worktimeData);
  }

  getWorktimesReport(getReportData) {
    return this.http.post<any>(this.baseUrl + 'worktimes/reporting', getReportData);
  }

  deleteWorktimes(id: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.decodedToken.id,
        organizationId
      },
    };

    return this.http.delete<any>(this.baseUrl + 'worktimes/delete/' + id, options);
  }

  loadUsersWorktimes(searchParameters) {
    const userId = this.authService.decodedToken.id;
    return this.http.post<PaginatedResults>(this.baseUrl + 'worktimes/get/' + userId, searchParameters);
  }

  getTaskLoggedWorktime(projectId: number, taskId: number, organizationId: number) {
    const body = {
      userId: this.authService.currentUser.id,
      projectId,
      organizationId
    }
    return this.http.post<LoggedWorktime>(this.baseUrl + 'task/worktimes/' + taskId, body);
  }

  cutWorktime(original: Worktime, edited: Worktime, organizationId: Number) {
    original.userId = this.authService.currentUser.id;
    edited.userId = this.authService.currentUser.id;

    const body = {
      original,
      edited,
      organizationId
    }
    
    return this.http.post<any>(this.baseUrl + 'worktimes/cut', body);
  }
}
