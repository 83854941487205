<div>
    <form class="form">
    <table>
        <div *ngFor="let todoItem of todoListItems">
            <tr>
                <td>
                    <label for="">*Message</label>
                    <input [(ngModel)]="todoItem.message" 
                        id="message_{{todoItem.message}}" 
                        name="message{{todoItem.message}}" 
                        class="form-control" 
                        placeholder="{{todoItem.message}}" 
                        type="text">
                <div class="invalid-feedback worktime-date-field">Message is required</div>
                </td>
                <td>
                    <div>
                        <label for="">Done</label>
                        <input [(ngModel)]="todoItem.isChecked" 
                        name="isChecked{{todoItem.isChecked}}" 
                        class="form-control" 
                        placeholder="{{todoItem.isChecked}}" 
                        type="checkbox">
                    </div>
                </td>
                <td>
                    <div>
                        <label for="">Controls</label>
                    </div>
                    <div>
                        <button name="deleteItemButton_{{todoItem.id}}" (click)="deleteItem(todoItem.id, todoId)" type="button" class="btn btn-danger">Delete</button>
                    </div>
                </td>
            </tr>
        </div>
    </table>
        <div class="item-controls">
            <button name="addItemButton" (click)="addItem()" type="button" class="btn btn-info">Add item</button>
            <button name="saveItemsButton" (click)="saveItems()" type="button" class="btn btn-success">Save items</button>
        </div>
    </form>
</div>
