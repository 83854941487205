<label for="">Tags:</label>
<div class="half-row">
    <div *ngIf="tagControls">
        <p class="tag-link tag-left" (click)="clearTag()">clear</p>
        <p class="tag-link tag-right" (click)="saveTag()">save</p>
    </div>
    <div *ngIf="!tagControls">
        <p class="tag-link tag-left" (click)="toggleTagControls(true)">Add tag</p>
    </div>
    <div class="tag-input">
        <input
            *ngIf="tagControls"
            [(ngModel)]="searchTerm"
            class="form-control"
            placeholder="Type tag name">
        <div *ngIf="tagControls && searchTerm" class="matching-tags">
            <div class="reset-float match" *ngFor="let match of getMatches(searchTerm)">
                <span (click)="onSelect2(match)">{{match.tagName}}</span>
            </div>
        </div>
    </div>
     
    <div class="alert alert-danger" role="alert" *ngIf="errorMessage">
      {{ errorMessage }}
    </div>
</div>
<div class="reset-float">
    <span class="tag-bubble" *ngFor="let tag of entityTags">
        {{tag.tagName}}
        <span class="tag-delete" (click)="deleteTag(tag.tagId)">x</span>
    </span>
</div>