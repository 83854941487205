<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new organization</h3>
        </div>

        <div class="card-body">
          <form [formGroup]="organizationCreateForm" class="form">
            <table style="width: 100%;">
                <tr>
                    <td colspan="2">
                        <label for="">*Organization name</label>
                        <input 
                          [ngClass]="{'is-invalid': organizationCreateForm.get('organizationname').errors && organizationCreateForm.get('organizationname').touched}"
                          formControlName="organizationname"
                          name="organizationname"
                          type="text"
                          class="form-control">
                        <div class="invalid-feedback worktime-date-field">Organization name is required</div>
                    </td> 
                </tr>
                <tr>
                  <td colspan="2">
                    <label for="">Description</label>
                    <textarea 
                      formControlName="description"
                      name="description"
                      type="text"
                      class="form-control">
                    </textarea>
                  </td>
                </tr>
              <tr class="bottom-row">
                <td>
                  <button [disabled]="!organizationCreateForm.valid" name="createOrganizationButton" (click)="saveOrganisation()" type="button" class="btn btn-success float-left">Save</button>
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </div>
  </div>

