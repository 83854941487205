<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit task states</h3>
        </div>
        
        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="taskStates.length > 0" (click)="saveTaskStates()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>

            <table style="width: 100%;">
              <span *ngFor="let state of taskStates" >
                <tr class="full-row">
                  <td *ngIf="!hasStateTasks(state.stateId)" colspan="3">
                    <button (click)="deleteTaskState(state.stateId)" name="deleteTaskState_{{state.stateName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                  </td>
                </tr>
                <tr>
                  <td colspan="3">
                    <label for="">*Taskstate name</label>
                    <input [(ngModel)]="state.stateName" 
                      id="stateName_{{state.stateName}}" 
                      name="stateName{{state.stateId}}" 
                      class="form-control" 
                      placeholder="{{state.stateName}}" 
                      type="text">
                  </td>
                </tr>
                <tr>
                  <td class="bottom-row">
                    <div>
                      <label for="">Order</label>
                      <div>
                        <button *ngIf="!isFirstOrLastState(state.stateId, 'up')" (click)="changeOrder(state.stateId, 'up')" name="taskStateOrderUp_{{state.stateName}}" [disabled]="isFirstOrLastState(state.stateId, 'up')" type="button" class="btn btn-info">&lt;</button>
                        <button *ngIf="!isFirstOrLastState(state.stateId, 'down')" (click)="changeOrder(state.stateId, 'down')" name="taskStateOrderDown_{{state.stateName}}" [disabled]="isFirstOrLastState(state.stateId, 'down')" type="button" class="btn btn-info">&gt;</button>
                      </div>
                    </div>
                  </td>
                  <td class="bottom-row">
                    <div>
                      <label for="">Closed</label>
                      <input [(ngModel)]="state.closed" 
                        name="closed{{state.stateId}}" 
                        class="form-control" 
                        placeholder="{{state.closed}}" 
                        type="checkbox">
                    </div>
                  </td>
                  <td class="bottom-row">
                    <div>
                      <label for="">Billable</label>
                      <input [(ngModel)]="state.billable" 
                        name="billable{{state.stateId}}" 
                        class="form-control" 
                        placeholder="{{state.billable}}" 
                        type="checkbox">
                    </div>
                  </td>
                </tr>
              </span>
            </table>

            <div class="mt-5">
              <button *ngIf="taskStates.length > 0" (click)="saveTaskStates()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>
          </form>

        <div>
          <h2 *ngIf="taskStates.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
