import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import { Priority } from '../_models/priority';

@Injectable({
  providedIn: 'root'
})
export class PriorityService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getProjectPriorities(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId
    };
    return this.http.post<Priority[]>(this.baseUrl + 'project/priorities/get/' + projectId, body);
  }

  // getPriority(priorityId: number, projectId: number) {
  //   let body = {
  //     userId: this.authService.currentUser.id,
  //     projectId
  //   };
  //   return this.http.post<Priority>(this.baseUrl + 'priority/get/' + priorityId, body);
  // }

  savePriority(priorityData) {
    priorityData.userId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'priority/save', priorityData);
  }

  editPriorities(prioritiesData, projectId: number, organizationId: number) {
    prioritiesData.userId = this.authService.currentUser.id;
    prioritiesData.projectId = projectId;
    prioritiesData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'priority/edit', prioritiesData);
  }

  deletePriority(priorityId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        userId: this.authService.currentUser.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'priority/delete/' + priorityId, options);
  }
}
