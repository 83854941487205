import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { PagePermission } from 'src/app/_models/pagePermission';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TodoService } from 'src/app/_services/todo.service';

@Component({
  selector: 'app-todo-create',
  templateUrl: './todo-create.component.html',
  styleUrls: ['./todo-create.component.css']
})
export class TodoCreateComponent implements OnInit {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  @Output() todoListCreated: EventEmitter<any> = new EventEmitter();

  pagePermissions: Array<PagePermission> = new Array<PagePermission>();
  todoForm: UntypedFormGroup;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  isSearching: boolean = false;

  constructor(private router: Router, private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService,
    private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService, private todoService: TodoService, 
    private fb: UntypedFormBuilder) { }

  ngOnInit(): void {
    this.generatePagePermissions();
    this.createTodoItemForm();
  }

  createTodoItemForm() {
    this.todoForm = this.fb.group({
      name: ['', Validators.required],
      deadline: ['', '']
    });
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'todo.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'todo.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'todo.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  async saveTodoList() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    
    const todoListData = {
      todoListName: this.todoForm.value['name'],
      deadline: this.todoForm.value['deadline']
    };

    await new Promise((resolve, reject) => this.todoService.saveTodoList(todoListData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.alertify.success('Saved todo list');
            this.todoListCreated.emit();
            // this.searchTaskStates(this.projectId);
            this.todoForm.reset();
            resolve(true);
        }, err => {
          this.alertify.error('Failed to save todo list');
          reject(new Error('Failed'));
      }
    ));
  }
}
