import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableRoleResult } from 'src/app/_models/expandedRoleResult';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { RoleService } from 'src/app/_services/role.service';

import * as moment from 'moment';
import { ProjectType } from '../../_models/projectType';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { ExpandableProjectResult } from 'src/app/_models/expandableProjectResult';
import { Organization } from 'src/app/_models/organization';
import { ExpandableOrganizationResult } from 'src/app/_models/expandableOrganizationResult';
import { TeamService } from 'src/app/_services/team.service';
import { FormGroup } from '@angular/forms';
import { ExpandableTeamResult } from 'src/app/_models/expandableTeamResult';
import { Team } from 'src/app/_models/team';
import { UserToShow } from 'src/app/_models/userToShow';
import { StateMapping } from 'src/app/_models/stateMapping';
import { User } from 'src/app/_models/user';
import { number } from 'echarts';

@Component({
  selector: 'app-role-edit',
  templateUrl: './role-edit.component.html',
  styleUrls: ['./role-edit.component.css']
})
export class RoleEditComponent implements OnInit {
  @Input() activeOrganizationId: number = null;

  roles = [];
  permissions = [];
  minDate;
  maxDate;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  perPage: number = 10;
  pageNumber: number = 0;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedRoles: Array<ExpandableRoleResult> = new Array<ExpandableRoleResult>();
  isAllExpanded: boolean = false;
  resultCount: number = 0;
  // errorMessage: string;
  // selectedOption: any;
  // stateMappings: Array<StateMapping> = new Array<StateMapping>();
  // searchTerm: string;
  // leaderControls: boolean = false;

  // teamUsers = new Map<number, Array<UserToShow>>();
  // selectedUser: any;

  constructor(private roleService: RoleService, private authService: AuthService, private alertify: AlertifyService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService,
    private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.spinner.show();

    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        await this.searchRoles();
        // await this.findTeamUsers();
      }
    }
  }

  async changePage(pagination: PaginParameters) {
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    await this.searchRoles();
    // await this.findTeamUsers();
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async searchRoles() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.expandedRoles = new Array<ExpandableRoleResult>();
    await new Promise((resolve, reject) => this.roleService.getRoles(this.activeOrganizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.roles = res.results;

          this.setExpandedRoles();
          this.permissions = this.generatePermissionGroups(res.results2);
          
          this.resultCount = res.count[0]['totalCount'];
          this.spinner.hide();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load roles');
          reject(new Error('failed'));
      }
    ));
  }

  setExpandedRoles() {
    if (!this.roles || !this.roles.length) {
      return;
    }

    for (let role of this.roles) {
      this.expandedRoles.push(
        {
          role,
          isActive: this.isAllExpanded
        }
      );
    }
  }

  generatePermissionGroups(permissions) {
    let counter: number = 0;
    let counter2: number = 0;
    let permissionGroups = [];

    for (const permission of permissions) {
      counter++;
      if (counter === 1) {
        permissionGroups[counter2] = [];
      }
      if(counter > 3) {
        counter2++;
        counter = 1;
        permissionGroups[counter2] = [];
      }
      permissionGroups[counter2].push(permission);
    }
    return permissionGroups;
  }

  getReadableName(permissionString: string) {
    if (!permissionString) {
      return null;
    }

    return permissionString[0].toUpperCase() + permissionString.substr(1).split('.').join(' ');
  }

  saveRoles() {
    if (!this.activeOrganizationId) {
      return;
    }

    let editedRoleData = [];
    for (let i = 0; i < this.roles.length; i++) {
      let permissionData = [];
      for (let j = 0 ; j < this.permissions.length; j++) {
        for (let k = 0; k < 3; k++) {
          if (this.permissions[j][k].roleId === this.roles[i].id) {
            let value = {
              permissionId: this.permissions[j][k].permissionId,
              roleId: this.roles[i].id,
              value: this.permissions[j][k].value ? 1 : 0
            }
            permissionData.push(value);
          }
        }
      }

      const roleFormatedData = {
        rolename: this.roles[i].roleName,
        roleId: this.roles[i].id,
        organizationId: this.roles[i].organizationId,
        description: this.roles[i].description,
        permissions: permissionData
      };

      editedRoleData.push(roleFormatedData);
    }
    const rolesData = {
      rolesData: editedRoleData
    };

    this.roleService.editRoles(rolesData, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved role changes');
      }, err => {
        this.alertify.error('Failed to save role changes');
      }
    );
  }

  deleteRole(roleId: number, organizationId: number) {
    this.alertify.confirm('Are you sure you want to delete this role', () => {
      this.roleService.deleteRole(roleId, organizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted role');
          this.searchRoles();
        },
        err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete role');
            }
          }
        }
      );
    });
  }

  expandRow(row: ExpandableRoleResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedRoles.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

}
