import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PageChangedEvent } from 'ngx-bootstrap/pagination';
import { PaginParameters } from 'src/app/_models/paginParameters';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  // styleUrls: ['./pagination.component.css'],
  styles: [`
  .content-wrapper {
    border: 1px solid #ddd; 
    border-radius: 4px; 
    padding-left: 10px; 
    margin-bottom: 10px;
  }
`]
})

export class PaginationComponent implements OnInit {
  @Input() perPage: number = 2;
  @Input() totalItems: number = 10;
  @Output() changePage: EventEmitter<any> = new EventEmitter();
  rotate: boolean = true;
  maxSize: number = 5;
  bigCurrentPage: number = 1;
  smallnumPages:number = 1;
 
  ngOnInit(): void {
    this.setPageCount(this.totalItems);
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'totalItems') {
        if (changes && changes.items && changes.items.currentValue !== changes.items.previousValue) {
          this.setPageCount(this.totalItems);
        }
      }
      else if (property === 'perPage') {
        if (changes && changes.items && changes.items.currentValue !== changes.items.previousValue) {
          this.setPageCount(this.totalItems);
        }
      }
    }
  }

  setPageCount(total: number) {
    this.smallnumPages = total / this.perPage;
  }
 
  pageChanged(event: PageChangedEvent): void {
    let pagingParameters: PaginParameters = 
    {
      perPage: this.perPage,
      pageNumber: event.page - 1
    };
    this.changePage.emit(pagingParameters);
  }
}
