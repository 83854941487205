import { Component, OnInit, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-tasktypes-edit',
  templateUrl: './tasktypes-edit.component.html',
  styleUrls: ['./tasktypes-edit.component.css']
})
export class TasktypesEditComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  taskTypeEditForm: UntypedFormGroup;
  taskTypes = [];
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";

  constructor(private router: Router, private fb: UntypedFormBuilder, private spinner: NgxSpinnerService,
    private authService: AuthService, private alertify: AlertifyService, private taskService: TaskService,
    private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId' || property === 'activeOrganizationId') {
        if (this.projectId && this.activeOrganizationId) {
          this.spinner.show();
          try {
            await this.searchTaskTypes();
          } catch (error) {
            console.log('Error: ', error);
          }
          this.spinner.hide();
        }
      }
    }
  }

  async searchTaskTypes() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.taskTypes = [];

    await new Promise((resolve, reject) => this.taskService.getTaskTypes(this.projectId, this.activeOrganizationId)
    .subscribe(
      res => {
        this.taskTypes = res;
        resolve(true);
    }, err => {
      this.alertify.error('Failed to load task types');
      reject(new Error('failed'));
    }
  ));
  }

  async saveTaskTypes() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    let editedTaskTypes = [];
    for (let i = 0; i < this.taskTypes.length; i++) {

      const formatedTasktypesData = {
        typeId: this.taskTypes[i].taskTypeId,
        typeName: this.taskTypes[i].typeName,
        projectId: this.projectId,
        billable: this.taskTypes[i].billable ? 1 : 0
      };

      editedTaskTypes.push(formatedTasktypesData);
    }

    const tasktypesData =
    {
      tasktypeData: editedTaskTypes
    };

    await new Promise((resolve, reject) => this.taskService.editTaskTypes(tasktypesData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved changes');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to save changes');
            }
          }
          reject(new Error('Failed'));
        }
      ));
  }

  async deleteTaskType(taskTypeId) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this task type?', () => {
      this.taskService.deleteTaskType(taskTypeId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted task type');
          this.searchTaskTypes();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete task type');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }
}
