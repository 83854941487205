<div>
    <form [formGroup]="entityUsersForm">
        <div>
            <label for="">Users:</label>
            <div class="new-user-field">
                <button [disabled]="!entityUsersForm.valid" (click)="saveUser()" type="button" class="btn btn-success float-left">Add</button>
                <input 
                [ngClass]="{'is-invalid': entityUsersForm.get('username').errors && entityUsersForm.get('username').touched}"
                formControlName="username"
                name="username"
                type="text"
                class="form-control username-textfield">
            </div>
    
            <div class="project-users">
                <div *ngFor="let user of entityUsers">
                    <div class="user-row">
                        <div class="username-info-column">
                            <label for="">Username</label>
                            <p class="form-control username-textfield">{{user.username}}</p>
                        </div>
                        <!-- This must be done in a more dynamic way -->
                        <div class="username-info-column" *ngIf="userRoles && userRoles.length > 0" style="width: 25%;"> 
                            <label for="">Role</label>
                            <div *ngIf="userRoles.length > 0" class="btn-group" style="width: 100%;" dropdown>
                                <button id="role-button{{user.userId}}" dropdownToggle type="button" class="btn btn-primary dropdown-toggle"
                                aria-controls="dropdown-basic">
                                Role <span class="caret"></span>
                                </button>
                                <ul id="role-dropdown{{user.userId}}" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                                    <li role="menuitem" (click)="setRole(null, user.userId)">No role</li>
                                    <li *ngFor="let role of userRoles" role="menuitem" (click)="setRole(role, user.userId)">{{role.roleName}}</li>
                                </ul>
                            </div>
                            <p *ngIf="user.userId" type="text" class="form-control">
                              {{user.rolename}}
                            </p>
                        </div>
                        <div *ngIf="canDeleteUser(user.userId)">
                            <label for="">Controls</label>
                            <div class="control-buttons-row">
                                <button *ngIf="canDeleteUser(user.userId) && canHighlight" (click)="highlightUser(user.userId)" type="button" class="btn btn-info user-control-button">!</button>
                                <button *ngIf="canDeleteUser(user.userId)" (click)="deleteUser(user.userId)" type="button" class="btn btn-danger user-control-button">X</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
