import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Organization } from 'src/app/_models/organization';
import { PagePermission } from 'src/app/_models/pagePermission';
import { UserPermission } from 'src/app/_models/userPermission';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { AuthService } from 'src/app/_services/auth.service';
import { OrganizationService } from 'src/app/_services/organization.service';
import { PermissionService } from 'src/app/_services/permission.service';
import { ProjectEditComponent } from '../project-edit/project-edit.component';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent implements OnInit {

  @ViewChild(ProjectEditComponent) child: ProjectEditComponent;

  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService,
    private permissionService: PermissionService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'project.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'project.create'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
      this.updateProjects(null);
    }
  }

  updateProjects(events: any) {
    if (!this.activeOrganizationId || !this.child) {
      return;
    }

    this.child.searchProjects(this.activeOrganizationId);
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
