import { Component, OnInit, OnChanges, SimpleChanges, SimpleChange, Input, Output, EventEmitter } from '@angular/core';
import { TaskTypes } from '../../_models/taskTypes';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { TaskService } from '../../_services/task.service';
import { TaskState } from 'src/app/_models/taskState';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-taskstates-create',
  templateUrl: './taskstates-create.component.html',
  styleUrls: ['./taskstates-create.component.css']
})
export class TaskstatesCreateComponent implements OnInit, OnChanges {
  @Input() projectId: number;
  @Input() activeOrganizationId: number;
  @Output() taskstateCreated: EventEmitter<any> = new EventEmitter();

  taskTypesCreateData = {taskTypeId: '', taskTypeName: '', billable: ''};
  taskStateForm: UntypedFormGroup;
  taskStates: Array<TaskState>;
  activeTaskState;
  maxOrderId: number = 0;

  constructor(private router: Router, private fb: UntypedFormBuilder, private authService: AuthService, private alertify: AlertifyService,
              private taskService: TaskService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.createTaskForm();
  }

  ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'projectId') {
        this.searchTaskStates(this.projectId);
      }
    }
  }

  createTaskForm() {
    this.taskStateForm = this.fb.group({
      taskstatename: ['', Validators.required],
      closed: ['', ''],
      billable: ['', '']
    });
  }

  async searchTaskStates(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.taskStates = new Array<TaskState>();

    await new Promise((resolve, reject) => this.taskService.getTaskStates(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.taskStates = res;
          let orderIds: number[] = this.taskStates.map(ts => ts.orderId);
          this.maxOrderId = Math.max(...orderIds);
          if(!isFinite(this.maxOrderId) || isNaN(this.maxOrderId)) {
            this.maxOrderId = 0;
          }
          this.maxOrderId += 1;
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load task types');
        reject(new Error('Failed'));
      }
    ));
  }

  async saveState() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const billable = this.taskStateForm.value['billable'] ? 1 : 0;
    const closed = this.taskStateForm.value['closed'] ? 1 : 0;
    
    const tasktypeData = {
      stateName: this.taskStateForm.value['taskstatename'],
      billable,
      closed,
      projectId: this.projectId,
      orderId: this.maxOrderId,
      organizationId: this.activeOrganizationId
    };

    await new Promise((resolve, reject) => this.taskService.saveTaskState(tasktypeData)
      .subscribe(
        res => {
            this.alertify.success('Saved state');
            this.taskstateCreated.emit();
            this.searchTaskStates(this.projectId);
            resolve(true);
        }, err => {
          this.alertify.error('Failed to save state');
          reject(new Error('Failed'));
      }
    ));
  }
}
