import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { ProjectType } from '../../_models/projectType';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableProjectResult } from 'src/app/_models/expandableProjectResult';
import { OrganizationService } from 'src/app/_services/organization.service';
import { Organization } from 'src/app/_models/organization';
import { ExpandableOrganizationResult } from 'src/app/_models/expandableOrganizationResult';
import { TeamService } from 'src/app/_services/team.service';
import { FormGroup } from '@angular/forms';
import { ExpandableTeamResult } from 'src/app/_models/expandableTeamResult';
import { Team } from 'src/app/_models/team';
import { UserToShow } from 'src/app/_models/userToShow';
import { StateMapping } from 'src/app/_models/stateMapping';
import { User } from 'src/app/_models/user';
import { number } from 'echarts';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-team-edit',
  templateUrl: './team-edit.component.html',
  styleUrls: ['./team-edit.component.css']
})
export class TeamEditComponent implements OnInit {
  @Input() organizations: Array<Organization> = new Array<Organization>();
  @Input() resultCount: number = 0;
  @Input() activeOrganizationId: number = null;

  teams = [];
  minDate;
  maxDate;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  perPage: number = 10;
  pageNumber: number = 0;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedStates: Array<ExpandableTeamResult> = new Array<ExpandableTeamResult>();
  isAllExpanded: boolean = false;
  // errorMessage: string;
  // selectedOption: any;
  // stateMappings: Array<StateMapping> = new Array<StateMapping>();
  // searchTerm: string;
  // leaderControls: boolean = false;

  teamUsers = new Map<number, Array<UserToShow>>();
  selectedUser: any;


  constructor(private teamService: TeamService, private authService: AuthService, private alertify: AlertifyService,
    private organizationService: OrganizationService, private router: Router, private spinner: NgxSpinnerService,
    private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.bsConfig = {
      containerClass: 'theme-red',
      dateInputFormat: 'YYYY-MM-DD'
    };

    this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    // await this.searchTeams();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        this.spinner.show();
        try {
          await this.searchTeams();
          await this.findTeamUsers();
        } catch (error) {
          console.log('Error: ', error);
        }
        this.spinner.hide();
      }
    }
  }

  async changePage(pagination: PaginParameters) {
    this.spinner.show();
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    try {
      await this.searchTeams();
    } catch (error) {
      console.log('Error: ', error);
    }
    try {
      await this.findTeamUsers();
    } catch (error) {
      console.log('Error: ', error);
    }
    this.spinner.hide();
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async searchTeams() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.expandedStates = new Array<ExpandableTeamResult>();
    await new Promise((resolve, reject) => this.teamService.getTeams(this.activeOrganizationId, this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.teams = res.results;
          this.teams.forEach(team => {
            this.expandedStates.push(
              {
                team,
                isActive: this.isAllExpanded
              }
            );
          });
          this.resultCount = res.count[0]['totalCount'];
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load teams');
          reject(new Error('failed'));
      }
    ));
  }

  // isOwner(teamId: number): boolean {
  //   const team = this.teams.find(t => t.teamId === teamId);
  //   if (!team) {
  //     return false;
  //   }

  //   return team. === this.authService.currentUser.id;
  // }

  async saveTeams() {
    let editedTeamData = [];
    for (let i = 0; i < this.teams.length; i++) {
      const teamFormatedData = {
        name: this.teams[i].name,
        teamId: this.teams[i].teamId,
        organizationId: this.teams[i].organizationId,
        organizationName: this.teams[i].name,
        description: this.teams[i].description
      };

      editedTeamData.push(teamFormatedData);
    }
    const teamsData = {
      teamsData: editedTeamData
    };

    await new Promise((resolve, reject) => this.teamService.editTeams(teamsData, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved team changes');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save team changes');
        reject(new Error('Failed'));
      }
    ));
  }

  async deleteTeam(teamId: number) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this team', () => {
      this.teamService.deleteTeam(teamId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted team');
          this.searchTeams();
          resolve(true);
        },
        err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete team');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }

  async saveUser(event: any) {
    if (!event || !event.entityId || !event.username || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    const teamUserData = {
      teamId: event.entityId,
      username: event.username,
      organizationId: this.activeOrganizationId
    };
    await new Promise((resolve, reject) => this.teamService.saveTeamUser(teamUserData).subscribe(
      res => {
        const newUser: UserToShow = {
          userId: res.userId,
          username: event.username,
          firstname: res.firstname,
          lastname: res.lastname
        }
        let users = this.teamUsers.get(event.entityId);
        users.push(newUser);
        this.teamUsers.set(event.entityId, users);
        this.alertify.success('Added user to team');
        resolve(true);
      }, err => {
        this.alertify.error('Failed to save changes');
        reject(new Error('Failed'));
      }
    ));
  }

  async deleteUser(event: any) {
    if (!event || !event.entityId || !event.userId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to remove this user from the team?', () => {
      this.teamService.deleteTeamUser(event.entityId, event.userId, this.activeOrganizationId)
      .subscribe(
        res => {
          let users = this.teamUsers.get(event.entityId);
          let userToRemove = users.find(u => u.userId === event.userId);
          let index = users.indexOf(userToRemove);
          users.splice(index, 1);
          this.teamUsers.set(event.entityId, users);
          this.alertify.success('Succesfully removed user from the team');
          resolve(true);
        }, err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to remove user from team');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }

  async findTeamUsers() {
    this.teamUsers = new Map<number, Array<UserToShow>>();
    for (let team of this.teams) {
      if (team.teamId) {
        await this.getTeamUsers(team.teamId);
      }
   }
  }

  async getTeamUsers(teamId: number) {
    if (!teamId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.teamService.getTeamUsers(teamId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.teamUsers.set(teamId, res);
          resolve(true);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to get users');
            }
          }
          reject(new Error('failed'));
        }
      )
    );
  }

  getTeamUser(teamId: number) {
    return this.teamUsers.get(teamId);
  }

  async setTeamLeader(userId, entityId) {
    if (!entityId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    await new Promise((resolve, reject) => this.teamService.setTeamLeader(userId, entityId, this.activeOrganizationId)
      .subscribe(
        res => {
          let leader = null; 
          if (userId) {
            let users = this.teamUsers.get(entityId);
            leader = users.find(u => u.userId === userId);
          }
          let team = this.teams.find(t => t.teamId === entityId);
          team.teamLeader = leader?.userId ?? null;
          team.username = leader?.username ?? null;
          resolve(true);
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to set leader');
            }
          }
          reject(new Error('failed'));
        }
      )
    );
  }

  // onSelect2(selectedUser: UserToShow): void {
  //   this.selectedOption = selectedUser;
  //   this.searchTerm = selectedUser.username;
  // }

  // getMatches(searchTerm: string) {
  //   let matchingUsers = new Array<UserToShow>();
  //   this.teamUsers.forEach(user => {
  //     if (user.username.includes(searchTerm) && !matchingUsers.find(mu => mu.username === user.username)) {
  //       matchingUsers.push(user);
  //     }
  //   });
  //   return matchingUsers;
  // }

  // toggleLeaderControls(hasControls: boolean) {
  //   this.leaderControls = hasControls;
  // }

  expandRow(row: ExpandableOrganizationResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

}
