import { TagService } from '../_services/tag.service';
import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Tag } from '../_models/tag';
import { StateMapping } from '../_models/stateMapping';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import * as moment from 'moment';
import { HttpErrorResponse } from '@angular/common/http';

import { HttpClient } from '@angular/common/http';
 
import { noop, Observable, Observer, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';
import { TypeaheadMatch } from 'ngx-bootstrap/typeahead/typeahead-match.class';
import { ActiveOrganizationService } from '../_services/active-organization.service';

@Component({
  selector: 'app-tag',
  templateUrl: './tag.component.html',
  styleUrls: ['./tag.component.css']
})
export class TagComponent implements OnInit {
  @Input() entityId: number;
  @Input() relationTable: string;
  @Input() projectId: number;
  @Input() projectTags: Array<Tag> = new Array<Tag>();
  @Input() activeOrganizationId: number;
  entityTags: Array<Tag> = new Array<Tag>();
  commentForm: UntypedFormGroup;
  selectedOption: any;
  stateMappings: Array<StateMapping> = new Array<StateMapping>();
  searchTerm: string;
  suggestions$: Observable<Tag[]>;
  errorMessage: string;
  selectedTag: any;
  tagControls: boolean = false;
  projectTags2: Array<Tag> = new Array<Tag>();

  tagsForm: UntypedFormGroup;

  constructor(private tagService: TagService, private router: Router, private fb: UntypedFormBuilder,
    private authService: AuthService, private alertify: AlertifyService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    // this.createTagForm();
    // this.getEntityTags();
  }

  // ngOnChanges(changes: SimpleChanges) {
  //   for (const property in changes) {
  //     if (property === 'projectTags') {
  //       if (changes && changes.items && changes.projectTags.currentValue !== changes.projectTags.previousValue) {
  //       }
  //     }
  //   }
  // }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        if (!this.activeOrganizationId) {
          return;
        }
        try {
          this.createTagForm();
        } catch (error) {
          console.log('Error: ', error);
        }
        try {
          this.getEntityTags();
        } catch (error) {
          console.log('Error: ', error);
        }
      }
    }
  }

  createTagForm() {
    this.tagsForm = this.fb.group({
      newTag: ['', Validators.required],
      tags: new UntypedFormArray([])
    });
  }

  get f() { return this.tagsForm.controls; }
  get t() { return this.f.tags as UntypedFormArray; }

  async getEntityTags() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    this.entityTags = new Array<Tag>();
    this.stateMappings = new Array<StateMapping>();

    this.createTagForm();

    await new Promise((resolve, reject) => this.tagService.getTags(this.entityId, this.relationTable, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.entityTags = res;
          if (this.entityTags && this.entityTags.length) {
            this.entityTags.forEach(tag => {
              this.tagsForm.get('tags')
              let state: StateMapping = {
                id: tag.tagId,
                value: false
              };
              this.stateMappings.push(state);
              
              this.t.push(this.fb.group({
                commentId: [tag.tagId, Validators.required],
                comment: [tag.tagName, Validators.required],
                userId: [tag.userId, Validators.required],
              }));
            }); 
          }
          resolve(true);
      }, err => {
        this.alertify.error('Failed to load tags');
        reject(new Error('Failed'));
      }
    ));
  }

  onSelect2(selectedTag: Tag): void {
    this.selectedOption = selectedTag;
    this.searchTerm = selectedTag.tagName;
  }

  getMatches(searchTerm: string) {
    let matchingTags = new Array<Tag>();
    this.projectTags.forEach(tag => {
      if (tag.tagName.includes(searchTerm) && !matchingTags.find(mt => mt.tagName === tag.tagName)) {
        matchingTags.push(tag);
      }
    });
    return matchingTags;
  }

  isOwner(userId: number) {
    if (!userId) {
      return false;
    }
    return (userId === this.authService.currentUser.id);
  }

  canEdit(tag: Tag) {
    if (!tag.userId || !tag.tagId) {
      return false;
    }
    return (tag.userId === this.authService.currentUser.id && this.stateMappings.length && this.stateMappings.find(sm => sm.id === tag.tagId)?.value);
  }

  getEditState(tagId: number) {
    if (!this.stateMappings || !this.stateMappings.length || !tagId) {
      return 'Edit';
    }
    return this.stateMappings.find(sm => sm.id === tagId)?.value === true ? 'Save' : 'Edit';
  }

  clearTag() {
    this.selectedTag = null;
    this.searchTerm = null;
    this.toggleTagControls(false);
  }

  toggleTagControls(hasControls: boolean) {
    this.tagControls = hasControls;
  }

  async saveTag() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    if ((!this.selectedTag 
      && !this.searchTerm) 
      || (this.entityTags && this.entityTags.find(et => et.tagName === this.selectedTag)
      || this.entityTags.find(et => et.tagName === this.searchTerm))) {
      return;
    }

    let tagData = {
      organizationId: this.activeOrganizationId,
      entityId: this.entityId,
      relationTable: this.relationTable,
      userId: this.authService.currentUser.id,
      tagName: this.selectedTag ? this.selectedTag : this.searchTerm
    };

    await new Promise((resolve, reject) => this.tagService.saveTag(tagData, this.projectId)
    .subscribe(
        res => {
          let newTag: Tag = {
            projectId: this.projectId,
            tagId: res.insertId,
            entityId: this.entityId,
            relationTable: this.relationTable,
            userId: this.authService.currentUser.id,
            tagName: this.selectedTag ? this.selectedTag : this.searchTerm,
            createdAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss')),
            updatedAt: new Date(moment().format('YYYY-MM-DD HH:mm:ss'))
          };
          
          this.entityTags.push(newTag);

          let state: StateMapping = {
            id: newTag.tagId,
            value: false
          };
          this.stateMappings.push(state);
          this.toggleTagControls(false);
          this.alertify.success('Saved tag');
          resolve(true);
      }, err => {
        this.alertify.error('Failed to save comment');
        reject(new Error('Failed'));
      }
    ));
  }

  async deleteTag(tagId: number) {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()){
      return;
    }

    const tag: Tag = this.entityTags.find(c => c.tagId === tagId);
    if (!tag) {
      this.alertify.error('Failed to delete tag');
    }

    await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this tag', () => {
      this.tagService.deleteTag(tag.tagId, this.entityId, this.relationTable, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted tag');
          this.getEntityTags();
          resolve(true);
        }, err => {
          console.log(err);
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete tag');
            }
          }
          reject(new Error('Failed'));
        }
      );
    }));
  }
}
