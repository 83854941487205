<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit project types</h3>
        </div>
        
        <div class="card-body">
          <form class="form">
            <div class="horizontal-line mb-5">
              <button *ngIf="projectTypes.length > 0" name="editProjectTypeUp" (click)="saveProjectTypes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>

            <table class="table-full-width">
              <span *ngFor="let type of projectTypes" class="full-row">
                <tr>
                  <td class="full-row">
                    <button (click)="deleteProjectType(type.projectTypeId)" name="deleteProjectType_{{type.projectTypeName}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                  </td>
                </tr>
                <tr>
                  <td class="half-row bottom-row">
                    <label for="">*Project type name</label>
                    <input [(ngModel)]="type.projectTypeName" name="typeName{{type.projectTypeId}}" class="form-control" placeholder="{{type.projectTypeName}}" type="text">
                  </td>
                </tr>
              </span>
            </table>

            <div class="mt-5">
              <button *ngIf="projectTypes.length > 0" name="editProjectTypeBottom" (click)="saveProjectTypes()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
            </div>
          </form>

            <div>
                <h2 *ngIf="projectTypes.length == 0">No data for selected time period</h2>
            </div>
            <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
