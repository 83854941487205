import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {
  registerUserData = {username: '', firstname: '', lastname: '', weeklyWorkHours: 0.0, dailyWorkHours: 0.0, password: '', allowExternalEntities: 0};
  registerForm: UntypedFormGroup;
  minPasswordLength: number = 8;
  maxPasswordLength: number = 54;

  constructor(private auth: AuthService, private router: Router, private fb: UntypedFormBuilder,
              private alertify: AlertifyService) { }

  ngOnInit(): void {
    this.createRegisterForm();
  }

  createRegisterForm() {
    this.registerForm = this.fb.group({
      username: ['', Validators.required],
      firstname: ['', ''],
      lastname: [null, ''],
      weeklyWorkHours: ['', ''],
      dailyWorkHours: ['', ''],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      confirmPassword: ['', Validators.required],
      allowExternalEntities: [true, '']
    }, {validator: this.passwordMatchValidator});
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('password').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  // registerUser() {
  //   let value = this.registerForm.value['allowExternalEntities'];
  //   const externalEntityValue: number = value === false ? 0 : 1;

  //   this.registerUserData = {
  //     username: this.registerForm.value['username'],
  //     firstname: this.registerForm.value['firstname'],
  //     lastname: this.registerForm.value['lastname'],
  //     weeklyWorkHours: this.registerForm.value['weeklyWorkHours'],
  //     dailyWorkHours: this.registerForm.value['dailyWorkHours'],
  //     password: this.registerForm.value['password'],
  //     allowExternalEntities: externalEntityValue
  //   };
  //   this.auth.registerUser(this.registerUserData)
  //     .subscribe(
  //       res => {
  //         this.alertify.success('Registered user');
  //         this.router.navigate(['/worktime/create']);
  //       }, err =>
  //       {
  //         this.alertify.error('Failed to register');
  //       }
  //     );
  // }

}
