<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new task</h3>
        </div>

        <div class="card-body">
          <form *ngIf="taskTypes && taskStates" [formGroup]="taskCreateForm" class="form">
            <table style="width: 100%;">
                <tr>
                    <td colspan="2">
                        <label for="">*Task name</label>
                        <input 
                          [ngClass]="{'is-invalid': taskCreateForm.get('taskname').errors && taskCreateForm.get('taskname').touched}"
                          formControlName="taskname"
                          name="taskname"
                          type="text"
                          class="form-control">
                        <div class="invalid-feedback worktime-date-field">Task name is required</div>
                    </td>
                </tr>
                <tr>
                  <td colspan="2">
                    <label for="">Description</label>
                    <textarea 
                      formControlName="description"
                      name="description"
                      type="text"
                      class="form-control">
                    </textarea>
                  </td>
                </tr>
                <tr>
                    <td>
                      <label for="">*Project</label>
                      <div *ngIf="projects.length > 0" id="taskProject-select" class="btn-group" style="width: 100%;" dropdown>
                        <button 
                          id="project-button-basic"
                          dropdownToggle type="button"
                          class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Project <span class="caret"></span>
                        </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                              <li *ngFor="let project of projects" role="menuitem" (click)="setTaskProject(project.projectId)">{{project.projectName}}</li>
                          </ul>
                      </div>
                      <p *ngIf="activeProject">
                        {{activeProject.projectName}}
                      </p>
                      <div class="invalid-feedback worktime-date-field">Project is required</div>
                      <div class="alert" *ngIf="taskTypes.length == 0">No projects to select from!</div>
                    </td>
                    <td>
                      <label for="">*Task state</label>
                      <div *ngIf="taskStates.length > 0" id="taskState-select" class="btn-group" style="width: 100%;" dropdown>
                        <button 
                          id="state-button-basic"
                          dropdownToggle type="button"
                          class="btn btn-primary dropdown-toggle"
                          aria-controls="dropdown-basic">
                          Task state <span class="caret"></span>
                        </button>
                          <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li *ngFor="let state of taskStates" role="menuitem" (click)="setTaskState(state.stateId)">{{state.stateName}}</li>
                          </ul>
                      </div>
                      <p *ngIf="activeTaskState">
                        {{activeTaskState.stateName}}
                      </p>
                      <div class="invalid-feedback worktime-date-field">Task state is required</div>
                      <div class="alert" *ngIf="taskStates.length == 0">No task state to select from!</div>
                    </td>
                  </tr>
                <tr>
                  <td>
                    <label for="">*Task type</label>
                    <div *ngIf="taskTypes.length > 0" id="taskType-select" class="btn-group" style="width: 100%;" dropdown>
                      <button 
                        id="type-button-basic"
                        dropdownToggle type="button"
                        class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Task type <span class="caret"></span>
                      </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                            <li *ngFor="let type of taskTypes" role="menuitem" (click)="setTaskType(type.taskTypeId)">{{type.typeName}}</li>
                        </ul>
                    </div>
                    <p *ngIf="activeTaskType">
                      {{activeTaskType.typeName}}
                    </p>
                    <div class="invalid-feedback worktime-date-field">Task type is required</div>
                    <div class="alert" *ngIf="taskTypes.length == 0">No task types to select from!</div>
                  </td>
                  <td>
                    <label for="">Version</label>
                    <div *ngIf="projectVersions && projectVersions.length > 0" id="taskVersion-select" class="btn-group" style="width: 100%;" dropdown>
                      <button 
                        id="version-button-basic"
                        dropdownToggle type="button"
                        class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Version <span class="caret"></span>
                      </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" (click)="setVersion(0)">No version</li>
                          <li *ngFor="let version of projectVersions" role="menuitem" (click)="setVersion(version.versionId)">{{version.versionName}}</li>
                        </ul>
                    </div>
                    <p *ngIf="activeVersion">
                      {{activeVersion.versionName}}
                    </p>
                    <div class="alert" *ngIf="taskTypes.length == 0">No versions to select from!</div>
                  </td>
                </tr>
                <tr>
                  <td>
                    <label for="">Task priority</label>
                    <div *ngIf="priorities && priorities.length > 0" id="taskPriority-select" class="btn-group" style="width: 100%;" dropdown>
                      <button 
                        id="state-button-basic"
                        dropdownToggle type="button"
                        class="btn btn-primary dropdown-toggle"
                        aria-controls="dropdown-basic">
                        Task priority <span class="caret"></span>
                      </button>
                        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li role="menuitem" (click)="setPriority(0)">No priority</li>
                          <li *ngFor="let priority of priorities" role="menuitem" (click)="setPriority(priority.priorityId)">{{priority.priorityName}}</li>
                        </ul>
                    </div>
                    <p *ngIf="activePriority">
                      {{activePriority.priorityName}}
                    </p>
                    <div class="alert" *ngIf="taskStates.length == 0">No priorities to select from!</div>
                  </td>
                  <td>
                    <div class="form-group">
                      <label for="">Deadline</label>
                      <input formControlName="taskdeadline"
                      name="taskdeadline"
                      class="form-control worktime-time-field worktime-date-field-input"
                      placeholder="Task deadline"
                      type="text"
                      bsDatepicker
                      [bsConfig]="bsConfig">
                  </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Estimated worktime</label>
                  <div>
                    <label class="worktime-estimation-days-label" for="">Days: </label>
                    <input 
                    formControlName="estimatedDays"
                      name="estimatedDays"
                      type="number"
                      min="0" 
                      class="form-control worktime-estimation">
                    <label class="worktime-estimation-label" for="">Hours: </label>
                    <input 
                    formControlName="estimatedHours"
                      name="estimatedHours"
                      type="number"
                      min="0"
                      max="24"
                      class="form-control worktime-estimation">
                    <label class="worktime-estimation-label" for="">Minutes: </label>
                    <input 
                    formControlName="estimatedMinutes"
                      name="estimatedMinutes"
                      type="number"
                      min="0" 
                      max="60"
                      class="form-control worktime-estimation">
                  </div>
                </td>
              </tr>
              <tr class="bottom-row">
                <td>
                  <button [disabled]="!taskCreateForm.valid" name="createTaskButton" (click)="saveTask()" type="button" class="btn btn-success float-left">Save</button>
                </td>
              </tr>
            </table>
          </form>

        </div>
      </div>
    </div>
  </div>

