import { Injectable } from '@angular/core';
import { Organization } from '../_models/organization';
import { OrganizationWithUser } from '../_models/organizationWithUser';
import { PagePermission } from '../_models/pagePermission';
import { UserPermission } from '../_models/userPermission';
import { AuthService } from './auth.service';
import { OrganizationService } from './organization.service';
import { PermissionService } from './permission.service';

@Injectable({
  providedIn: 'root'
})
export class ActiveOrganizationService {

  perPage: number = 10;
  pageNumber: number = null;
  organizations: Array<OrganizationWithUser> = new Array<OrganizationWithUser>();
  activeOrganizationId: number = null;
  userPermissions = new Array<UserPermission>();
  initialized = false;
  delay = 1;
  counter = 0;
  maxRetryAttemps = 3;

  constructor(private organizationService: OrganizationService, private authService: AuthService, private permissionService: PermissionService) { }

  ngOnInit() {

  }

  getOrganizations() {
    const user = localStorage.getItem('user');
    if (!user) {
      setTimeout(() => {
        this.callGetUser(), this.delay
      });
    }
    else {
      this.callGetUser();
    }
  }

  callGetUser() {
    // if (this.counter >= this.maxRetryAttemps) {
    //   return;
    // }
    // this.counter++;

    this.organizationService.getOrganizationsWithUsers(this.perPage, this.pageNumber)
    .subscribe(
      res => {
        this.initialized = true;
        this.organizations = new Array<OrganizationWithUser>();
        this.organizations = res.results;
        this.validateActiveOrganization();
      }, err => {
        console.log('Something went wrong!');
    }
  );
  }

  validateActiveOrganization() {
    if (!this.organizations || this.organizations.length === 0) {
      return null;
    }

    let organizationId = this.organizationService.getActiveOrganizationId();
    if (!organizationId || (this.activeOrganizationId && (organizationId === this.activeOrganizationId.toString())) || isNaN(Number(organizationId)) || Number(organizationId) <= 0) {
      return this.activeOrganizationId ?? null;
    }

    return this.setActiveOrganization(organizationId);
  }
  
  setActiveOrganization(organizationId: string) {
    if (!organizationId) {
      this.activeOrganizationId = null;
      return null;
    }

    this.activeOrganizationId = this.organizations.find(o => o.organizationId.toString() === organizationId)?.organizationId ?? null;
    return this.activeOrganizationId;
  }

  isValidOrganizationId() {
    let organizationId = this.organizationService.getActiveOrganizationId();
    if (!this.organizations || !this.organizations.length) {
      return false;
    }
    else if (!organizationId || isNaN(Number(organizationId)) || Number(organizationId) <= 0 || !this.organizations.find(o => o.organizationId.toString() === organizationId)) {
      return false;
    }
    else {
      return true;
    }
  }

  async checkPermissions(pagePermissions: Array<PagePermission>) {
    const localStorageOrganizationId = Number(localStorage.getItem('activeOrganizationId'));
    this.activeOrganizationId = this.activeOrganizationId === localStorageOrganizationId ? this.activeOrganizationId : null;
    if (!this.activeOrganizationId) {
      return;
    }

    await new Promise((resolve, reject) => this.permissionService.getUserPermissions(this.activeOrganizationId)
      .subscribe(
        res => {
          resolve(true);
          this.userPermissions = new Array<UserPermission>();
          this.userPermissions = res;
          this.getPagePermissions(this.userPermissions, pagePermissions);
          return pagePermissions;
        }, err => {
          console.log('Something went wrong! ', err);
          reject(new Error('failed'));
      }
    ));
  }

  getPagePermissions(userPermissions: Array<UserPermission>, pagePermissions: Array<PagePermission>) {
    for (const permission of pagePermissions) {
      const userPermissionValue = userPermissions.find(userPermission => userPermission.permissionstring === permission.permissionstring);
      permission.value = userPermissionValue ? userPermissionValue.value : 0;
    }
  }
}
