<!-- <div class="row pt-5">
    <div class="col-md-6 mx-auto"> -->
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Create new task</h3>
        </div>
        <div class="card-body">
          <form *ngIf="taskTypes && taskStates" [formGroup]="taskCreateForm" class="form">
            {{task?.taskName}}
            <table style="width: 100%;">
              <tr>
                <td colspan="2">
                    <label for="">*Task name</label>
                    <input 
                      [ngClass]="{'is-invalid': taskCreateForm.get('taskname').errors && taskCreateForm.get('taskname').touched}"
                      formControlName="taskname"
                      name="taskname"
                      type="text"
                      class="form-control">
                    <div class="invalid-feedback worktime-date-field">Task name is required</div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Description</label>
                  <textarea 
                    formControlName="taskdescription"
                    name="taskdescription"
                    type="text"
                    class="form-control">
                  </textarea>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="">*Task type</label>
                  <div *ngIf="taskTypes.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="type-btn"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Task type <span class="caret"></span>
                    </button>
                      <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let type of taskTypes" role="menuitem" (click)="setTaskType(type?.taskTypeId, type?.typeName)">{{type.typeName}}</li>
                      </ul>
                  </div>
                  <p *ngIf="activeTaskType">
                    {{activeTaskType.typeName}}
                  </p>
                  <div class="invalid-feedback worktime-date-field">Task type is required</div>
                  <div class="alert" *ngIf="taskTypes.length == 0">No task types to select from!</div>
                </td>
                
                <td>
                  <label for="">*Task state</label>
                  <div *ngIf="taskStates.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="state-btn"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Task state <span class="caret"></span>
                    </button>
                      <ul id="state-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                          <li *ngFor="let state of taskStates" role="menuitem" (click)="setTaskState(state.stateId, state.stateName)">{{state.stateName}}</li>
                      </ul>
                  </div>
                  <p *ngIf="activeTaskState">
                    {{activeTaskState.stateName}}
                  </p>
                  <div class="invalid-feedback worktime-date-field">Task state is required</div>
                  <div class="alert" *ngIf="taskStates.length == 0">No task state to select from!</div>
                </td>
              </tr>
              <tr>
                <td>
                  <label for="">Version</label>
                  <div *ngIf="projectVersions && projectVersions.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="version-btn"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Version <span class="caret"></span>
                    </button>
                      <ul id="version-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" (click)="setVersion(0)">No version</li>
                        <li *ngFor="let version of projectVersions" role="menuitem" (click)="setVersion(version.versionId)">{{version.versionName}}</li>
                      </ul>
                  </div>
                  <p *ngIf="activeVersion">
                    {{activeVersion.versionName}}
                  </p>
                  <div class="alert" *ngIf="projectVersions && projectVersions.length == 0">No versions to select from!</div>
                </td>
                <td>
                  <label for="">Priority</label>
                  <div *ngIf="projectPriorities && projectPriorities.length > 0" class="btn-group" style="width: 100%;" dropdown>
                    <button 
                      id="priority-btn"
                      dropdownToggle type="button"
                      class="btn btn-primary dropdown-toggle"
                      aria-controls="dropdown-basic">
                      Priority <span class="caret"></span>
                    </button>
                      <ul id="priority-dropdown-basic" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic">
                        <li role="menuitem" (click)="setPriority(0)">No priority</li>
                        <li *ngFor="let priority of projectPriorities" role="menuitem" (click)="setPriority(priority.priorityId)">{{priority.priorityName}}</li>
                      </ul>
                  </div>
                  <p *ngIf="activePriority">
                    {{activePriority.priorityName}}
                  </p>
                  <div class="alert" *ngIf="projectPriorities && projectPriorities.length == 0">No priorities to select from!</div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="form-group">
                    <label for="">Deadline</label>
                    <input formControlName="taskdeadline"
                    name="taskdeadline"
                    class="form-control worktime-time-field worktime-date-field-input"
                    placeholder="Task deadline"
                    type="text"
                    bsDatepicker
                    [bsConfig]="bsConfig">
                </div>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <app-comment *ngIf="task" entityId={{getTaskId()}} [relationTable]="relationTable" projectId={{projectId}}></app-comment>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <app-assignee *ngIf="task" [projectId]="getProjectId()" [activeOrganizationId]="activeOrganizationId" [taskId]="getTaskId()" (assigneeSaved)="updateAssignee($event)"></app-assignee>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <app-worktime-logging [projectId]="getProjectId()" [taskId]="getTaskId()"></app-worktime-logging>
                </td>
              </tr>
              <tr>
                <td colspan="2">
                  <label for="">Estimated worktime</label>
                  <div>
                    <label class="worktime-estimation-days-label" for="">Days: </label>
                    <input 
                    formControlName="estimatedDays"
                      name="estimatedDays"
                      type="number"
                      min="0" 
                      class="form-control worktime-estimation">
                    <label class="worktime-estimation-label" for="">Hours: </label>
                    <input 
                    formControlName="estimatedHours"
                      name="estimatedHours"
                      type="number"
                      min="0"
                      max="24"
                      class="form-control worktime-estimation">
                    <label class="worktime-estimation-label" for="">Minutes: </label>
                    <input 
                    formControlName="estimatedMinutes"
                      name="estimatedMinutes"
                      type="number"
                      min="0" 
                      max="60"
                      class="form-control worktime-estimation">
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <app-tag *ngIf="task" entityId={{getTaskId()}} [relationTable]="relationTable" projectId={{getProjectId()}} [projectTags]="projectTags"></app-tag>
                </td>
              </tr>
              <tr class="bottom-row">
                <td>
                  <button [disabled]="!taskCreateForm.valid" (click)="saveTask()" type="button" class="btn btn-success float-left">Save</button>
                </td>
                <td>
                  <button *ngIf="task" (click)="deleteTask()" type="button" class="btn btn-danger float-left">Delete</button>
                </td>
              </tr>
            </table>
          </form>
          <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    <!-- </div>
  </div> -->

