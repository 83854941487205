<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <div class="card-header">
          <h3 class="mb-0">Edit user</h3>
        </div>
        <div class="card-body">
          <form [formGroup]="userEditForm" class="form" *ngIf="userToEdit">
            <div class="form-group">
              <label for="">*Username</label>
              <input formControlName="username" [ngClass]="{'is-invalid': userEditForm.get('username').errors 
              && userEditForm.get('username').touched}" name="username" type="text" class="form-control rounded-0" required>
              <div class="invalid-feedback">Username is required</div>
            </div>
            <div class="form-group">
                <label for="">Firstname</label>
                <input formControlName="firstname" name="firstname" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
                <label for="">Lastname</label>
                <input formControlName="lastname" name="lastname" type="text" class="form-control rounded-0">
            </div>
            <div>
                <label for="">Daily workhours</label>
                <input formControlName="dailyWorkHours" name="dailyWorkHours" type="text" class="form-control rounded-0">
            </div>
            <div>
                <label for="">Weekly workhours</label>
                <input formControlName="weeklyWorkHours" name="weeklyWorkHours" type="text" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">Allow external entities</label>
              <input formControlName="allowExternalEntities" name="allowExternalEntities" type="checkbox" class="form-control rounded-0">
            </div>
            <div class="form-group">
              <label for="">*Current password</label>
              <input type="password"
                name="password"
                [ngClass]="{'is-invalid': userEditForm.get('password').errors
                  && userEditForm.get('password').touched}"
                class="form-control"
                formControlName="password"
                placeholder="Password">
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('password').hasError('required')
                && userEditForm.get('password').touched">
                Password is required
              </div>
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('password').hasError('minlength')
                && userEditForm.get('password').touched">
                Password must be at least {{minPasswordLength}} characters
              </div>
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('password').hasError('maxlength')
                && userEditForm.get('password').touched">
                Password cannot exceed {{maxPasswordLength}} characters
              </div>
            </div>

            <div class="form-group">
              <label for="">New password</label>
              <input type="password"
                name="newpassword"
                [ngClass]="{'is-invalid': userEditForm.get('newPassword').errors
                  && userEditForm.get('newPassword').touched}"
                class="form-control"
                formControlName="newPassword"
                placeholder="New password">
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('newPassword').hasError('minlength')
                && userEditForm.get('newPassword').touched">
                Password must be at least {{minPasswordLength}} characters
              </div>
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('newPassword').hasError('maxlength')
                && userEditForm.get('newPassword').touched">
                Password cannot exceed {{maxPasswordLength}} characters
              </div>
            </div>

            <div class="form-group">
              <label for="">Confirm password</label>
              <input type="password" 
                name="confirmpassword"
                [ngClass]="{'is-invalid': userEditForm.get('confirmPassword').errors
                  && userEditForm.get('confirmPassword').touched
                  || userEditForm.get('confirmPassword').touched
                  && userEditForm.hasError('mismatch')}"
                class="form-control" 
                formControlName="confirmPassword" 
                placeholder="Confirm Password">
              <div class="invalid-feedback"
                *ngIf="userEditForm.get('confirmPassword').hasError('required')
                && userEditForm.get('confirmPassword').touched">
                Password is required
              </div>
              <div class="invalid-feedback"
                *ngIf="userEditForm.hasError('mismatch')
                && userEditForm.get('confirmPassword').touched">
                Passwords must match
              </div>
            </div>
            <button [disabled]="!userEditForm.valid" (click)="saveUser()" type="button" class="btn btn-success float-left mt-4">Save changes</button>
          </form>
        </div>
        <div *ngIf="!userToEdit">
            <h4>No userdata to show</h4>
        </div>
      </div>
    </div>
  </div>