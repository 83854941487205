import { Component, OnInit, ViewChild } from '@angular/core';
import { PagePermission } from 'src/app/_models/pagePermission';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { ProjecttypesEditComponent } from '../projecttypes-edit/projecttypes-edit.component';

@Component({
  selector: 'app-project-types',
  templateUrl: './project-types.component.html',
  styleUrls: ['./project-types.component.css']
})
export class ProjectTypesComponent implements OnInit {
  @ViewChild(ProjecttypesEditComponent) child: ProjecttypesEditComponent;

  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'projectTypes.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'projectTypes.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'projectTypes.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    }
  }

  updateProjectTypes(events: any) {
    if (!this.activeOrganizationId || !this.child) {
      return;
    }

    this.child.searchProjectTypes();
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
