import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TaskTypes } from '../../_models/taskTypes';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { ProjectService } from '../../_services/project.service';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-projecttypes-create',
  templateUrl: './projecttypes-create.component.html',
  styleUrls: ['./projecttypes-create.component.css']
})
export class ProjecttypesCreateComponent implements OnInit {
  @Input() activeOrganizationId: number = null;
  @Output() projectTypeCreated: EventEmitter<any> = new EventEmitter();

  projectTypesCreateData = {proejctTypeId: '', projectTypeName: ''};
  projectTypeForm: UntypedFormGroup;
  activeprojectType;

  constructor(private router: Router, private fb: UntypedFormBuilder, private activeOrganizationService: ActiveOrganizationService,
    private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService) { }

    ngOnInit(): void {
      this.createProjectTypeForm();
    }

    createProjectTypeForm() {
      this.projectTypeForm = this.fb.group({
        projecttypename: ['', Validators.required],
      });
    }

    saveProjectType() {
      if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
        return;
      }

      const projectTypeData = {
        organizationId: this.activeOrganizationId,
        ownerId: this.authService.decodedToken.id,
        projectTypeName: this.projectTypeForm.value['projecttypename']
      };

      this.projectService.saveProjectType(projectTypeData)
        .subscribe(
          res => {
            this.projectTypeCreated.emit();
              this.alertify.success('Saved type');
          }, err => {
            this.alertify.error('Failed to save type');
        }
      );
    }

}
