import { Component, OnInit, ViewChild } from '@angular/core';
import { Project } from 'src/app/_models/project';
import { AuthService } from 'src/app/_services/auth.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import { ProjectService } from 'src/app/_services/project.service';
import { TaskService } from 'src/app/_services/task.service';
import * as moment from 'moment';
import { PriorityEditComponent } from '../priority-edit/priority-edit.component';
import { OrganizationService } from 'src/app/_services/organization.service';
import { Organization } from 'src/app/_models/organization';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { PagePermission } from 'src/app/_models/pagePermission';

@Component({
  selector: 'app-priorities',
  templateUrl: './priorities.component.html',
  styleUrls: ['./priorities.component.css']
})
export class PrioritiesComponent implements OnInit {
  @ViewChild(PriorityEditComponent) child: PriorityEditComponent;

  projects: Project[];
  projectId: number;

  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  activeOrganizationId: number = null;
  organizations: Array<Organization> = new Array<Organization>();
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();


  constructor(private authService: AuthService, private alertify: AlertifyService, private organizationService: OrganizationService,
              private projectService: ProjectService, private taskService: TaskService, 
              private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.searchProjects();
    this.generatePagePermissions();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'priorities.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'priorities.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'priorities.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  searchOrganizations() {
    this.organizationService.getOrganizations(this.perPage, this.pageNumber)
      .subscribe(
        res => {
          this.organizations = res.results;
          this.resultCount = res.count[0]['totalCount'];
        }, err => {
          this.alertify.error('Failed to load organizations');
      }
    );
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
      this.searchProjects();
    }
  }

  searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projects = res.results;
          this.projects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
            }
          });

          this.projectId = Number(localStorage.getItem('currentProject'));

          if (!this.projects.find(p => p.projectId === this.projectId)){
            this.projectId = this.projects[0].projectId;
            localStorage.setItem('currentProject', this.projectId.toString());
          }
        }, err => {
          this.alertify.error('Failed to load projects');
      }
    );
  }

  setProject(projectId: number) {
    if (this.projects.find(p => p.projectId === projectId)){
      localStorage.setItem('currentProject', projectId.toString());
      this.projectId = projectId;
    }
  }

  search() {
    console.log('Implement searching of something here?');
  }

  getProjectName(): string {
    return this.projects.find(p => p.projectId === this.projectId).projectName;
  }

  updatePriorities(events: any) {
    if (!this.activeOrganizationId || !this.child) {
      return;
    }

    this.child.searchPriorities();
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }
}
