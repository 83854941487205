import { Component, OnInit } from '@angular/core';
import { UserService } from '../_services/user.service';
import { AlertifyService } from '../_services/alertify.service';
import { AuthService } from '../_services/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { UserToEdit } from '../_models/UserToEdit';
import { UntypedFormGroup, FormControl, Validators, UntypedFormBuilder } from '@angular/forms';
import { Organization } from 'src/app/_models/organization';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { PagePermission } from 'src/app/_models/pagePermission';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  userToEdit: UserToEdit;
  userEditForm: UntypedFormGroup;
  minPasswordLength: number = 8;
  maxPasswordLength: number = 54;
  activeOrganizationId: number = null;
  organizations: Array<Organization> = new Array<Organization>();
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();
  isFetchingUsers: Boolean = false;

  constructor(private userService: UserService, private alertify: AlertifyService,
              private fb: UntypedFormBuilder, private authService: AuthService, private router: Router,
              private activeOrganizationService: ActiveOrganizationService) { }

  async ngOnInit() {
    this.activeOrganizationService.getOrganizations();
    this.createUserEditForm();
    this.generatePagePermissions();
    // this.userService.getUserToEdit()
    // .subscribe(
    //   res => {
    //       this.userToEdit = res;

    //       this.userEditForm.patchValue({username: this.userToEdit.username});
    //       this.userEditForm.patchValue({firstname: this.userToEdit.firstname});
    //       this.userEditForm.patchValue({lastname: this.userToEdit.lastname});
    //       this.userEditForm.patchValue({weeklyWorkHours: this.userToEdit.weeklyWorkHours});
    //       this.userEditForm.patchValue({dailyWorkHours: this.userToEdit.dailyWorkHours});
    //       this.userEditForm.patchValue({allowExternalEntities: this.userToEdit.allowExternalEntities === 0 ? false : true});
    //   }, err => {
    //     this.alertify.error('Failed to load data');
    //   }
    // );
  }

  async ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'userinfo.own.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'userinfo.own.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'userinfo.others.view'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'userinfo.others.edit'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      try {
        await this.activeOrganizationService.checkPermissions(this.pagePermissions);
        await this.getUserToEdit();
      } catch (error) {
        console.log('Error: ', error);
      }
    }
  }

  createUserEditForm() {
    this.userEditForm = this.fb.group({
      username: ['', Validators.required],
      firstname: ['', ''],
      lastname: [null, ''],
      weeklyWorkHours: ['', ''],
      dailyWorkHours: ['', ''],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(64)]],
      newPassword: ['', [Validators.minLength(8), Validators.maxLength(64)]],
      confirmPassword: ['', ''],
      allowExternalEntities: [false, '']
    }, {validator: this.newPasswordValidator});
  }

  passwordMatchValidator(g: UntypedFormGroup) {
    return g.get('newPassword').value === g.get('confirmPassword').value ? null : {'mismatch': true};
  }

  newPasswordValidator(g: UntypedFormGroup) {
    if (g.get('newPassword').value) {
      return g.get('newPassword').value === g.get('confirmPassword').value ? null : {'mismatch': true};
    }
    else {
      return true;
    }
  }

  async getUserToEdit() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId() || this.isFetchingUsers){
      return;
    }

    this.isFetchingUsers = true;
    await this.userService.getUserToEdit(this.activeOrganizationId)
    .subscribe(
      res => {
          this.userToEdit = res;

          this.userEditForm.patchValue({username: this.userToEdit.username});
          this.userEditForm.patchValue({firstname: this.userToEdit.firstname});
          this.userEditForm.patchValue({lastname: this.userToEdit.lastname});
          this.userEditForm.patchValue({weeklyWorkHours: this.userToEdit.weeklyWorkHours});
          this.userEditForm.patchValue({dailyWorkHours: this.userToEdit.dailyWorkHours});
          this.userEditForm.patchValue({allowExternalEntities: this.userToEdit.allowExternalEntities === 0 ? false : true});
          this.isFetchingUsers = false;
      }, err => {
        this.alertify.error('Failed to load data');
        this.isFetchingUsers = false;
      }
    );
  }

  async saveUser() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId() || this.isFetchingUsers){
      return;
    }

    let value = this.userEditForm.value['allowExternalEntities'];
    const externalEntityValue: number = (!value || value === false) ? 0 : 1;

    const newPassword = this.userEditForm.value['newPassword'];
    this.userToEdit.username = this.userEditForm.value['username'];
    this.userToEdit.firstname = this.userEditForm.value['firstname'];
    this.userToEdit.lastname = this.userEditForm.value['lastname'];
    this.userToEdit.weeklyWorkHours = this.userEditForm.value['weeklyWorkHours'];
    this.userToEdit.dailyWorkHours = this.userEditForm.value['dailyWorkHours'];
    this.userToEdit.password = this.userEditForm.value['password'];
    this.userToEdit.newPassword = newPassword ? newPassword : null;
    this.userToEdit.allowExternalEntities = externalEntityValue;
    this.userToEdit.organizationId = this.activeOrganizationId;

    await this.userService.saveUserToEdit(this.userToEdit)
    .subscribe(
      res => {
          this.alertify.success('Saved changes');
      }, err => {
        this.alertify.error('Failed to save changes');
      }
    );
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }

}
