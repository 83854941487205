import { Component, OnInit, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpandableTodoListResult } from 'src/app/_models/expandedTodoListResult';
import { PagePermission } from 'src/app/_models/pagePermission';
import { PaginParameters } from 'src/app/_models/paginParameters';
import { SearchParameters } from 'src/app/_models/searchParameters';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';
import { AlertifyService } from 'src/app/_services/alertify.service';
import * as moment from 'moment';

import { AuthService } from 'src/app/_services/auth.service';
import { ProjectService } from 'src/app/_services/project.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { Project } from 'src/app/_models/project';
import { TodoService } from 'src/app/_services/todo.service';
import { TodoList } from 'src/app/_models/todoList';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-todo-lists',
  templateUrl: './todo-lists.component.html',
  styleUrls: ['./todo-lists.component.css']
})
export class TodoListsComponent implements OnInit {

  projectId: number;
  userProjects = Array<Project>();
  todoLists = Array<TodoList>();
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();
  activeOrganizationId: number = null;
  perPage: number = 10;
  pageNumber: number = 0;
  resultCount: number = 0;
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  expandedStates: Array<ExpandableTodoListResult> = new Array<ExpandableTodoListResult>();
  isAllExpanded: boolean = false;
  bsConfig: Partial<BsDatepickerConfig>;
  ismeridian: boolean = false;
  isSearching: boolean = false;

  constructor(private router: Router, private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService,
    private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService, private todoService: TodoService) { }

  async ngOnInit() {
    // this.bsConfig = {
    //   containerClass: 'theme-red',
    //   dateInputFormat: 'YYYY-MM-DD'
    // };

    // this.minDate = moment().startOf('month').format('YYYY-MM-DD');
    // this.maxDate = moment().endOf('month').format('YYYY-MM-DD');

    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  async ngOnChanges(changes: SimpleChanges) {
    for (const property in changes) {
      if (property === 'activeOrganizationId') {
        if (!this.activeOrganizationId) {
          return;
        }
        this.spinner.show();
        await this.searchProjects();
        await this.getTodoLists();
      }
    }
  }

  ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'todo.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'todo.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'todo.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  isVisible() {
    return this.resultCount > this.perPage;
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
    }
  }

  changePage(pagination: PaginParameters) {
    this.perPage = pagination.perPage;
    this.pageNumber = pagination.pageNumber;
    this.getTodoLists();
  }

  search(searchParameters: SearchParameters) {
    this.projectId = searchParameters.projectId ? searchParameters.projectId : undefined;
    // this.minDate = searchParameters.minDate ? searchParameters.minDate : undefined;
    // this.maxDate = searchParameters.maxDate ? searchParameters.maxDate : undefined;
    if (!this.isSearching && this.activeOrganizationId) {
      this.isSearching = true;
      this.getTodoLists();
    }
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }

  async searchProjects(getData?: boolean) {
    console.log("1-1");
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    console.log("2-2");
    this.userProjects = new Array<Project>();
    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
            this.userProjects = res.results;
            this.userProjects.forEach(project => {
              if (project.projectDeadline) {
                project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
              }
            });
            this.projectId = this.userProjects.find(up => up.projectId === this.projectId) ? this.projectId : this.userProjects[0].projectId;

            // if (getData) {
            //   this.getAllData();
            // }

            resolve(true);
        }, err => {
          this.alertify.error('Failed to load projects');
          reject(new Error('failed'));
      }
    ));
  }

  async getTodoLists() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.isSearching = true;
    this.todoLists = new Array<TodoList>();
    this.expandedStates = new Array<ExpandableTodoListResult>();
    await new Promise((resolve, reject) => this.todoService.getTodoLists(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.todoLists = res.results;
          this.todoLists.forEach(project => {
            if (project.deadline) {
              project.deadline = new Date(moment(project.deadline).format('YYYY-MM-DD HH:mm:ss'));
            }
          });

          this.todoLists.forEach(todoList => {
            this.expandedStates.push(
              {
                todoList,
                isActive: this.isAllExpanded
              }
            );
          });
          // this.spinner.hide();
          this.isSearching = false;
          resolve(true);
        }, err => {
          console.log('err: ', err);
          this.alertify.error('Failed to load projects');
          this.isSearching = false;
          reject(new Error('failed'));
      }
    ));
  }

  saveTodoLists() {
    if (!this.activeOrganizationId) {
      this.alertify.error('Failed to save project changes');
      return;
    }

    let editedTodoListData = [];

    for (let i = 0; i < this.todoLists.length; i++) {
      const todoListItemsData = {
        id: this.todoLists[i].id,
        projectId: this.todoLists[i].projectId,
        name: this.todoLists[i].name,
        deadline: !this.todoLists[i].deadline ? null : this.todoLists[i].deadline
      };

      editedTodoListData.push(todoListItemsData);
    }

    const todoListData = {
      todoListData: editedTodoListData
    };

    this.todoService.editTodoLists(todoListData, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Saved project changes');
      }, err => {
        this.alertify.error('Failed to save project changes');
      }
    );
  }

  deleteTodoList(todoId: number, projectId: number) {
    if (!todoId || !projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.alertify.confirm('Are you sure you want to delete this todo list?', () => {
      this.todoService.deleteTodoList(todoId, this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.alertify.success('Deleted todo list');
          this.getTodoLists();
        },
        err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.alertify.error('Failed to delete todo list');
            }
          }
        }
      );
    });
  }

  expandRow(row: ExpandableTodoListResult) {
    row.isActive = !row.isActive;
  }

  expandAll() {
    this.isAllExpanded = !this.isAllExpanded;

    this.expandedStates.forEach(row => {
      row.isActive = this.isAllExpanded;
    });
  }

  getValidTime(timeToCheck: Date) {
    if (!timeToCheck) {
      return;
    }
    if (moment(timeToCheck).isValid()) {
      return moment(timeToCheck).format('DD-MM-YYYY HH:mm');
    }
    else {
      return 'No endtime set';
    }
  }

}
