import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { ProjectType } from '../_models/projectType';
import { ProjectVersion } from '../_models/projectVersion';
import { Project } from '../_models/project';
import { PaginParameters } from '../_models/paginParameters';
import { PaginatedResults } from '../_models/paginatedResults';

@Injectable({
  providedIn: 'root'
})
export class ProjectService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getUsersProjects(organizationId: number) {
    const ownerId = this.authService.decodedToken.id;
    return this.http.get<PaginatedResults>(this.baseUrl + 'projects/get/' + ownerId + '/' + null + '/' + null + '/' + organizationId);
  }

  getPaginatedUsersProjects(organizationId: number, perPage?: number, pageNumber?: number) {
    const ownerId = this.authService.decodedToken.id;
    return this.http.get<PaginatedResults>(this.baseUrl + 'projects/get/' + ownerId + '/' + (perPage ? perPage : 0) + '/' + (pageNumber ? pageNumber : 0) + '/' + organizationId);
  }

  getProjectTasks(organizationId: number, projectId: number) {
    const ownerId = this.authService.decodedToken.id;
    const data = {
      organizationId,
      projectId,
      ownerId: this.authService.decodedToken.id
    };
    return this.http.post<PaginatedResults>(this.baseUrl + 'task/get/', data);
  }

  saveProject(projectData) {
    return this.http.post<any>(this.baseUrl + 'projects/create', projectData);
  }

  editProjects(projectData, organizationId: number) {
    projectData.organizationId = organizationId;
    projectData.ownerId = this.authService.decodedToken.id;
    return this.http.post<any>(this.baseUrl + 'projects/edit', projectData);
  }

  deleteProject(projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        userId: this.authService.decodedToken.id,
        organizationId: organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'projects/delete/' + projectId, options);
  }

  getProjectTypes(organizationId: number) {
    const userId = this.authService.currentUser.id;
    return this.http.get<ProjectType[]>(this.baseUrl + 'project/get/types/' + organizationId + '/' + userId);
  }

  saveProjectType(projectTypeData) {
    projectTypeData.ownerId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'project/type/save', projectTypeData);
  }

  editProjectTypes(projectTypeData, organizationId: number) {
    projectTypeData.ownerId = this.authService.currentUser.id;
    projectTypeData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'project/types/edit', projectTypeData);
  }

  deleteProjectType(typeId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ownerId: this.authService.decodedToken.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'project/type/delete/' + typeId, options);
  }

  getProjectVersions(projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      organizationId
    };
    return this.http.post<ProjectVersion[]>(this.baseUrl + 'project/versions/get/' + projectId, body);
  }

  saveProjectVersion(versionData) {
    versionData.userId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'project/version/save', versionData);
  }

  editProjectVersions(projectVersionData, projectId: number, organizationId: number) {
    projectVersionData.userId = this.authService.currentUser.id;
    projectVersionData.projectId = projectId;
    projectVersionData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'project/versions/edit', projectVersionData);
  }

  deleteProjectVersion(versionId: number, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        projectId,
        userId: this.authService.currentUser.id,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'project/version/delete/' + versionId, options);
  }

  getProjectUsers(projectId: number, organizationId: number) {
    const body = {
      userId: this.authService.decodedToken.id,
      projectId,
      organizationId
    };
    return this.http.post<any>(this.baseUrl + 'projectUsers/get', body);
  }

  saveProjectUser(projectUserData) {
    projectUserData.ownerId = this.authService.currentUser.id;
    return this.http.post<any>(this.baseUrl + 'projectUser/save', projectUserData);
  }

  editProjectUser(projectUserData) {
    return this.http.post<any>(this.baseUrl + 'projectUser/edit', projectUserData);
  }

  deleteProjectUser(projectId: number, userId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        ownerId: this.authService.currentUser.id,
        userId,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'projectUser/delete/' + projectId, options);
  }
}
