import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { UserToEdit } from '../_models/UserToEdit';
import { Observable } from 'rxjs';
import { ProjectType } from '../_models/projectType';
import { TaskTypes } from '../_models/taskTypes';
import { TaskState } from '../_models/taskState';
import { Task } from '../_models/task';
import { Comment } from '../_models/comment';

@Injectable({
  providedIn: 'root'
})
export class CommentService {
  baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getComments(entityId: number, relationTable: string, projectId: number, organizationId: number) {
    let body = {
      userId: this.authService.currentUser.id,
      projectId: projectId,
      organizationId
    }
    return this.http.post<any>(this.baseUrl + 'comments/get/' + relationTable + '/' + entityId, body);
  }

  saveComment(commentData, projectId: number, organizationId: number) {
    commentData.userId = this.authService.currentUser.id;
    commentData.projectId = projectId;
    commentData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'comment/save', commentData);
  }

  editComments(commentsData, organizationId: number) {
    commentsData.organizationId = organizationId;
    return this.http.post<any>(this.baseUrl + 'comments/edit', commentsData);
  }

  editComment(data, projectId: number, organizationId: number) {
    const commentData = {
      commentData: data,
      userId: this.authService.currentUser.id,
      projectId,
      organizationId
    };
    return this.http.post<any>(this.baseUrl + 'comment/edit', commentData);
  }

  deleteComments(commentId: number, entityId: number, relationTable: string, projectId: number, organizationId: number) {
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        entityId,
        relationTable,
        userId: this.authService.currentUser.id,
        projectId,
        organizationId
      },
    };
    return this.http.delete<any>(this.baseUrl + 'comment/delete/' + commentId, options);
  }
}