import { Component, OnInit, TemplateRef } from '@angular/core';
import { AuthService } from '../_services/auth.service';
import { AlertifyService } from '../_services/alertify.service';
import { ProjectService } from '../_services/project.service';
import { TaskService } from '../_services/task.service';
import * as moment from 'moment';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Task } from '../_models/task';
import { TaskState } from '../_models/taskState';
import { ProjectVersion } from '../_models/projectVersion';
import { Project } from '../_models/project';
import { TaskTypes } from '../_models/taskTypes';
import { Priority } from '../_models/priority';
import { PriorityService } from '../_services/priority.service';
import { Assignee } from '../_models/assignee';
import { BoardTask } from '../_models/boardTask';
import { SearchParameters } from '../_models/searchParameters';
import { BoardData } from '../_models/boardData';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from '../_services/active-organization.service';
import { PagePermission } from '../_models/pagePermission';

@Component({
  selector: 'app-board',
  templateUrl: './board.component.html',
  styleUrls: ['./board.component.css']
})
export class BoardComponent implements OnInit {
  taskStates: Array<TaskState>;
  projects: Array<Project>;
  projectTasks: Array<BoardTask>;
  formattedList = [];
  projectId: number;
  modalRef: BsModalRef;
  activeTask: BoardTask;
  activeState: TaskState;
  activeVersion: ProjectVersion;
  activePriority: Priority;
  projectVersions: ProjectVersion[];
  projectPriorities: Array<Priority>;
  taskTypes: Array<TaskTypes>;
  versionId: number;
  typeId: number;
  priorityId: number;
  priorityIds: Array<number>;
  tagName: string;
  selectedTagNames: Array<string> = new Array<string>();
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";
  activeOrganizationId: number = null;
  pagePermissions: Array<PagePermission> = new Array<PagePermission>();

  constructor(private authService: AuthService, private alertify: AlertifyService, private modalService: BsModalService,
              private projectService: ProjectService, private taskService: TaskService, private priorityService: PriorityService,
              private spinner: NgxSpinnerService, private activeOrganizationService: ActiveOrganizationService) { }

  ngOnInit(): void {
    this.activeOrganizationService.getOrganizations();
    this.generatePagePermissions();
  }

  async ngDoCheck() {
    this.checkActiveOrganization();
  }

  generatePagePermissions() {
    let pagePermission1: PagePermission = {
      value: 0,
      permissionstring: 'board.view'
    };
    let pagePermission2: PagePermission = {
      value: 0,
      permissionstring: 'board.edit'
    };
    let pagePermission3: PagePermission = {
      value: 0,
      permissionstring: 'board.create'
    };
    let pagePermission4: PagePermission = {
      value: 0,
      permissionstring: 'project.view'
    };

    this.pagePermissions.push(pagePermission1);
    this.pagePermissions.push(pagePermission2);
    this.pagePermissions.push(pagePermission3);
    this.pagePermissions.push(pagePermission4);
  }

  async checkActiveOrganization() {
    const previousOrganizationId = this.activeOrganizationId;
    let newId = this.activeOrganizationService.validateActiveOrganization();
    if (!newId) {
      this.activeOrganizationService.getOrganizations();
      return;
    }
    if (previousOrganizationId !== newId && !isNaN(newId)) {
      this.activeOrganizationId = Number(newId);
      this.spinner.show();
      try {
        await this.activeOrganizationService.checkPermissions(this.pagePermissions);
        await this.getBoardData();
      } catch (error) {
        console.log('Error: ', error);
      }
      this.spinner.hide();
    }
  }

  async search(searchParameters: SearchParameters) {
    this.projectId = searchParameters.projectId ? searchParameters.projectId : undefined;
    this.priorityIds = searchParameters.priorityId && searchParameters.priorityId.length ? searchParameters.priorityId : null;
    this.versionId = searchParameters.versionId ? searchParameters.versionId : undefined;
    this.typeId = searchParameters.typeId ? searchParameters.typeId : undefined;
    this.selectedTagNames = searchParameters.tagsToSearch && searchParameters.tagsToSearch.length ? searchParameters.tagsToSearch.map(t => t.tagName) : null;
    this.spinner.show();
    try {
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
      await this.getBoardData();
    } catch (error) {
      console.log('Error: ', error);
    }
    this.spinner.hide();
  }

  async getBoardData() {
    try {
      await this.searchProjects();
      await this.getProjectVersions();
      await this.getProjectPriorities();
      await this.searchTaskStates(this.projectId);
      await this.searchTaskTypes(this.projectId);
      await this.searchTasks(this.projectId);
    } catch (error) {
      console.log('Error: ', error);
    }
  }

  async searchProjects() {
    if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projects = Array<Project>();

    await new Promise((resolve, reject) => this.projectService.getUsersProjects(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projects = res.results;
          this.projects.forEach(project => {
            if (project.projectDeadline) {
              project.projectDeadline = new Date(moment(project.projectDeadline).format('YYYY-MM-DD HH:mm:ss'));
            }
          });

          this.projectId = Number(localStorage.getItem('currentProject'));
          if (!this.projects.find(p => p.projectId === this.projectId)){
            this.projectId = this.projects[0].projectId;
            localStorage.setItem('currentProject', this.projectId.toString());
          }

          resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('Failed'));
      }
    ));
  }

  async searchTasks(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectTasks = Array<BoardTask>();
    let boardData: BoardData = {
      organizationId: this.activeOrganizationId,
      projectId: projectId,
      userId: null,
      priorityId: this.priorityIds,
      taskTypeId: this.typeId,
      versionId: this.versionId,
      selectedTags: this.selectedTagNames
    }

    await new Promise((resolve, reject) => this.taskService.getBoardTasks(boardData)
      .subscribe(
        res => {
            this.projectTasks = res;
            this.generateStateTaskList();
            resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('Failed'));
      }
    ));
  }

  async searchTaskStates(projectId: number) {
    if (!projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.taskStates = [];

    await new Promise((resolve, reject) => this.taskService.getTaskStates(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskStates = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('Failed'));
      }
    ));
  }

  async searchTaskTypes(projectId: number) {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }
    this.taskTypes = Array<TaskTypes>();

    await new Promise((resolve, reject) => this.taskService.getTaskTypes(projectId, this.activeOrganizationId)
      .subscribe(
        res => {
            this.taskTypes = res;
            resolve(true);
        }, err => {
          this.alertify.error('Failed to save changes');
          reject(new Error('Failed'));
      }
    ));
  }

  generateStateTaskList() {
    this.formattedList = [];
    let selectedVersion: ProjectVersion;

    if (this.versionId !== 0) {
      this.projectVersions.forEach(version => {
        if (version.versionId === this.versionId) {
          selectedVersion = version;
          return;
        }
      });
    }

    if (selectedVersion) {
      this.createVersionList(selectedVersion);
    }
    else {
      const emptyVersion: ProjectVersion = {
        versionId: 0,
        versionName: 'No version',
        projectId: this.projectId,
        createdAt: new Date()
      };

      // add tasks without version
      this.createVersionList(emptyVersion);

      // add tasks with version
      if (this.projectVersions && this.projectVersions.length > 0) {
        this.projectVersions.forEach(version => {
          this.createVersionList(version);
        });
      }
    }
    this.spinner.hide();
  }

  createVersionList(version: ProjectVersion) {
    let versionList = {
      version: version,
      versionStates: []
    };
    const stateTasks = this.findStateTasks(version, versionList.versionStates);
    if (versionList.versionStates.length > 0) {
      this.formattedList.push(versionList);
    }
  }

  findStateTasks(version: ProjectVersion, list) {
    this.taskStates.forEach(state => {
      let stateList = [];
      stateList.push(state);
      this.projectTasks.forEach(task => {
        const isMatchingStateAndType = task.taskState === state.stateId && (this.typeId ? task.taskType === this.typeId : true);
        const isMatchingVersion = version.versionId === 0 ? 
                                  (!task.versionId || task.versionId === 0) : 
                                  (version.versionId > 0 && task.versionId === version.versionId);

        if (isMatchingStateAndType && isMatchingVersion) {
          stateList.push(task);
        }
      });
      if (stateList.length > 0) {
        list.push(stateList);
      }
    });

    return list;
  }

  async getProjectVersions() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectVersions = new Array<ProjectVersion>();
    await new Promise((resolve, reject) => this.projectService.getProjectVersions(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectVersions = res.length > 0 ? res : new Array<ProjectVersion>();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project versions');
          reject(new Error('Failed'));
      }
    ));
  }

  async getProjectPriorities() {
    if (!this.projectId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
      return;
    }

    this.projectPriorities = new Array<Priority>();
    await new Promise((resolve, reject) => this.priorityService.getProjectPriorities(this.projectId, this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectPriorities = res.length > 0 ? res : new Array<Priority>();
          resolve(true);
        }, err => {
          this.alertify.error('Failed to get project priorities');
          reject(new Error('Failed'));
      }
    ));
  }

  openTask(template: TemplateRef<any>, taskId: number, stateId: number, versionId: number, priorityId: number) {
    if (!this.checkPagePermissions('board.edit') || !this.checkPagePermissions('project.view')) {
      return;
    }

    this.activeTask = null;
    if (taskId) {
      this.formattedList.forEach(version => {
        if ((!versionId && version.version.versionId === 0) || (version.version.versionId === versionId) || (versionId === 0 && !version.version.versionId)) {
          version.versionStates.forEach(state => {
            if (state[0] && state[0].stateId === stateId) {
              state.forEach(task => {
                if (task.taskId === taskId && (task.priorityId === priorityId || priorityId === 0)) {
                  this.activeTask = task;
                }
              });
            }
          });
        }
      });
    }

    this.activeState = this.taskStates.find(ts => ts.stateId === stateId);
    this.activeVersion = this.projectVersions.find(pv => pv && pv.versionId === versionId);
    this.activePriority = this.projectPriorities.find(pp => pp && pp.priorityId === priorityId);
    this.modalRef = this.modalService.show(template);
  }

  async updateTasks(modifiedTask: BoardTask) {
    if (modifiedTask.taskId) {
      if (this.projectTasks.includes(modifiedTask)) {
        const index = this.projectTasks.indexOf(modifiedTask);
        this.projectTasks.splice(index);
      }
      else {
        this.projectTasks.forEach(task => {
          if (task.taskId === modifiedTask.taskId) {
            task.taskName = modifiedTask.taskName;
            task.taskState = modifiedTask.taskState;
            task.taskType = modifiedTask.taskType;
            task.ownerId = modifiedTask.ownerId;
            task.deadline = modifiedTask.deadline;
            task.assigneeId = modifiedTask.assigneeId;
            task.assignee = modifiedTask.assignee;
          }
        });
      }
    }

    this.spinner.show();
    try {
      await this.activeOrganizationService.checkPermissions(this.pagePermissions);
      await this.getBoardData();
      this.hideModal();
    } catch (error) {
      console.log('Error: ', error);
    }
    this.spinner.hide();
  }

  getStateTasks(state) {
    return state.filter(s => s.taskId);
  }

  checkPagePermissions(permissionString: string) {
    for (const permission of this.pagePermissions) {
      if (permission && permission.permissionstring === permissionString && permission.value === 1) {
        return true;
      }
    }
    return false;
  }

  hideModal() {
    this.modalRef.hide();
  }
}