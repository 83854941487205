import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../_services/auth.service';
import { AlertifyService } from '../../_services/alertify.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ProjectService } from '../../_services/project.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActiveOrganizationService } from 'src/app/_services/active-organization.service';

@Component({
  selector: 'app-projecttypes-edit',
  templateUrl: './projecttypes-edit.component.html',
  styleUrls: ['./projecttypes-edit.component.css']
})
export class ProjecttypesEditComponent implements OnInit {
  @Input() activeOrganizationId: number = null;
  projectTypeEditForm: UntypedFormGroup;
  projectTypes = [];
  spinnerType: string = "ball-spin-clockwise";
  spinnerColor: string = "#0066ff";

  constructor(private router: Router, private fb: UntypedFormBuilder, private spinner: NgxSpinnerService,
    private authService: AuthService, private alertify: AlertifyService, private projectService: ProjectService,
    private activeOrganizationService: ActiveOrganizationService) { }

    async ngOnInit() {
      this.spinner.show();
      try {
        await this.searchProjectTypes();
      } catch (error) {
        console.log('Error: ', error);
      }
      this.spinner.hide();
    }

    async ngOnChanges(changes: SimpleChanges) {
      for (const property in changes) {
        if (property === 'activeOrganizationId') {
          this.spinner.show();
          try {
            await this.searchProjectTypes();
          } catch (error) {
            console.log('Error: ', error);
          }
          this.spinner.hide();
        }
      }
    }

    async searchProjectTypes() {
      if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
        return;
      }

      await new Promise((resolve, reject) => this.projectService.getProjectTypes(this.activeOrganizationId)
      .subscribe(
        res => {
          this.projectTypes = res;
          resolve(true);
        }, err => {
          this.alertify.error('Failed to load project types');
          reject(new Error('failed'));
        }
      ));
    }

    async saveProjectTypes() {
      if (!this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
        return;
      }

      let editedProjectTypes = [];

      for (let i = 0; i < this.projectTypes.length; i++) {

        const formatedProjectTypesData = {
          organizationId: this.activeOrganizationId,
          projectTypeId: this.projectTypes[i].projectTypeId,
          ownerId: this.authService.decodedToken.id,
          projectTypeName: this.projectTypes[i].projectTypeName
        };

        editedProjectTypes.push(formatedProjectTypesData);
      }

      const projectTypesData =
      {
        projectTypeData: editedProjectTypes
      };

      await new Promise((resolve, reject) => this.projectService.editProjectTypes(projectTypesData, this.activeOrganizationId)
        .subscribe(
          res => {
            this.alertify.success('Saved changes');
            resolve(true);
          }, err => {
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.alertify.error('Failed to save changes');
              }
            }
            reject(new Error('failed'));
          }
        ));
    }

    async deleteProjectType(typeId) {
      if (!typeId || !this.activeOrganizationId || !this.activeOrganizationService.isValidOrganizationId()) {
        return;
      }

      await new Promise((resolve, reject) => this.alertify.confirm('Are you sure you want to delete this project type?', () => {
        this.projectService.deleteProjectType(typeId, this.activeOrganizationId)
        .subscribe(
          res => {
            this.alertify.success('Deleted project type');
            this.searchProjectTypes();
            resolve(true);
          }, err => {
            console.log(err);
            if (err instanceof HttpErrorResponse) {
              if (err.status === 401) {
                this.alertify.error('Failed to delete task type');
              }
            }
            reject(new Error('failed'));
          }
        );
      }));
    }

}
