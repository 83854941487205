<div class="row pt-5">
    <div class="col-md-12 mx-auto">
      <div class="card rounded-0" *ngIf="checkPagePermissions('board.view') && checkPagePermissions('project.view')">
        <app-search pageHeader="Board" [hasDates]=false [hasOnlyProject]=false [hasSelectAllProjects]=false [hasSelectAll]=true [searchTags]=true [isWideView]=true (searchParametersChanged)="search($event)"></app-search>
        <div class="card-body" *ngIf="checkPagePermissions('board.view') && checkPagePermissions('project.view')">
          <div class="swimlane" *ngFor="let version of formattedList">
            <div>
              {{version.version.versionName}}
            </div>
            <div class="board-body" *ngFor="let item of version.versionStates">
              <div class="state-body" id="state-body_{{item[0].stateName}}_{{version.version.versionName}}">
                <ng-container *ngIf="item[0]">
                  <div class="board-header" id="state_{{item[0].stateName}}_{{version.version.versionName}}">
                    {{item[0].stateName}}
                  </div>
                  <ng-container *ngFor="let task of getStateTasks(item)">
                    <div *ngIf="task.taskId" class="state-card" (click)="openTask(template, task?.taskId, item[0].stateId, task?.versionId, task?.priorityId)">
                      <p>{{task.taskName}}</p>
                      <p *ngIf="task && task.assigneeId">Assignee: {{task.assignee}}</p>
                    </div>
                  </ng-container>
                  <button *ngIf="checkPagePermissions('board.create') && checkPagePermissions('project.view')" type="button" class="btn btn-primary" id="new_{{item[0].stateName}}_{{version.version.versionName}}" (click)="openTask(template, null, item[0].stateId, version?.version?.versionId, 0)">+</button>
                </ng-container>
              </div>
            </div>
            <div *ngIf="formattedList.length === 0"><label for="">No data to show</label></div>
          </div>
        </div>

        <ng-template #template>
          <div class="modal-header">
            <h4 class="modal-title pull-left">{{activeTask?.taskName || 'new task'}}</h4>
            <button type="button" class="close pull-right" aria-label="Close" (click)="hideModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <app-task-edit-single 
              [activeOrganizationId]="activeOrganizationId" 
              [projectId]="projectId" 
              [task]="activeTask" 
              [taskState]="activeState" 
              [version]="activeVersion" 
              [priority]="activePriority" 
              (taskSaved)="updateTasks($event)"></app-task-edit-single>
          </div>
        </ng-template>

        <div *ngIf="!projects || projects.length === 0"><label for="">No data to show</label></div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
      </div>
    </div>
  </div>