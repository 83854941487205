<div class="row pt-5">
  <div class="col-md-6 mx-auto">
    <div class="card rounded-0">
      <div class="card-header">
        <h3 class="mb-0">Login</h3>
      </div>
      <div class="card-body">
        <form class="form" (keydown)="loginUser($event)" >
          <div class="form-group">
            <label for="">Username</label>
            <input [(ngModel)]="loginUserData.username" name="username" type="text" class="form-control rounded-0" required>
          </div>
          <div class="form-group">
            <label for="">Password</label>
            <input [(ngModel)]="loginUserData.password" name="password" type="password" class="form-control rounded-0" required>
          </div>
          <button (click)="loginUser()" type="button" class="btn btn-success float-right">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>