<div class="row pt-5">
    <div class="col-md-6 mx-auto">
      <div class="card rounded-0">
        <app-todo-create [projectId]="projectId" [activeOrganizationId]="activeOrganizationId" (todoListCreated)="getTodoLists()"></app-todo-create>
        <app-search *ngIf="checkPagePermissions('todo.view') && checkPagePermissions('todo.edit') && checkPagePermissions('project.view')"
        pageHeader="Todo" [hasDates]=false [hasSelectAllProjects]=true [hasSelectAll]=false (searchParametersChanged)="search($event)"></app-search>
        <div class="card-body" *ngIf="checkPagePermissions('todo.view') && checkPagePermissions('todo.edit') && checkPagePermissions('project.view')">
          <form class="form">

            <div class="horizontal-line mb-5">
              <button *ngIf="expandedStates.length > 0" (click)="saveTodoLists()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <!-- <button type="button" class="btn btn-info mb-3 ml-2" (click)="toggleMode()">12H / 24H</button> -->
              <button name="expand-all" type="button" class="btn btn-info mb-3 ml-2" (click)="expandAll()">{{!isAllExpanded ? 'Expand all' : 'Minize all'}}</button>
            </div>
            <table class="table-full-width">
              <span *ngFor="let row of expandedStates">
                <tr>
                  <td style="width: 25%;">
                    <button (click)="deleteTodoList(row.todoList.id, row.todoList.projectId)" name="deleteTodoList_{{row.todoList.id}}" [disabled]="false" type="button" class="btn btn-danger">Delete</button>
                    <button name="expandButton_{{row.todoList.id}}" (click)="expandRow(row)" [disabled]="false" type="button" class="btn btn-info">{{ row.isActive ? '-' : '+' }}</button>
                  </td>
                </tr>
                <span *ngIf="!row.isActive">
                  <tr>
                    <td class="minified-column">
                      <label for="">*Name</label>
                      <p>{{row.todoList.name}}</p>
                    </td>
                    <td class="minified-column endtime-column">
                      <label for="">Deadline</label>
                      <p>{{getValidTime(row.todoList.deadline)}}</p>
                    </td>
                  </tr>
                </span>

                <span *ngIf="row.isActive">
                  <tr>
                    <td class="half-row">
                      <label for="">*Name</label>
                      <input 
                        [(ngModel)]="row.todoList.name" 
                        name="minDate{{row.todoList.id}}" 
                        class="form-control date-input" 
                        placeholder="{{row.todoList.name}}" 
                        type="text"
                      >
                    </td>
                    <td class="half-row">
                      <label for="">*Deadline</label>
                      <input 
                        [(ngModel)]="row.todoList.deadline" 
                        class="form-control"
                        name="starttime{{row.todoList.id}}" 
                        type="text"
                        bsDatepicker
                        [bsConfig]="bsConfig"
                      >
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">
                      <app-todoitem projectId={{row.todoList.projectId}} [activeOrganizationId]="activeOrganizationId" todoId={{row.todoList.id}}></app-todoitem>
                    </td>
                  </tr>
                </span>
              </span>
            </table>
            <div class="mt-5">
              <button *ngIf="expandedStates.length > 0" (click)="saveTodoLists()" [disabled]="false" type="button" class="btn btn-success mb-3">Save</button>
              <!-- <button type="button" class="btn btn-info mb-3 ml-2" (click)="toggleMode()">12H / 24H</button> -->
            </div>
          </form>
          <app-pagination *ngIf="isVisible()" perPage={{perPage}} totalItems={{resultCount}} (changePage)="changePage($event)"></app-pagination>

        <div>
          <h2 *ngIf="!expandedStates || expandedStates.length == 0">No data for selected time period</h2>
        </div>
        <ngx-spinner [type]="spinnerType" [color]="spinnerColor"></ngx-spinner>
        </div>
      </div>
    </div>
  </div>
